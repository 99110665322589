import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { OrderAuditLog } from "http/models";
import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { format } from "date-fns-tz";
import OrderAuditLogImageCard from "./OrderAuditLogImageCard";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    accordion: {
      width: "90%",
      marginBottom: theme.spacing(0),
    },
    cost: {
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    editIcon: {
      marginTop: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      marginTop: theme.spacing(2),
    },
    item: {
      borderRadius: "4px",
      margin: "0 auto",
      padding: theme.spacing(1),
      width: "90%",
      textAlign: "center",
      boxShadow: `1px 1px 8px ${theme.palette.grey[800]}`,
    },
    header: {
      padding: theme.spacing(2),
    },
    value: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(12),
      },
    },
    addIcon: {
      marginLeft: theme.spacing(3),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      borderRadius: "4px",
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
    removeIcon: {
      marginRight: theme.spacing(3),
      borderRadius: "4px",
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
  }),
  { index: 1 }
);

interface Props {
  orderAuditLog: OrderAuditLog;
}

const OrderAuditLogCard: React.FC<Props> = ({ orderAuditLog }) => {
  const styles = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      className={styles.accordion}
      expanded={expanded === "panel4"}
      onChange={handleChange("panel4")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" />}
        aria-controls="panel4bh-content"
        id="panel4bh-header"
      >
        <Grid item xs={12} md={3}>
          <Typography className={styles.header} variant="h6">
            Updated
          </Typography>
          <Typography className={styles.cost} variant="subtitle1">
            {new Date(orderAuditLog?.updated_at).toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography className={styles.header} variant="h6">
            By
          </Typography>
          <Typography className={styles.cost} variant="subtitle1">
            {orderAuditLog?.last_updated_by_username}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography className={styles.header} variant="h6">
            From
          </Typography>
          <Typography className={styles.cost} variant="subtitle1">
            {orderAuditLog?.og_status}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography className={styles.header} variant="h6">
            To
          </Typography>
          <Typography className={styles.cost} variant="subtitle1">
            {orderAuditLog?.new_status}
          </Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          <Typography className={styles.header} variant="h6">
            Reason
          </Typography>
          <Typography className={styles.cost} variant="subtitle1">
            {orderAuditLog?.reason
              ? orderAuditLog.reason
              : "No reason submitted"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={styles.header} variant="h6">
            Images
          </Typography>
        </Grid>
        <Grid direction="row" justify="center" alignItems="center">
          {orderAuditLog.images ? (
            orderAuditLog.images.map((img) => (
              <OrderAuditLogImageCard key={img.id} img={img} />
            ))
          ) : (
            <Typography className={styles.cost} variant="subtitle1">
              {orderAuditLog?.reason
                ? orderAuditLog.reason
                : "No images submitted"}
            </Typography>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderAuditLogCard;
