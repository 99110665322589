import React, { useState, useContext, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import { Order, OrderStatus } from "http/models";
import { listOrderAuditLogs, updateOrderStatus } from "http/endpoints";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { notificationsContext } from "contexts/Notifications";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      position: "absolute",
      width: 400,
      height: 640,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      transition: "all 3000ms ease;",
    },
    mainGrid: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    grid: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(8),
    },
    inputField: {
      width: "60%",
      marginTop: theme.spacing(6),
      marginLeft: theme.spacing(11),
      marginBottom: theme.spacing(1),
      display: "block",
    },
    closeIcon: {
      marginLeft: "300px",
      marginTop: "-40px",
      color: `${theme.palette.grey[800]}`,
      height: "40px",
      width: "40px",
      opacity: "0.5",
      "&:hover": {
        color: "red",
        boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
        cursor: "pointer",
        borderRadius: "8px",
      },
    },
    scoot: {
      marginTop: theme.spacing(-2),
    },
    header: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(-2),
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    gridItem: {
      marginTop: theme.spacing(3),
    },
    disableIcon: {
      marginLeft: theme.spacing(1),
    },
    uploadBtn: {
      marginTop: theme.spacing(-16),
    },
    btnIcon: {
      marginLeft: theme.spacing(1),
    },
    updateBtnIcon: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(-1),
    },
    updateBtn: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(-1),
    },
    notifyUserSwitch: {
      marginLeft: theme.spacing(-1),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(-6),
    },
    takePhotoBtn: {
      marginTop: theme.spacing(-16),
      marginBottom: theme.spacing(-12),
    },
    attachedImages: {
      marginTop: theme.spacing(-8),
    },
    subHeader: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(-4),
    },
  }),
  { index: 1 }
);

interface Props {
  open: boolean;
  closeHandler: (show: boolean) => void;
  order: Order;
  ogStatus: OrderStatus;
  newStatus: OrderStatus;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}

const ConfirmOrderStatusUpdateModal: React.FC<Props> = ({
  open,
  closeHandler,
  order,
  ogStatus,
  newStatus,
  setStatus,
}) => {
  const [shouldNotify, setShouldNotify] = useState<boolean>(true);
  const [isPending, setIsPending] = useState<boolean>(true);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
  const [reason, setReason] = useState<string>("");
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  const modalStyle = (): React.CSSProperties => ({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    textAlign: "center",
    borderRadius: "8px",
    padding: "45px",
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files && event.target.files[0]) {
      const files: File[] = [];
      const fileNames: string[] = [];
      for (let i = 0; i < event.target.files.length; i += 1) {
        fileNames.push(event.target.files[i].name);
        files.push(event.target.files[i]);
      }
      setSelectedFileNames([...selectedFileNames, ...fileNames]);
      setSelectedFiles([...selectedFiles, ...files]);
    }
  };

  const handleDelete = (target: string) => {
    const fileNames: string[] = [];
    const files: File[] = [];
    for (let i = 0; i < selectedFileNames.length; i += 1) {
      if (target === selectedFileNames[i]) {
        // eslint-disable-next-line no-continue
        continue;
      } else {
        files.push(selectedFiles[i]);
        fileNames.push(selectedFileNames[i]);
      }
    }
    setSelectedFiles(files);
    setSelectedFileNames(fileNames);
  };

  const handleOrderStatusUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    updateOrderStatus(
      {
        id: order.id,
        status: newStatus,
        notify: shouldNotify,
      },
      { reason }
    ).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      } else {
        if (selectedFiles.length > 0) {
          listOrderAuditLogs({ order_id: order?.id }).then((listResp) => {
            if (listResp.error) {
              displayAPIErrorNotification(listResp.error);
              setTimeout(() => {
                setIsPending(false);
              }, 2000);
            } else {
              const latestOrderAuditLog =
                listResp.body[listResp.body.length - 1];
              const data = new FormData();
              for (let i = 0; i < selectedFiles.length; i += 1) {
                data.append(
                  "order-audit-log-images",
                  selectedFiles[i],
                  selectedFileNames[i]
                );
              }
              fetch(`/api/v1/order-logs/${latestOrderAuditLog?.id}/images`, {
                method: "POST",
                body: data,
              })
                .then((uploadResp) => {
                  if (uploadResp.status === 201) {
                    closeHandler(false);
                    setIsPending(false);
                    setStatus(newStatus);
                    setReason("");
                    setSelectedFiles([]);
                    displayNotification(
                      "successfully updated order status",
                      "success"
                    );
                  } else {
                    uploadResp.json().then((json) => {
                      displayAPIErrorNotification(json);
                      setTimeout(() => {
                        setIsPending(false);
                      }, 2000);
                    });
                  }
                })
                .catch((error) => {
                  displayAPIErrorNotification(error);
                  setTimeout(() => {
                    setIsPending(false);
                  }, 2000);
                });
            }
          });
        } else {
          closeHandler(false);
          setIsPending(false);
          setStatus(newStatus);
          setReason("");
          setSelectedFiles([]);
          displayNotification("successfully updated order status", "success");
        }
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      }
      closeHandler(false);
    });
  };

  const styles = useStyles();

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.paper} style={modalStyle()}>
        <Grid
          className={styles.mainGrid}
          container
          alignItems="center"
          justify="center"
        >
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => closeHandler(false)}
          />
          <Typography className={styles.header} variant="h4">
            Confirm Order Status Update
          </Typography>
        </Grid>
        <form onSubmit={(e) => handleOrderStatusUpdate(e)}>
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Typography className={styles.gridItem}>
                Updating order status from <strong>{ogStatus}</strong> to{" "}
                <strong>{newStatus}</strong>
              </Typography>
            </Grid>
            <FormGroup className={styles.notifyUserSwitch}>
              <FormControlLabel
                onChange={(e: React.SyntheticEvent) =>
                  setShouldNotify(!shouldNotify)
                }
                value={shouldNotify}
                control={<Switch />}
                label="Notify user via email?"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => setReason(e.target.value)}
              className={styles.inputField}
              name="reason"
              label="Reason(optional)"
              type="text"
              value={reason}
              fullWidth
            />
          </Grid>
          <Typography variant="h5" className={styles.subHeader}>
            Attach Images(Optional)
          </Typography>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Button
              className={styles.takePhotoBtn}
              color="secondary"
              variant="contained"
              onClick={handleClick}
            >
              Take a Photo <CameraAltIcon className={styles.btnIcon} />
            </Button>
          </Grid>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Button
              className={styles.uploadBtn}
              color="secondary"
              variant="contained"
              onClick={handleClick}
            >
              Select Images <PermMediaIcon className={styles.btnIcon} />
            </Button>
            <input
              name="order-audit-log-images"
              accept="image/*"
              type="file"
              onChange={handleAdd}
              style={{ display: "none" }}
              ref={hiddenFileInput}
              multiple
            />
          </Grid>
          <Typography className={styles.attachedImages}>
            Attached Images({selectedFiles.length})
          </Typography>
          <Button
            className={styles.updateBtn}
            type="submit"
            color="secondary"
            variant="contained"
          >
            <Typography>
              update <SaveIcon className={styles.updateBtnIcon} />
            </Typography>
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ConfirmOrderStatusUpdateModal;
