import React, { createContext, useState } from "react";
import dayjs from "dayjs";
import { Product } from "http/models";
import { listProducts } from "http/endpoints";

interface Props {}

interface state {
  products: Product[];
  fetchProducts: () => void;
}

const initialState: state = {
  products: [],
  fetchProducts: () => {},
};

export const productsContext = createContext<state>(initialState);

export const ProductsContextProvider: React.FC<Props> = ({ children }) => {
  const [products, updateProducts] = useState<Product[]>([]);

  const fetchProducts = () => {
    listProducts(null).then((resp) => {
      if (!resp.error) {
        if (resp.body !== null) {
          const newProducts = [...resp.body];
          const sortedProducts = newProducts.sort((a, b) => {
            if (dayjs(a.created_at).unix() < dayjs(b.created_at).unix()) {
              return -1;
            }
            return 1;
          });
          updateProducts(sortedProducts);
        } else {
          updateProducts([]);
        }
      }
    });
  };

  return (
    <productsContext.Provider value={{ products, fetchProducts }}>
      {children}
    </productsContext.Provider>
  );
};
