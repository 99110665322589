import React from "react";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      position: "absolute",
      width: 400,
      height: 260,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      transition: "all 3000ms ease;",
    },
    grid: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
    closeIcon: {
      marginLeft: "300px",
      marginTop: "-40px",
      color: `${theme.palette.grey[800]}`,
      height: "40px",
      width: "40px",
      opacity: "0.5",
      "&:hover": {
        color: "red",
        boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
        cursor: "pointer",
        borderRadius: "8px",
      },
    },
    scoot: {
      marginTop: theme.spacing(-2),
    },
    header: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    btn: {
      marginTop: theme.spacing(5),
    },
    gridItem: {
      marginTop: theme.spacing(3),
    },
    disableIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

interface Props {
  open: boolean;
  close: (shouldOpen: boolean) => void;
  productName: string;
  closeHandler: (show: boolean) => void;
  removeCartItemHandler: (e: React.FormEvent<HTMLFormElement>) => void;
}

const ConfirmRemoveCartItemModal: React.FC<Props> = ({
  open,
  close,
  productName,
  closeHandler,
  removeCartItemHandler,
}) => {
  const modalStyle = (): React.CSSProperties => ({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    textAlign: "center",
    borderRadius: "8px",
    padding: "45px",
  });

  const styles = useStyles();

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.paper} style={modalStyle()}>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <CloseIcon className={styles.closeIcon} onClick={() => close(true)} />
          <Typography className={styles.header} variant="h4">
            Remove Cart Item
          </Typography>
        </Grid>
        <form onSubmit={(e) => removeCartItemHandler(e)}>
          <Grid
            className={styles.scoot}
            container
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <Typography className={styles.gridItem}>
                Are you sure you want to remove <strong>{productName}</strong>{" "}
                from your cart?
              </Typography>
            </Grid>
          </Grid>
          <Button
            className={styles.btn}
            type="submit"
            color="secondary"
            variant="contained"
          >
            <Typography>Yes</Typography>
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ConfirmRemoveCartItemModal;
