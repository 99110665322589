import React from "react";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      // margin: theme.spacing(1),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
  }),
  { index: 1 }
);

const FadeIn: React.FC = ({ children }) => {
  const styles = useStyles();

  return (
    <Fade
      in
      // eslint-disable-next-line react/jsx-indent-props
      {...{ timeout: { appear: 300, enter: 750 } }}
    >
      <Paper elevation={0} className={styles.paper}>
        {children}
      </Paper>
    </Fade>
  );
};

export default FadeIn;
