import React from "react";
import { Button } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    btnSlide: {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      background: "#f1f1f1",
      border: "1px solid rgba(34, 34, 34, 0.287)",
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    prev: {
      top: "50%",
      left: "20px",
      transform: "translateY(-60%)",
    },
    next: {
      top: "50%",
      right: "20px",
      transform: "translateY(-60%)",
    },
  }),
  { index: 1 }
);

interface Props {
  direction: string;
  moveSlide: () => void;
}

const ButtonSlider: React.FC<Props> = ({ direction, moveSlide }) => {
  const styles = useStyles();
  return (
    <Button
      onClick={moveSlide}
      className={
        direction === "next"
          ? `${styles.btnSlide} ${styles.next}`
          : `${styles.btnSlide} ${styles.prev}`
      }
    >
      {direction === "next" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
    </Button>
  );
};

export default ButtonSlider;
