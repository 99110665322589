import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { notificationsContext } from "contexts/Notifications";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getProductReview, listProductReviews } from "http/endpoints";
import { ProductReview } from "http/models";
import { makeStyles, Typography, Button, Grid } from "@material-ui/core";
import FadeIn from "components/FadeIn";
import { userContext } from "contexts/User";
import CircularProgress from "@mui/material/CircularProgress";
import ReviewImageCard from "./ReviewImageCard";

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      marginTop: theme.spacing(16),
      padding: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(4),
    },
    uploadBtn: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(4),
    },
    btnIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

const EditProductReviewImages: React.FC = () => {
  const { user } = useContext(userContext);
  const { product_id } = useParams<{ product_id: string }>();
  const { review_id } = useParams<{ review_id: string }>();
  const styles = useStyles();
  const history = useHistory();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [review, setReview] = useState<ProductReview | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [trigger, setTrigger] = useState<boolean>(false);

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {
    getProductReview({ product_id, review_id }, null).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        history.push(`/product/${product_id}`);
        return;
      }
      setReview(resp.body as ProductReview);
    });
  }, [trigger]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleDone = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      history.push(`/product/${product_id}`);
    }
  };

  const handleAddImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsPending(true);
    const data = new FormData();
    if (!e.target.files) {
      return;
    }
    for (let i = 0; i < e.target.files.length; i += 1) {
      data.append(
        "product-review-images",
        e.target.files[i],
        e.target.files[i].name
      );
    }
    fetch(`/api/v1/products/${product_id}/reviews/${review_id}/images`, {
      method: "POST",
      body: data,
    })
      .then((resp) => {
        if (resp.status === 201) {
          setIsPending(false);
          displayNotification(
            "successfully uploaded product review images",
            "success"
          );
          setTrigger(!trigger);
        } else {
          resp.json().then((json) => {
            displayAPIErrorNotification(json);
            setTimeout(() => {
              setIsPending(false);
            }, 2000);
          });
        }
      })
      .catch((error) => {
        displayAPIErrorNotification(error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      });
  };

  if (!user) {
    return <CircularProgress />;
  }

  if (!user.is_staff) {
    history.push("/not-found");
  }

  if (!review) {
    return <CircularProgress />;
  }

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={styles.header} variant="h3">
          Edit Product Review Images
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Button
          className={styles.uploadBtn}
          color="secondary"
          variant="contained"
          onClick={handleClick}
        >
          Select <PermMediaIcon className={styles.btnIcon} />
        </Button>
        <input
          name="avatar"
          accept="image/*"
          type="file"
          onChange={handleAddImages}
          style={{ display: "none" }}
          ref={hiddenFileInput}
          multiple
        />
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Button
          className={styles.uploadBtn}
          color="secondary"
          variant="contained"
          onClick={handleDone}
        >
          Done <ArrowForwardIosIcon className={styles.btnIcon} />
        </Button>
      </Grid>
      {review.images ? (
        <Grid container alignItems="center" justify="center">
          {review.images.map((img) => (
            <ReviewImageCard
              key={img.id}
              productId={review.product_id}
              reviewImage={img}
            />
          ))}
        </Grid>
      ) : null}
    </FadeIn>
  );
};

export default EditProductReviewImages;
