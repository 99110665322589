import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Product, ProductService } from "http/models";
import { Checkbox, Grid, makeStyles, Tooltip } from "@material-ui/core";
import React, { useContext, useEffect, useRef } from "react";
import Switch from "@mui/material/Switch";
import { getProduct, updateProductService } from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import { cartContext } from "contexts/Cart";

const label = { inputProps: { "aria-label": "Switch demo" } };

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    accordion: {
      width: "90%",
      margin: theme.spacing(3),
      marginBottom: theme.spacing(0),
    },
    cost: {
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    editIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      marginTop: theme.spacing(2),
    },
    item: {
      borderRadius: "4px",
      margin: "0 auto",
      padding: theme.spacing(1),
      width: "90%",
      textAlign: "center",
      boxShadow: `1px 1px 8px ${theme.palette.grey[800]}`,
    },
    header: {
      padding: theme.spacing(2),
    },
    editAction: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(-4),
    },
    deleteAction: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(0),
    },
    value: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(12),
      },
    },
    addIcon: {
      marginLeft: theme.spacing(3),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      borderRadius: "4px",
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
    removeIcon: {
      marginRight: theme.spacing(3),
      borderRadius: "4px",
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
    addHeader: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(0),
    },
    checkBox: {
      marginTop: theme.spacing(1),
    },
  }),
  { index: 1 }
);

interface Props {
  productService: ProductService;
  inCart: boolean;
  appendToSelectedServices: () => void;
  removeFromSelectedServices: () => void;
}

const ProductServiceCard: React.FC<Props> = ({
  productService,
  inCart,
  appendToSelectedServices,
  removeFromSelectedServices,
}) => {
  const { id } = useParams<{ id: string }>();
  const styles = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [selected, setSelected] = React.useState<boolean>(false);
  const { cartItems, fetchCartItems } = useContext(cartContext);
  const [serviceInCart, setServiceInCart] = React.useState<boolean>(false);

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {
    fetchCartItems();
    for (let i = 0; i < cartItems.length; i += 1) {
      const item = cartItems[i];
      if (item.additional_services) {
        for (let j = 0; j < item.additional_services.length; j += 1) {
          if (item.additional_services[j].id === productService.id) {
            setServiceInCart(true);
          }
        }
      }
    }
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleCheck = () => {
    if (selected) {
      removeFromSelectedServices();
    } else {
      appendToSelectedServices();
    }
    setSelected(!selected);
    setExpanded(false);
  };

  const truncate = (s: string, len: number) =>
    s.length > len ? `${s.substring(0, len)}...` : s;

  return (
    <>
      <Accordion
        className={styles.accordion}
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large" />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Grid item xs={12} md={4}>
            <Typography className={styles.header} variant="h6">
              Name
            </Typography>
            <Typography className={styles.cost} variant="subtitle1">
              {truncate(productService.name, 20)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={styles.header} variant="h6">
              Price
            </Typography>
            <Typography className={styles.cost} variant="subtitle1">
              ${productService.total}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={styles.addHeader} variant="h6">
              Add
            </Typography>
            <Checkbox
              className={styles.checkBox}
              disabled={inCart}
              checked={selected || serviceInCart}
              onChange={handleCheck}
            />
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={styles.header} variant="h6">
                Description
              </Typography>
              <Typography className={styles.cost} variant="subtitle1">
                {productService.description}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ProductServiceCard;
