import Typography from "@material-ui/core/Typography";
import { Product, CartItem as Item, ProductVariation } from "http/models";
import { Grid, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useContext } from "react";
import { getProduct, updateCartItem, deleteCartItem } from "http/endpoints";
import CircularProgress from "@mui/material/CircularProgress";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { notificationsContext } from "contexts/Notifications";
import { cartContext } from "contexts/Cart";
import CartItemImage from "./CartItemImage";
import ConfirmRemoveCartItemModal from "./ConfirmRemoveCartItemModal";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    cost: {
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(8),
      },
    },
    editIcon: {
      marginTop: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      marginTop: theme.spacing(2),
    },
    item: {
      borderRadius: "4px",
      margin: "0 auto",
      padding: theme.spacing(1),
      width: "90%",
      textAlign: "center",
      boxShadow: `1px 1px 8px ${theme.palette.grey[800]}`,
    },
    header: {
      margin: theme.spacing(2),
    },
    value: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(12),
      },
    },
    addIcon: {
      marginLeft: theme.spacing(3),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      borderRadius: "4px",
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
    removeIcon: {
      marginRight: theme.spacing(3),
      borderRadius: "4px",
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
  }),
  { index: 1 }
);

interface Props {
  item: Item;
  refreshTotal: () => void;
}

const CartItem: React.FC<Props> = ({ item, refreshTotal }) => {
  const styles = useStyles();
  const { cartItems, fetchCartItems } = useContext(cartContext);
  const [show, setShow] = useState<boolean>(true);
  const [variation, setVariation] = useState<ProductVariation | null>(null);
  const [removeCartItem, setIsPendingRemoveCartItem] = useState<boolean>(false);
  const [
    shouldOpenConfirmRemoveCartItemModal,
    setShouldOpenConfirmRemoveCartItemModal,
  ] = useState<boolean>(false);

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {
    getProduct({ id: item.product_id }).then((resp) => {
      if (resp.status.code !== 200 || !resp.body) {
        return;
      }
      const p = resp.body as Product;
      if (p) {
        const v = p.variations.find(
          (currentVariation) => currentVariation.id === item.variation_id
        );
        if (v) {
          setVariation(v);
        }
      }
    });
  }, [cartItems]);

  const toPrice = (priceString: string): number => {
    let price: number;
    if (priceString.includes(".")) {
      const f = parseFloat(priceString);
      price = Number(f.toFixed(2));
    } else {
      price = parseInt(priceString, 10);
    }
    return price;
  };

  const handlePriceChange = (priceString: string, quantity: number): number => {
    const price = toPrice(priceString) * quantity;
    refreshTotal();
    return Number(price.toFixed(2));
  };

  const calculateItemTotal = (cartItem: Item): number => {
    let price = toPrice(cartItem.price) * cartItem.quantity;

    if (cartItem.additional_services?.length) {
      for (let i = 0; i < cartItem.additional_services.length; i += 1) {
        price += toPrice(cartItem.additional_services[i].total);
      }
    }
    const itemTotal = Number(price.toFixed(2));
    return itemTotal;
  };

  const calculateAdditionalServices = (): number => {
    let additionalServicesTotalCost: number = 0;

    if (item.additional_services?.length) {
      for (let i = 0; i < item.additional_services.length; i += 1) {
        additionalServicesTotalCost += toPrice(
          item.additional_services[i].total
        );
      }
    }
    return additionalServicesTotalCost;
  };

  const handleIncrement = () => {
    updateCartItem(
      { id: item.id },
      {
        quantity: item.quantity + 1,
        product_id: item.product_id,
      }
    ).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
      } else {
        fetchCartItems();
      }
    });
  };

  const handleDecrement = () => {
    if (item.quantity === 1) {
      setShouldOpenConfirmRemoveCartItemModal(true);
      return;
    }
    updateCartItem(
      { id: item.id },
      {
        quantity: item.quantity - 1,
        product_id: item.product_id,
      }
    ).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
      } else {
        fetchCartItems();
      }
    });
  };

  const handleRemoveCartItem = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPendingRemoveCartItem(true);
    deleteCartItem({ id: item.id }).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPendingRemoveCartItem(false);
        }, 2000);
      } else {
        setShow(false);
        setTimeout(() => {
          fetchCartItems();
          displayNotification("successfully removed cart item", "success");
        }, 500);
        setTimeout(() => {
          setIsPendingRemoveCartItem(false);
        }, 2000);
      }
    });
    setShouldOpenConfirmRemoveCartItemModal(false);
  };

  if (!variation) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ flexGrow: 1 }} className={show ? styles.card : styles.fadeOut}>
      <Grid container className={styles.item}>
        <Grid item xs={12} md={4}>
          <Typography className={styles.header} variant="h4">
            Product
          </Typography>
          <Typography variant="h6">{variation.name}</Typography>
          <CartItemImage
            src={variation.images.find((img) => img.is_primary)?.url}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className={styles.header} variant="h4">
            Quantity
          </Typography>
          <Typography className={styles.value} variant="h4">
            <RemoveIcon
              className={styles.removeIcon}
              onClick={handleDecrement}
            />
            {item.quantity}
            <AddIcon className={styles.addIcon} onClick={handleIncrement} />
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className={styles.header} variant="h4">
            Price
          </Typography>
          <Typography className={styles.cost} variant="subtitle1">
            Cost(${item.price})
          </Typography>
          <Typography>
            {item.additional_services?.length
              ? `+ Additional Services($${calculateAdditionalServices()})`
              : ""}
          </Typography>
          <Typography>x Quantity({item.quantity})</Typography>
          <Typography>
            <strong>Item Total: ${calculateItemTotal(item).toFixed(2)}</strong>
          </Typography>
        </Grid>
      </Grid>
      <ConfirmRemoveCartItemModal
        open={shouldOpenConfirmRemoveCartItemModal}
        close={() => setShouldOpenConfirmRemoveCartItemModal(false)}
        productName={variation.name}
        closeHandler={() => setShouldOpenConfirmRemoveCartItemModal(false)}
        removeCartItemHandler={(e) => handleRemoveCartItem(e)}
      />
    </Box>
  );
};

export default CartItem;
