import React, { useState } from "react";
import "./Slider.css";
import { ProductVariation } from "http/models";
import ButtonSlider from "components/ButtonSlider";
import { CardMedia, makeStyles } from "@material-ui/core";
import FadeIn from "components/FadeIn";

interface Props {
  variation: ProductVariation;
}

const useStyles = makeStyles(
  (theme) => ({
    slider: {
      width: "500px",
      height: "500px",
      margin: "100px auto 0",
      position: "relative",
      overflow: "hidden",
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
      marginBottom: "30px",
      [theme.breakpoints.up("md")]: {
        height: "840px",
        width: "840px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "900px",
        width: "900px",
      },
      [theme.breakpoints.up("xl")]: {
        height: "1260",
        width: "960px",
      },
    },
  }),
  { index: 1 }
);

const Slider: React.FC<Props> = ({ variation }) => {
  const [slideIndex, setSlideIndex] = useState<number>(1);
  const styles = useStyles();
  console.log(`checking variation: ${JSON.stringify(variation)}`);

  const nextSlide = () => {
    if (slideIndex !== variation.images.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === variation.images.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(variation.images.length);
    }
  };

  const moveDot = (index: number) => {
    setSlideIndex(index);
  };

  const resolveURL = (url: string | undefined): string => {
    if (!url) {
      return "";
    }
    let imgPath = `/img/product-variations/${url}`;
    if (url.includes("storage.googleapis.com/")) {
      imgPath = `https://${url}`;
    }
    return imgPath;
  };

  return (
    <FadeIn>
      <div className={styles.slider}>
        {variation.images &&
          variation.images.length > 0 &&
          variation.images.map((img, index) => (
            <div
              key={img.id}
              className={
                slideIndex === index + 1 ? "slide active-anim" : "slide"
              }
            >
              <CardMedia
                component="img"
                height="100%"
                image={resolveURL(img.url)}
                alt={img.url}
              />
            </div>
          ))}

        <ButtonSlider moveSlide={prevSlide} direction="prev" />
        <ButtonSlider moveSlide={nextSlide} direction="next" />

        <div className="container-dots">
          {Array.from({ length: variation.images.length }).map(
            (item, index) => (
              <div
                role="button"
                aria-label="navigate"
                tabIndex={index}
                onClick={() => moveDot(index + 1)}
                onKeyDown={() => moveDot(index + 1)}
                className={slideIndex === index + 1 ? "dot active" : "dot"}
              />
            )
          )}
        </div>
      </div>
    </FadeIn>
  );
};

export default Slider;
