import { useHistory, Link, Redirect } from "react-router-dom";
import React, { useContext, useState } from "react";
import { register } from "http/endpoints";
import { userContext } from "contexts/User";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { notificationsContext } from "contexts/Notifications";
import FadeIn from "components/FadeIn";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { FcGoogle } from "react-icons/fc";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(8),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(4),
    },
    btn: {
      marginTop: theme.spacing(-1),
    },
    moveUp: {
      marginTop: theme.spacing(-4),
    },
    forgotPassword: {
      width: "200px",
      marginLeft: "30px",
      marginTop: theme.spacing(2),
      color: theme.palette.primary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    registerIcon: {
      marginLeft: theme.spacing(1),
    },
    inputField: {
      width: theme.spacing(30),
      marginLeft: theme.spacing(7),
      marginTop: theme.spacing(2),
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(3),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
    spacer5: {
      height: theme.spacing(5),
      width: "100%",
    },
    spacer6: {
      height: theme.spacing(6),
      width: "100%",
    },
    spacer7: {
      height: theme.spacing(7),
      width: "100%",
    },
    spacer8: {
      height: theme.spacing(8),
      width: "100%",
    },
    spacer9: {
      height: theme.spacing(9),
      width: "100%",
    },
    spacer10: {
      height: theme.spacing(10),
      width: "100%",
    },
  }),
  { index: 1 }
);

const SignUp: React.FC = () => {
  const history = useHistory();
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isPending, setIsPending] = useState<boolean>(false);
  const [usernameFieldHasError, setUsernameFieldHasError] =
    useState<boolean>(false);
  const [emailFieldHasError, setEmailFieldHasError] = useState<boolean>(false);
  const { user } = useContext(userContext);
  const styles = useStyles();
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  const openOauthConsent = () => {
    window.open("/api/v1/sessions/oauth-sign-in");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    setUsernameFieldHasError(false);
    setEmailFieldHasError(false);

    register(null, { username, email, password }).then((resp) => {
      if (resp.error) {
        if (resp.error.error.detail) {
          if (resp.error.error.detail.includes("email is already taken")) {
            setEmailFieldHasError(true);
          }
          if (resp.error.error.detail.includes("username is already taken")) {
            setUsernameFieldHasError(true);
          }
        }
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      } else {
        setTimeout(() => {
          history.push("/verify-email");
          displayNotification(
            "verification code successfully emailed",
            "success"
          );
        }, 1000);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      }
    });
  };

  if (user !== null) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <form onSubmit={handleSubmit} noValidate>
            <Grid
              className={styles.grid}
              container
              alignItems="center"
              justify="center"
            >
              <Typography variant="h3">Sign Up</Typography>
            </Grid>
            <FadeIn>
              <div className={styles.spacer4} />
              <Grid item xs={12} className={styles.moveUp}>
                <TextField
                  onChange={(e) => setUsername(e.target.value.trim())}
                  className={styles.inputField}
                  label="Username"
                  type="text"
                  error={usernameFieldHasError}
                  fullWidth
                  required
                >
                  {username}
                </TextField>
              </Grid>
              <div className={styles.spacer4} />
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setEmail(e.target.value.trim())}
                  className={styles.inputField}
                  label="Email"
                  type="email"
                  error={emailFieldHasError}
                  fullWidth
                  required
                >
                  {email}
                </TextField>
              </Grid>
              <div className={styles.spacer4} />
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setPassword(e.target.value.trim())}
                  className={styles.inputField}
                  label="Password"
                  type="password"
                  fullWidth
                  required
                >
                  {password}
                </TextField>
              </Grid>
              <Grid
                className={styles.grid}
                direction="column"
                container
                alignItems="center"
                justify="center"
              >
                {isPending ? (
                  <Button
                    className={styles.btn}
                    color="default"
                    disabled
                    variant="contained"
                  >
                    Registering...
                  </Button>
                ) : (
                  <Button
                    className={styles.btn}
                    type="submit"
                    color="secondary"
                    variant="contained"
                  >
                    <Typography>Register</Typography>
                    <AssignmentIndIcon className={styles.registerIcon} />
                  </Button>
                )}
                <Grid
                  className={styles.grid}
                  direction="column"
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Button
                    onClick={openOauthConsent}
                    color="secondary"
                    variant="contained"
                    endIcon={<FcGoogle />}
                  >
                    Google Sign In
                  </Button>
                  <Link to="/log-in" className={styles.forgotPassword}>
                    <Typography>Got an account already?</Typography>
                  </Link>
                </Grid>
              </Grid>
            </FadeIn>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default SignUp;
