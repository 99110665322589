import React, { useState } from "react";
import "./Slider.css";
import { ProductReview } from "http/models";
import ButtonSlider from "components/ButtonSlider";
import { CardMedia } from "@material-ui/core";
import FadeIn from "components/FadeIn";

interface Props {
  review: ProductReview;
}

const Slider: React.FC<Props> = ({ review }) => {
  const [slideIndex, setSlideIndex] = useState<number>(1);
  const nextSlide = () => {
    if (slideIndex !== review.images.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === review.images.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(review.images.length);
    }
  };

  const moveDot = (index: number) => {
    setSlideIndex(index);
  };

  const resolveURL = (url: string | undefined): string => {
    if (!url) {
      return "";
    }
    let imgPath = `/img/reviews/${url}`;
    if (url.includes("storage.googleapis.com/")) {
      imgPath = `https://${url}`;
    }
    return imgPath;
  };

  return (
    <FadeIn>
      <div className="container-slider">
        {review.images.map((img, index) => (
          <div
            key={img.id}
            className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
          >
            <CardMedia
              component="img"
              height="50%"
              image={resolveURL(img.url)}
              alt={img.url}
            />
          </div>
        ))}

        <ButtonSlider moveSlide={prevSlide} direction="prev" />
        <ButtonSlider moveSlide={nextSlide} direction="next" />

        <div className="container-dots">
          {Array.from({ length: review.images.length }).map((item, index) => (
            <div
              role="button"
              aria-label="navigate"
              tabIndex={index}
              onClick={() => moveDot(index + 1)}
              onKeyDown={() => moveDot(index + 1)}
              className={slideIndex === index + 1 ? "dot active" : "dot"}
            />
          ))}
        </div>
      </div>
    </FadeIn>
  );
};

export default Slider;
