import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import VerifiedUserOutlined from "@material-ui/icons/VerifiedUserOutlined";
import ErrorOutlined from "@material-ui/icons/ErrorOutlined";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmDisableMFAModal from "pages/Settings/ConfirmDisableMFAModal";
import { PhoneNumber } from "http/models";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { DeleteOutlined } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { userContext } from "contexts/User";
import { phoneNumbersContext } from "contexts/PhoneNumbers";
import {
  deletePhoneNumber,
  enableMultiFactorAuth,
  disableMultiFactorAuth,
} from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import SendIcon from "@material-ui/icons/Send";
import ScreenLockPortraitIcon from "@material-ui/icons/ScreenLockPortrait";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    btn: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    verifiedStatus: {
      height: theme.spacing(4),
      width: theme.spacing(4),
      opacity: "0.7",
      color: "green",
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
    unVerifiedStatus: {
      height: theme.spacing(4),
      width: theme.spacing(4),
      opacity: "0.7",
      color: "red",
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    phoneNumber: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      margin: theme.spacing(1),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      padding: theme.spacing(1),
      width: theme.spacing(36),
    },
    sendIcon: {
      marginLeft: theme.spacing(1),
    },
    screenLockIcon: {
      marginLeft: theme.spacing(1),
    },
    disableIcon: {
      marginLeft: theme.spacing(1),
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(3),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
    spacer5: {
      height: theme.spacing(5),
      width: "100%",
    },
    spacer6: {
      height: theme.spacing(6),
      width: "100%",
    },
    spacer7: {
      height: theme.spacing(7),
      width: "100%",
    },
    spacer8: {
      height: theme.spacing(8),
      width: "100%",
    },
    spacer9: {
      height: theme.spacing(9),
      width: "100%",
    },
    spacer10: {
      height: theme.spacing(10),
      width: "100%",
    },
    paper: {
      margin: theme.spacing(1),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
  }),
  { index: 1 }
);

interface Props {
  phoneNumber: PhoneNumber;
  // eslint-disable-next-line no-unused-vars
  initiateVerifyPhoneNumberHandler: (id: string) => void;
}

const PhoneNumberCard: React.FC<Props> = ({
  phoneNumber,
  initiateVerifyPhoneNumberHandler,
}) => {
  const { setUser } = useContext(userContext);
  const { fetchPhoneNumbers } = useContext(phoneNumbersContext);
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);
  const styles = useStyles();
  const [shouldOpenDisableMFAModal, setShouldOpenDisableMFAModal] =
    useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const [isPendingDisableMFA, setIsPendingDisableMFA] =
    useState<boolean>(false);
  const [isPendingEnableMFA, setIsPendingEnableMFA] = useState<boolean>(false);
  const [isPendingCodeResend, setIsPendingCodeResend] =
    useState<boolean>(false);

  const handleDelete = (id: string) => {
    deletePhoneNumber({ id }).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
      } else {
        setShow(false);
        displayNotification("successfully deleted phone number", "success");
      }
    });
  };

  const handleEnableMultiFactorAuth = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    e.preventDefault();
    setIsPendingEnableMFA(true);
    enableMultiFactorAuth({ id }).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPendingEnableMFA(false);
        }, 2000);
      } else {
        setTimeout(() => {
          fetchPhoneNumbers();
          setUser();
          displayNotification("successfully enabled 2FA", "success");
        }, 500);
        setTimeout(() => {
          setIsPendingEnableMFA(false);
        }, 2000);
      }
    });
  };

  const handleDisableMultiFactorAuth = (
    e: React.FormEvent<HTMLFormElement>,
    id: string
  ) => {
    e.preventDefault();
    setIsPendingDisableMFA(true);
    disableMultiFactorAuth({ id }).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPendingDisableMFA(false);
        }, 2000);
      } else {
        setTimeout(() => {
          fetchPhoneNumbers();
          setUser();
          displayNotification("successfully disabled 2FA", "success");
        }, 500);
        setTimeout(() => {
          setIsPendingDisableMFA(false);
        }, 2000);
      }
    });
    setShouldOpenDisableMFAModal(false);
  };

  const handleResendCode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsPendingCodeResend(true);
    initiateVerifyPhoneNumberHandler(phoneNumber.id);
    setTimeout(() => {
      setIsPendingCodeResend(false);
    }, 2200);
  };

  return (
    <Card variant="outlined" className={show ? styles.card : styles.fadeOut}>
      <CardHeader
        action={
          phoneNumber.is_mfa_number ? null : (
            <Tooltip title="delete" placement="top">
              <IconButton
                className={styles.deleteIcon}
                onClick={() => handleDelete(phoneNumber.id)}
                disabled={phoneNumber.is_mfa_number}
              >
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          )
        }
        title={
          <Grid direction="row" justify="center" alignItems="center">
            <Typography className={styles.phoneNumber} variant="h4">
              {phoneNumber.phone_number}
            </Typography>
          </Grid>
        }
      />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
      >
        {phoneNumber.is_mfa_number ? (
          <Grid item>
            {isPendingDisableMFA ? (
              <Button
                className={styles.btn}
                color="default"
                variant="contained"
                disabled
              >
                Disabling...
              </Button>
            ) : (
              <>
                <Button
                  className={styles.btn}
                  onClick={() => setShouldOpenDisableMFAModal(true)}
                  type="submit"
                  color="secondary"
                  variant="contained"
                >
                  <Typography>Disable 2FA</Typography>
                  <NotInterestedIcon className={styles.disableIcon} />
                </Button>
                <ConfirmDisableMFAModal
                  open={shouldOpenDisableMFAModal}
                  close={() => setShouldOpenDisableMFAModal(false)}
                  phoneNumber={phoneNumber}
                  closeHandler={() => setShouldOpenDisableMFAModal(false)}
                  disableMFAHandler={(e) =>
                    handleDisableMultiFactorAuth(e, phoneNumber.id)
                  }
                />
              </>
            )}
          </Grid>
        ) : (
          <Grid item>
            {isPendingEnableMFA ? (
              <Button
                className={styles.btn}
                disabled
                color="default"
                variant="contained"
              >
                Enabling...
              </Button>
            ) : (
              <Button
                className={styles.btn}
                onClick={
                  phoneNumber.is_verified
                    ? (e) => handleEnableMultiFactorAuth(e, phoneNumber.id)
                    : (e) => handleResendCode(e)
                }
                type={isPendingCodeResend ? undefined : "submit"}
                color={isPendingCodeResend ? "default" : "secondary"}
                variant="contained"
                disabled={isPendingCodeResend}
              >
                {phoneNumber.is_verified ? (
                  <>
                    <Typography>Enable 2FA</Typography>
                    <ScreenLockPortraitIcon className={styles.screenLockIcon} />
                  </>
                ) : (
                  <>
                    {isPendingCodeResend ? (
                      <>
                        <Typography>Sending...</Typography>
                      </>
                    ) : (
                      <>
                        <Typography>Send Code</Typography>{" "}
                        <SendIcon className={styles.sendIcon} />
                      </>
                    )}
                  </>
                )}
              </Button>
            )}
          </Grid>
        )}
        <Grid item>
          {phoneNumber.is_verified ? (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
            >
              <Tooltip title="verified" placement="left">
                <VerifiedUserOutlined className={styles.verifiedStatus} />
              </Tooltip>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
            >
              <Tooltip title="unverified" placement="left">
                <ErrorOutlined className={styles.unVerifiedStatus} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default PhoneNumberCard;
