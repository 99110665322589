import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import FadeIn from "components/FadeIn";
import React, { useState, useContext, useEffect } from "react";
import { productsContext } from "contexts/Products";
import { cartContext } from "contexts/Cart";
import { notificationsContext } from "contexts/Notifications";
import { calculateShipping, clearCart } from "http/endpoints";
import { useHistory } from "react-router-dom";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { userContext } from "contexts/User";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import CartItem from "./CartItem";
import Checkout from "./Checkout";

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      padding: theme.spacing(2),
    },
    header: {
      marginTop: theme.spacing(14),
      marginBottom: theme.spacing(6),
    },
    subGrid: {
      padding: theme.spacing(3),
    },
    subHeader: {
      marginTop: theme.spacing(-6),
      marginBottom: theme.spacing(2),
    },
    checkoutBtn: {
      marginBottom: theme.spacing(2),
    },
    clearCartBtn: {
      marginBottom: theme.spacing(4),
    },
  }),
  { index: 1 }
);

const Cart: React.FC = () => {
  const { user } = useContext(userContext);
  const { products, fetchProducts } = useContext(productsContext);
  const { cartItems, fetchCartItems } = useContext(cartContext);
  const [salesTax, setSalesTax] = useState<number>(0);
  const [preTaxTotal, setPreTaxTotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const styles = useStyles();
  const history = useHistory();
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {
    fetchProducts();
    fetchCartItems();
    setPreTaxTotal(calculatePreTaxTotal());
  }, []);

  const toPrice = (priceString: string): number => {
    let price: number;
    if (priceString.includes(".")) {
      const f = parseFloat(priceString);
      price = Number(f.toFixed(2));
    } else {
      price = parseInt(priceString, 10);
    }
    return price;
  };

  const getPrice = (productID: string, variationID: string): number => {
    const p = products.find(
      (currentProduct) => currentProduct.id === productID
    );
    if (p) {
      const v = p.variations.find(
        (currentVariation) => currentVariation.id === variationID
      );
      if (v) {
        return toPrice(v.price);
      }
    }
    return 0;
  };

  const calculatePreTaxTotal = (): number => {
    let result: number = 0;
    for (let i = 0; i < cartItems.length; i += 1) {
      const cartItem = cartItems[i];
      result +=
        getPrice(cartItem.product_id, cartItem.variation_id) *
        cartItems[i].quantity;

      if (cartItem.additional_services) {
        const numServices = cartItem.additional_services.length;
        if (numServices > 0) {
          for (let j = 0; j < numServices; j += 1) {
            result += toPrice(cartItem.additional_services[j].total);
          }
        }
      }
    }
    return result;
  };

  const calculateSalesTax = (): number => calculatePreTaxTotal() * 0.04;

  const calculateTotal = () => {
    fetchCartItems();
    setTotal(calculateSalesTax() + calculatePreTaxTotal());
  };

  const handleClearCart = () => {
    clearCart(null, null).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
      } else {
        fetchCartItems();
        displayNotification("successfully cleared cart", "success");
      }
    });
  };

  if (!user) {
    return <CircularProgress />;
  }

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={styles.header} variant="h3">
          Cart
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Button
          className={styles.clearCartBtn}
          onClick={handleClearCart}
          type="button"
          color="secondary"
          variant="contained"
          disabled={cartItems.length === 0}
        >
          Clear Cart <DeleteIcon />
        </Button>
      </Grid>
      <Grid container alignItems="center" justify="center">
        {cartItems.length > 0 ? (
          cartItems.map((cartItem) => (
            <CartItem
              key={cartItem.id}
              item={cartItem}
              refreshTotal={calculateTotal}
            />
          ))
        ) : (
          <Typography variant="h6">No Items In Cart Yet</Typography>
        )}
      </Grid>
      <Checkout
        preTaxTotal={calculatePreTaxTotal()}
        salesTax={calculateSalesTax()}
        total={calculatePreTaxTotal() + calculateSalesTax()}
      />
    </FadeIn>
  );
};

export default Cart;
