import Snackbar from "@material-ui/core/Snackbar";
import React, { useContext } from "react";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { notificationsContext } from "../contexts/Notifications";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }),
  { index: 1 }
);

export interface Notification {
  level: Color;
  message: string;
}

const Alert = (props: AlertProps) => (
  <MuiAlert elevation={6} variant="filled" {...props} />
);

export const DisplayNotification: React.FC = () => {
  const styles = useStyles();
  const {
    notification,
    shouldDisplayNotification,
    setShouldDisplayNotification,
  } = useContext(notificationsContext);

  const handleClose = () => {
    setShouldDisplayNotification(false);
  };

  return (
    <div className={styles.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={shouldDisplayNotification}
        onClose={handleClose}
        autoHideDuration={2000}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert severity={notification?.level} onClose={handleClose}>
          {notification?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
