import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      textAlign: "center",
      padding: theme.spacing(15),
    },
    btn: {
      marginTop: theme.spacing(4),
    },
  }),
  { index: 1 }
);

const PageNotFound = () => {
  const history = useHistory();
  const styles = useStyles();

  useEffect(() => {
    history.replace("/not-found");
  }, []);

  return (
    <div className={styles.grid}>
      <Typography variant="h4">Not Found</Typography>
      <Button
        className={styles.btn}
        color="secondary"
        variant="contained"
        onClick={() => {
          history.push("/");
        }}
      >
        Go home
      </Button>
    </div>
  );
};

export default PageNotFound;
