import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React from "react";

interface TabPanelProps {
  index: number;
  value: number;
  other?: any;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-prevent-tabpanel-${index}`}
    aria-labelledby={`scrollable-prevent-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

export default TabPanel;
