import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { notificationsContext } from "contexts/Notifications";
import { makeStyles, Typography, TextField, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import FadeIn from "components/FadeIn";
import { ProductReview } from "http/models";
import {
  getProductReview,
  listProductReviews,
  updateProductReview,
} from "http/endpoints";
import { userContext } from "contexts/User";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Rating } from "@mui/material";
import PermMediaIcon from "@material-ui/icons/PermMedia";

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      marginTop: theme.spacing(12),
      padding: theme.spacing(1),
    },
    subGrid: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },
    header: {
      marginTop: theme.spacing(4),
    },
    inputField: {
      marginTop: theme.spacing(3),
      width: "300px",
    },
    saveBtn: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(12),
    },
    saveIcon: {
      marginLeft: theme.spacing(1),
    },
    editImagesBtn: {
      marginTop: theme.spacing(-6),
      marginBottom: theme.spacing(12),
    },
    btnIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

const EditProductReview: React.FC = () => {
  const { user } = useContext(userContext);
  const styles = useStyles();
  const history = useHistory();
  const { product_id } = useParams<{ product_id: string }>();
  const { review_id } = useParams<{ review_id: string }>();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [newStars, setStars] = useState<number | null>(0);
  const [newReviewDescription, setNewReviewDescription] = useState<string>("");
  const [review, setReview] = useState<ProductReview | null>(null);
  const [headline, setHeadline] = useState<string>("");

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {
    getProductReview({ product_id, review_id }, null).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        history.push(`/products/${product_id}`);
        return;
      }
      const pr = resp.body as ProductReview;
      setReview(pr);
      setStars(pr.stars);
      setNewReviewDescription(pr.review);
      setHeadline(pr.headline);
    });
  }, []);

  // eslint-disable-next-line consistent-return
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsPending(true);

    if (!review) {
      setIsPending(false);
      history.push("/not-found");
      return;
    }

    let { stars } = review;
    let description = review.review;
    if (newStars && newStars !== 0 && stars !== newStars) {
      stars = newStars;
    }

    if (
      newReviewDescription &&
      newReviewDescription !== "" &&
      description !== newReviewDescription
    ) {
      description = newReviewDescription;
    }

    updateProductReview(
      { product_id, review_id },
      { stars, review: description, headline }
    ).then((resp) => {
      if (resp.error) {
        setTimeout(() => {
          displayAPIErrorNotification(resp.error);
        }, 1000);
      } else {
        setTimeout(() => {
          displayNotification("successfully updated product review", "success");
          history.push(
            `/product/${review.product_id}/reviews/${review.id}/images/edit`
          );
        }, 1000);
      }
      setTimeout(() => {
        setIsPending(false);
      }, 2000);
    });
  }

  if (!user) {
    return <CircularProgress />;
  }

  if (!user.is_staff) {
    history.push("/not-found");
  }

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={styles.header} variant="h3">
          Edit Product Review
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit} noValidate>
        <Grid
          className={styles.subGrid}
          container
          alignItems="center"
          justify="center"
        >
          <Box sx={{ "& > legend": { mt: 2 } }}>
            <Typography component="legend">Rating</Typography>
            <Rating
              name="simple-controlled"
              value={newStars}
              defaultValue={review?.stars}
              size="large"
              onChange={(e, newValue) => {
                setStars(newValue);
              }}
            />
          </Box>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <TextField
            fullWidth
            onChange={(e) => {
              setHeadline(e.target.value);
            }}
            className={styles.inputField}
            name="headline"
            label="Headline"
            type="text"
            value={headline}
            required
          />
        </Grid>
        <Grid
          className={styles.subGrid}
          container
          alignItems="center"
          justify="center"
        >
          <TextField
            fullWidth
            onChange={(e) => {
              setNewReviewDescription(e.target.value);
            }}
            className={styles.inputField}
            name="review"
            label="Review"
            type="text"
            value={newReviewDescription}
            multiline
            rows={6}
            required
          />
        </Grid>
        <Grid container alignItems="center" justify="center">
          {isPending ? (
            <Button
              className={styles.saveBtn}
              color="default"
              disabled
              variant="contained"
            >
              saving...
            </Button>
          ) : (
            <Button
              className={styles.saveBtn}
              type="submit"
              color="secondary"
              variant="contained"
            >
              Save <SaveIcon className={styles.saveIcon} />
            </Button>
          )}
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Button
            className={styles.editImagesBtn}
            color="secondary"
            variant="contained"
            onClick={(e) =>
              history.push(
                `/product/${review?.product_id}/reviews/${review?.id}/images/edit`
              )
            }
          >
            Edit Images
            <PermMediaIcon className={styles.btnIcon} />
          </Button>
        </Grid>
      </form>
    </FadeIn>
  );
};

export default EditProductReview;
