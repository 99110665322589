import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardContent from "@mui/material/CardContent";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Product, ProductReview } from "http/models";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  CardActions,
  Collapse,
  Grid,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AccountCircle } from "@material-ui/icons";
import { Rating } from "@mui/material";
import { userContext } from "contexts/User";
import { getProduct } from "http/endpoints";
import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz";
import Slider from "./Slider";

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      padding: theme.spacing(4),
    },
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    avatar: {
      marginLeft: theme.spacing(2),
    },
    visitIcon: {
      marginLeft: theme.spacing(1),
    },
    accordion: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(-1),
    },
    accordionSummary: {
      margin: "auto",
    },
    editIcon: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(4),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    productName: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },

    card: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(-12),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      padding: theme.spacing(1),
      width: theme.spacing(60),
      "&:hover": {
        cursor: "pointer",
        boxShadow: `1px 1px 8px ${theme.palette.grey[800]}`,
      },
    },
    cardContent: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
    ratings: {
      marginLeft: theme.spacing(3.5),
      marginTop: theme.spacing(-2),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      marginTop: theme.spacing(2),
    },
  }),
  { index: 1 }
);

interface Props {
  review: ProductReview;
  allowRedirectToProductPage: boolean;
}

const ReviewCard: React.FC<Props> = ({
  review,
  allowRedirectToProductPage,
}) => {
  const history = useHistory();
  const styles = useStyles();
  const [show, setShow] = useState<boolean>(true);
  const { user } = useContext(userContext);
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    getProduct({ id: review.product_id }).then((resp) => {
      if (resp.status.code !== 200 || !resp.body) {
        return;
      }
      setProduct(resp.body);
    });
  }, []);

  const resolveAvatarURL = (url: string | undefined): string => {
    if (!url) {
      return "";
    }
    let imgPath = `/img/avatars/${url}`;
    if (url.includes("storage.googleapis.com/")) {
      imgPath = `https://${url}`;
    }
    return imgPath;
  };

  const handleClick = () => {
    history.push(`/product/${review.product_id}/reviews/${review.id}`);
  };

  const visitProduct = () => {
    history.push(`/product/${product?.id}`);
  };

  const truncate = (s: string, n: number) =>
    s.length > n ? `"${s.substring(0, n)}"...` : s;

  const [reviewExpanded, setReviewExpanded] = React.useState<string | false>(
    false
  );
  const handleToggleReviewExpanded =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setReviewExpanded(isExpanded ? panel : false);
    };

  return (
    <Card className={show ? styles.card : styles.fadeOut}>
      <CardHeader
        avatar={
          review.created_by_user_avatar ? (
            <Avatar
              src={`${resolveAvatarURL(review.created_by_user_avatar)}`}
              style={{ height: "4rem", width: "4rem" }}
              className={styles.avatar}
            />
          ) : (
            <Avatar
              style={{ height: "4rem", width: "4rem" }}
              className={styles.avatar}
            >
              <AccountCircle style={{ height: "100%", width: "100%" }} />
            </Avatar>
          )
        }
        title={review.created_by_username}
        subheader={format(new Date(review.created_at), "dd/MM/yyy")}
        action={
          review.created_by_user_id === user?.id ? (
            <Tooltip title="edit your review" placement="top">
              <IconButton className={styles.editIcon} onClick={handleClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : null
        }
      />
      {allowRedirectToProductPage ? (
        <Typography variant="subtitle1" className={styles.productName}>
          {/* Product Name: {product?.name} */}
          <Tooltip title="visit product" placement="right">
            <IconButton className={styles.visitIcon} onClick={visitProduct}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      ) : null}
      {review.images ? <Slider review={review} /> : null}
      <CardActions>
        <Rating
          className={styles.ratings}
          name="Rating"
          value={review.stars}
          readOnly
        />
      </CardActions>
      <CardContent className={styles.cardContent}>
        <Accordion
          className={styles.accordion}
          expanded={reviewExpanded === "panel1"}
          onChange={handleToggleReviewExpanded("panel1")}
        >
          <AccordionSummary
            className={styles.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid container alignItems="stretch" justify="space-between">
              <Typography variant="subtitle1">
                {truncate(review.headline, 33)}
              </Typography>
              <Typography variant="subtitle1">
                {reviewExpanded === "panel1" ? "" : "show"}
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>{review.headline}</AccordionDetails>
          <AccordionDetails>{review.review}</AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
