import { ProductColor } from "http/models";
import React from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    editIcon: {
      marginTop: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      margin: theme.spacing(1),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      padding: theme.spacing(1),
      width: theme.spacing(36),
      "&:hover": {
        cursor: "pointer",
        boxShadow: `1px 1px 8px ${theme.palette.grey[800]}`,
      },
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      marginTop: theme.spacing(2),
    },
    box: {
      height: "100px",
      width: "100px",
      "&:hover": {
        cursor: "pointer",
        height: "105px",
        width: "105px",
      },
    },
    paper: {
      margin: theme.spacing(2),
    },
  }),
  { index: 1 }
);

interface Props {
  onClick: () => void;
  color: ProductColor;
}

const ProductColorCard: React.FC<Props> = ({ color, onClick }) => {
  const styles = useStyles();
  return (
    <Paper elevation={4} className={styles.paper}>
      <Box
        onClick={onClick}
        component="div"
        className={styles.box}
        display={{ xs: "block" }}
        style={{ backgroundColor: `#${color.hex}`.replace("#", "") }}
      />
    </Paper>
  );
};

export default ProductColorCard;
