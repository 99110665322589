import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CreateProductVariationRequest, ProductVariation } from "http/models";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import SaveIcon from "@material-ui/icons/Save";
import React, { useContext, useEffect, useRef, useState } from "react";
import { notificationsContext } from "contexts/Notifications";
import {
  Grid,
  Button,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
  Paper,
  Switch,
  FormLabel,
  FormControl,
  colors,
} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import {
  setProductVariationAsPrimary,
  updateProductVariation,
} from "http/endpoints";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import { Autocomplete } from "@mui/material";
import ColorPickerModal from "./ColorPickerModal";
import ProductVariationImageCard from "./ProductVariationImageCard";
import DeleteProductVariationModal from "./DeleteProductVariationModal";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(0),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    errorIcon: {
      color: colors.red[600],
      marginRight: theme.spacing(4),
    },
    accordionSummary: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    accordion: {
      width: theme.spacing(90),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    saveBtn: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    dimension: {
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    editIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    sizesHeader: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    inputField: {
      width: "90%",
    },
    paper: {
      padding: theme.spacing(3),
    },
    grid: {
      padding: theme.spacing(1),
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      marginTop: theme.spacing(2),
    },
    item: {
      borderRadius: "4px",
      margin: "0 auto",
      padding: theme.spacing(1),
      width: "90%",
      textAlign: "center",
      boxShadow: `1px 1px 8px ${theme.palette.grey[800]}`,
    },
    header: {
      padding: theme.spacing(2),
    },
    editAction: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(-4),
    },
    deleteAction: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(0),
    },
    value: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(12),
      },
    },
    addIcon: {
      marginTop: theme.spacing(2),
    },
    addBtn: {
      marginTop: theme.spacing(2),
    },
    noneAdded: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(2),
    },
    removeIcon: {
      marginRight: theme.spacing(3),
      borderRadius: "4px",
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
    addHeader: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(0),
    },
    btnIcon: {
      marginLeft: theme.spacing(1),
    },
    checkBox: {
      marginTop: theme.spacing(1),
    },
    colorPickBtn: {
      marginTop: theme.spacing(1.5),
    },
    saveIcon: {
      marginLeft: theme.spacing(1),
    },
    listItem: {
      width: "100%",
    },
    uploadBtn: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    sizeOption: {
      width: theme.spacing(80),
      margin: theme.spacing(3),
    },
    chip: {
      marginTop: theme.spacing(1.5),
    },
    additionalAttributesHeader: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    switch: {
      margin: theme.spacing(2),
    },
    imagesHeader: {
      marginTop: theme.spacing(4),
    },
    setAsPrimaryBtn: {
      marginBottom: theme.spacing(2),
      color: "white",
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "white",
      },
    },
  }),
  { index: 1 }
);

interface Props {
  productVariation: ProductVariation;
  refresh: () => void;
}

const ProductVariationCard: React.FC<Props> = ({
  productVariation,
  refresh,
}) => {
  const styles = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [openEditVariationModal, setOpenEditVariationModal] =
    useState<boolean>(false);
  const [openDeleteVariationModal, setOpenDeleteVariationModal] =
    useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [openColorPickerModal, setShouldOpenColorPickerModal] =
    useState<boolean>(false);

  // name
  const [name, setName] = useState<string>("");
  const [hasNameError, setHasNameError] = useState<boolean>(false);
  // description
  const [description, setDescription] = useState<string>("");
  const [hasDescriptionError, setHasDescriptionError] =
    useState<boolean>(false);
  // price(should this be a number type?)
  const [price, setPrice] = useState<string>("");
  const [hasPriceError, setHasPriceError] = useState<boolean>(false);
  const [colorName, setColorName] = useState<string>("");
  const [hexCode, setHexCode] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
  const [size, setSize] = useState<string>("");
  const [sizes, setSizes] = useState<(string | string[])[]>([]);
  const [allowColorSelection, setAllowColorSelection] =
    useState<boolean>(false);
  const [allowSizeSelection, setAllowSizeSelection] = useState<boolean>(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState<FormData | null>(null);

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {
    setName(productVariation.name);
    setDescription(productVariation.description);
    setPrice(productVariation.price);
    if (productVariation.sizes) {
      setAllowSizeSelection(true);
      setSizes(productVariation.sizes);
    }
    if (productVariation.color) {
      setAllowColorSelection(true);
      setColorName(productVariation.color.name);
      setHexCode(productVariation.color.hex);
    }
  }, [productVariation]);

  const toggleDetailsAccordion =
    (panel: string) => (event: React.SyntheticEvent, expanded: boolean) => {
      setExpanded(expanded ? panel : false);
    };

  const setProductVariation = (hideAlert: boolean) => {
    setIsPending(true);
    const requestBody: CreateProductVariationRequest = {
      name,
      description,
      price,
    };
    if (allowColorSelection && colorName !== "" && hexCode !== "") {
      requestBody.color = {
        name: colorName,
        hex: `${hexCode}`,
      };
    } else {
      requestBody.color = null;
    }

    if (allowSizeSelection) {
      const productSizes = `${sizes}`.split(",");
      if (productSizes.length > 0) {
        if (productSizes.length === 1 && productSizes[0] === "") {
          requestBody.sizes = null;
        } else {
          requestBody.sizes = productSizes;
        }
      } else {
        requestBody.sizes = null;
      }
    } else {
      requestBody.sizes = null;
    }
    updateProductVariation(
      {
        product_id: productVariation.product_id,
        variation_id: productVariation.id,
      },
      requestBody
    ).then((resp) => {
      if (resp.error) {
        let setErrFunc: (b: boolean) => void;
        if (resp.error.error.detail) {
          if (resp.error.error.detail.includes("invalid price")) {
            setErrFunc = setHasPriceError;
          }
          if (
            resp.error.error.detail.includes("name unset") ||
            resp.error.error.detail.includes("name is already taken")
          ) {
            setErrFunc = setHasNameError;
          }
          if (resp.error.error.detail.includes("description unset")) {
            setErrFunc = setHasDescriptionError;
          }
        }
        setTimeout(() => {
          setIsPending(false);
          displayAPIErrorNotification(resp.error);
        }, 500);
      } else {
        refresh();
        setTimeout(() => {
          setIsPending(false);
          if (!hideAlert) {
            displayNotification(
              "successfully updated product variation",
              "success"
            );
          }
        }, 500);
      }
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProductVariation(false);
  };

  const handleAddImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductVariation(true);
    setIsPending(true);
    const data = new FormData();
    if (!e.target.files) {
      return;
    }
    const fileNames: string[] = [];
    const files: File[] = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      data.append(
        "product-variation-images",
        e.target.files[i],
        e.target.files[i].name
      );
      fileNames.push(e.target.files[i].name);
      files.push(e.target.files[i]);
    }
    setSelectedFileNames([...selectedFileNames, ...fileNames]);
    setSelectedFiles([...selectedFiles, ...files]);
    fetch(
      `/api/v1/products/${productVariation.product_id}/variations/${productVariation.id}/images`,
      {
        method: "POST",
        body: data,
      }
    )
      .then((resp) => {
        if (resp.status === 201) {
          setIsPending(false);
          displayNotification(
            "successfully uploaded product variation images",
            "success"
          );
          refresh();
        } else {
          resp.json().then((json) => {
            displayAPIErrorNotification(json);
            setTimeout(() => {
              setIsPending(false);
            }, 2000);
          });
        }
      })
      .catch((error) => {
        displayAPIErrorNotification(error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      });
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleSetAsPrimary = () => {
    setProductVariationAsPrimary({
      product_id: productVariation.product_id,
      variation_id: productVariation.id,
    }).then(() => {
      refresh();
      displayNotification("successfully updated default variation", "success");
    });
  };

  const truncate = (s: string, len: number) =>
    s.length > len ? `${s.substring(0, len)}...` : s;

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Accordion
          className={styles.accordion}
          expanded={expanded === "panel4"}
          onChange={toggleDetailsAccordion("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="large" />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            {productVariation.is_primary ? (
              <Grid item xs={2}>
                <Chip
                  color="info"
                  label="default"
                  className={styles.chip}
                  disabled
                />
              </Grid>
            ) : null}

            {productVariation.images ? null : (
              <Tooltip title="No images">
                <Grid item xs={2}>
                  <Chip
                    className={styles.chip}
                    disabled
                    color="error"
                    label="error"
                  />
                </Grid>
              </Tooltip>
            )}

            {!productVariation.is_primary &&
            productVariation.images &&
            productVariation.images.length > 0 ? (
              <Grid item xs={2} />
            ) : null}

            <Grid item xs={8}>
              <Typography variant="h5" className={styles.accordionSummary}>
                {" "}
                {truncate(productVariation.name, 40)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="delete product variation" placement="top">
                <IconButton
                  className={styles.deleteIcon}
                  onClick={() => setOpenDeleteVariationModal(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justify="center" alignItems="center">
              {productVariation.is_primary ? null : (
                <Grid container justify="center" alignItems="center">
                  <Button
                    className={styles.setAsPrimaryBtn}
                    type="button"
                    color="secondary"
                    variant="contained"
                    onClick={() => handleSetAsPrimary()}
                    disabled={
                      productVariation.is_primary ||
                      !productVariation.images ||
                      productVariation.images.length === 0
                    }
                  >
                    {productVariation.is_primary ? "default" : "Use as default"}
                  </Button>
                </Grid>
              )}

              <Grid className={styles.grid} item xs={12}>
                <Grid container justify="center" alignItems="center">
                  <Typography variant="h5">Basic Information</Typography>
                </Grid>
                <Grid container alignItems="center" justify="center">
                  <Typography variant="caption">(Required)</Typography>
                </Grid>
                <Grid container alignItems="center" justify="center">
                  <TextField
                    fullWidth
                    onChange={(e) => {
                      if (hasNameError) {
                        setHasNameError(false);
                      }
                      setName(e.target.value);
                    }}
                    className={styles.inputField}
                    name="name"
                    label="Name"
                    type="text"
                    value={name}
                    error={hasNameError}
                    required
                  />
                </Grid>
                <Grid container alignItems="center" justify="center">
                  <TextField
                    fullWidth
                    onChange={(e) => {
                      if (hasDescriptionError) {
                        setHasDescriptionError(false);
                      }
                      setDescription(e.target.value);
                    }}
                    className={styles.inputField}
                    name="description"
                    label="Description"
                    type="text"
                    value={description}
                    error={hasDescriptionError}
                    required
                  />
                </Grid>
                <Grid container alignItems="center" justify="center">
                  <TextField
                    fullWidth
                    onChange={(e) => {
                      if (hasPriceError) {
                        setHasPriceError(false);
                      }
                      setPrice(e.target.value.trim());
                    }}
                    className={styles.inputField}
                    name="price"
                    label="Price"
                    type="text"
                    value={price}
                    error={hasPriceError}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center">
                <Typography className={styles.imagesHeader} variant="h5">
                  Images
                </Typography>
              </Grid>
              <Grid container justify="center" alignItems="center">
                <Typography variant="caption">(Required)</Typography>
              </Grid>
              <Grid container alignItems="center" justify="center">
                {productVariation.images ? (
                  productVariation.images.map((img) => (
                    <ProductVariationImageCard
                      productId={productVariation.product_id}
                      variationImage={img}
                      refresh={refresh}
                    />
                  ))
                ) : (
                  <Typography className={styles.noneAdded} variant="h6">
                    No variation images added yet
                  </Typography>
                )}
              </Grid>
              <Grid container alignItems="center" justify="center">
                <Button
                  className={styles.uploadBtn}
                  color="secondary"
                  variant="contained"
                  onClick={handleClick}
                >
                  Upload <PermMediaIcon className={styles.btnIcon} />
                </Button>
                <input
                  name="avatar"
                  accept="image/*"
                  type="file"
                  onChange={handleAddImages}
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  multiple
                />
              </Grid>
              <Grid container alignItems="center" justify="center">
                <Typography
                  className={styles.additionalAttributesHeader}
                  variant="h5"
                >
                  Additional Attributes
                </Typography>
              </Grid>
              <Grid container alignItems="center" justify="center">
                <Typography variant="caption">(Optional)</Typography>
              </Grid>
              <Grid container alignItems="center" justify="center">
                <FormLabel component="legend">Sizes</FormLabel>
                <Tooltip title="recommended for products that come in various sizes">
                  <Switch
                    className={styles.switch}
                    value={allowSizeSelection}
                    onChange={() => setAllowSizeSelection(!allowSizeSelection)}
                  />
                </Tooltip>
              </Grid>
              {allowSizeSelection && (
                <>
                  <Grid container alignItems="center" justify="center">
                    <Typography variant="h5">Sizes</Typography>
                  </Grid>
                  <Grid
                    className={styles.sizesHeader}
                    container
                    alignItems="center"
                    justify="center"
                  >
                    <Typography variant="caption">(Optional)</Typography>
                  </Grid>
                  <FormControl>
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      value={sizes as string[]}
                      onChange={(_, newValue) => {
                        console.log(`change: ${newValue}`);
                        setSizes(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText="press enter to add sizes/dimensions"
                        />
                      )}
                    />
                  </FormControl>
                </>
              )}
              <Grid container alignItems="center" justify="center">
                <FormLabel component="legend">Color</FormLabel>
                <Tooltip title="recommended for products that come in various colors">
                  <Switch
                    className={styles.switch}
                    value={allowColorSelection}
                    onChange={() =>
                      setAllowColorSelection(!allowColorSelection)
                    }
                  />
                </Tooltip>
              </Grid>
              {allowColorSelection && (
                <Grid className={styles.grid} item xs={12}>
                  <Grid container alignItems="center" justify="center">
                    <Typography variant="h5">Color</Typography>
                  </Grid>
                  <Grid container alignItems="center" justify="center">
                    <Typography variant="caption">(Optional)</Typography>
                  </Grid>
                  <Grid container alignItems="center" justify="center">
                    <Button
                      color="secondary"
                      variant="contained"
                      className={styles.colorPickBtn}
                      onClick={() => setShouldOpenColorPickerModal(true)}
                    >
                      Select <ColorLensIcon />
                    </Button>
                  </Grid>
                  <Grid container alignItems="center" justify="center">
                    <TextField
                      fullWidth
                      className={styles.inputField}
                      name="hex"
                      label="Hex"
                      type="text"
                      value={hexCode}
                    />
                  </Grid>
                  <Grid container alignItems="center" justify="center">
                    <TextField
                      fullWidth
                      onChange={(e) => {
                        setColorName(e.target.value.trim());
                      }}
                      className={styles.inputField}
                      name="color name"
                      label="Color Name"
                      type="text"
                      value={colorName}
                      required
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container alignItems="center" justify="center">
              {isPending ? (
                <Button
                  className={styles.saveBtn}
                  color="default"
                  variant="contained"
                >
                  saving...
                </Button>
              ) : (
                <Button
                  className={styles.saveBtn}
                  type="submit"
                  color="secondary"
                  variant="contained"
                >
                  Save Changes <SaveIcon className={styles.saveIcon} />
                </Button>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        {openColorPickerModal && (
          <ColorPickerModal
            open={openColorPickerModal}
            setOpen={setShouldOpenColorPickerModal}
            setHexCode={setHexCode}
          />
        )}
        <DeleteProductVariationModal
          open={openDeleteVariationModal}
          setOpen={setOpenDeleteVariationModal}
          productVariation={productVariation}
          refresh={refresh}
          closeHandler={() => setOpenDeleteVariationModal(false)}
        />
      </form>
    </>
  );
};

export default ProductVariationCard;
