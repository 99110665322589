import { useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "pages/Home";
import LogIn from "pages/Auth/LogIn";
import SignUp from "pages/Auth/SignUp";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import VerifyMultiFactorAuth from "pages/Auth/VerifyMultiFactorAuth";
import VerifyEmail from "pages/Auth/VerifyEmail";
import CheckYourEmail from "pages/Auth/CheckYourEmail";
import NavBar from "components/NavBar/NavBar";
import LinkExpired from "pages/Auth/LinkExpired";
import PageNotFound from "pages/NotFound";
import Settings from "pages/Settings/Settings";
import Catalog from "pages/Catalog/Catalog";
import VerifyPhoneNumber from "pages/Settings/VerifyPhoneNumber";
import { userContext } from "contexts/User";
import CatalogProduct from "pages/Catalog/Product";
import Products from "pages/Products/Products";
import ProductDetail from "pages/Products/ProductDetail";
import Cart from "pages/Cart/Cart";
import OrderConfirmation from "pages/Cart/OrderConfirmation";
import Orders from "pages/Orders/Orders";
import OrderPage from "pages/Orders/OrderPage";
import AddProductReview from "pages/Reviews/AddReview";
import EditProductReview from "pages/Reviews/EditReview";
import AddProductReviewImages from "pages/Reviews/AddReviewImages";
import EditProductReviewImages from "pages/Reviews/EditReviewImages";
import Reviews from "pages/Reviews/Reviews";

const Router = () => {
  const { user, setUser } = useContext(userContext);

  useEffect(() => {
    setUser();
  }, []);

  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/log-in" component={LogIn} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route
          exact
          path="/settings/:tab?"
          component={user === null ? LogIn : Settings}
        />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/reset-password-session"
          component={CheckYourEmail}
        />
        <Route exact path="/reset-password/:id" component={ResetPassword} />
        <Route exact path="/verify-email" component={VerifyEmail} />
        <Route
          exact
          path="/verify-phone-number/:id"
          component={VerifyPhoneNumber}
        />
        <Route
          exact
          path="/verify-multi-factor-auth"
          component={VerifyMultiFactorAuth}
        />
        <Route exact path="/link-expired" component={LinkExpired} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/product/:id" component={ProductDetail} />
        <Route exact path="/product/:id/reviews" component={AddProductReview} />
        <Route
          exact
          path="/product/:product_id/reviews/:review_id/images"
          component={AddProductReviewImages}
        />
        <Route
          exact
          path="/product/:product_id/reviews/:review_id"
          component={EditProductReview}
        />
        <Route
          exact
          path="/product/:product_id/reviews/:review_id/images/edit"
          component={EditProductReviewImages}
        />
        <Route exact path="/catalog" component={Catalog} />
        <Route exact path="/catalog/product" component={CatalogProduct} />
        <Route exact path="/catalog/product/:id" component={CatalogProduct} />
        <Route
          exact
          path="/order-confirmation/:id"
          component={OrderConfirmation}
        />
        <Route exact path="/cart" component={Cart} />
        <Route
          exact
          path="/orders"
          component={user === null ? LogIn : Orders}
        />
        <Route
          exact
          path="/reviews"
          component={user == null ? LogIn : Reviews}
        />
        <Route exact path="/orders/:id" component={OrderPage} />
        <Route exact path="/not-found" component={PageNotFound} />
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
