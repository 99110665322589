import Modal from "@material-ui/core/Modal";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import React, { useState, useContext } from "react";
import SendIcon from "@material-ui/icons/Send";
import { createPhoneNumber } from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      position: "absolute",
      width: 400,
      height: 260,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 8, 3),
      transition: "all 3000ms ease;",
    },
    grid: {
      padding: theme.spacing(4),
    },
    gridModal: {
      marginTop: theme.spacing(2),
    },
    closeIcon: {
      marginBottom: theme.spacing(-3),
      marginLeft: "250px",
      marginTop: "-70px",
      color: theme.palette.grey[800],
      height: "40px",
      width: "40px",
      opacity: "0.5",
      "&:hover": {
        color: "red",
        boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
        cursor: "pointer",
        borderRadius: "8px",
      },
    },
    scoot: {
      marginTop: theme.spacing(-2),
    },
    inputField: {
      width: "60%",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      display: "block",
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    sendBtn: {
      marginTop: theme.spacing(0),
    },
    sendIcon: {
      marginLeft: theme.spacing(1),
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(3),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
    spacer5: {
      height: theme.spacing(5),
      width: "100%",
    },
    spacer6: {
      height: theme.spacing(6),
      width: "100%",
    },
    spacer7: {
      height: theme.spacing(7),
      width: "100%",
    },
    spacer8: {
      height: theme.spacing(8),
      width: "100%",
    },
    spacer9: {
      height: theme.spacing(9),
      width: "100%",
    },
    spacer10: {
      height: theme.spacing(10),
      width: "100%",
    },
  }),
  { index: 1 }
);

interface Props {
  open: boolean;
  setOpen: (shouldOpen: boolean) => void;
  newPhoneNumber: string;
  setNewPhoneNumberHandler: React.Dispatch<React.SetStateAction<string>>;
  initiateVerifyPhoneNumber: (id: string) => void;
  closeHandler: (show: boolean) => void;
}

const AddPhoneNumberModal: React.FC<Props> = ({
  open,
  setOpen,
  newPhoneNumber,
  setNewPhoneNumberHandler,
  initiateVerifyPhoneNumber,
  closeHandler,
}) => {
  const styles = useStyles();
  const [isPending, setIsPending] = useState<boolean>(false);

  const { displayAPIErrorNotification, displayCustomAPIErrorNotification } =
    useContext(notificationsContext);

  const modalStyle = (): React.CSSProperties => ({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    textAlign: "center",
    borderRadius: "8px",
    padding: "45px",
  });

  const handleAdd = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);

    createPhoneNumber(null, {
      country_code: "+1",
      phone_number: newPhoneNumber,
      type: "mobile",
    }).then((resp) => {
      if (resp.error) {
        if (resp.error.error.detail) {
          if (
            resp.error.error.detail.includes("strconv") ||
            resp.error.error.detail.includes("failed to decode")
          ) {
            displayCustomAPIErrorNotification("badly formatted phone number");
            setTimeout(() => {
              setIsPending(false);
            }, 2000);
            return;
          }
        }
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      } else {
        setTimeout(() => {
          initiateVerifyPhoneNumber(resp.body.id);
        }, 500);
        setTimeout(() => {
          setIsPending(false);
        }, 1500);
      }
    });
  };

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.paper} style={modalStyle()}>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setOpen(false)}
          />
          <Grid
            className={styles.gridModal}
            container
            alignItems="center"
            justify="center"
          />
          <Typography variant="h4">Add New Number</Typography>
        </Grid>
        <form onSubmit={handleAdd}>
          <Grid
            className={styles.scoot}
            container
            alignItems="center"
            justify="center"
          >
            <TextField
              onChange={(e) => setNewPhoneNumberHandler(e.target.value.trim())}
              className={styles.inputField}
              helperText="no dashes or spaces"
              placeholder="1112223344"
              name="new_number"
              label="Phone Number"
              type="tel"
              value={newPhoneNumber}
              fullWidth
              required
            />
          </Grid>
          <div className={styles.spacer2} />
          {isPending ? (
            <Button
              className={styles.btn}
              color="default"
              variant="contained"
              disabled
            >
              sending...
            </Button>
          ) : (
            <Button
              className={styles.sendBtn}
              type="submit"
              color="secondary"
              variant="contained"
            >
              Send Code <SendIcon className={styles.sendIcon} />
            </Button>
          )}
        </form>
      </div>
    </Modal>
  );
};

export default AddPhoneNumberModal;
