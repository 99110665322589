import Cookies from "js-cookie";
import { Redirect } from "react-router-dom";
import React, { useContext } from "react";
import { notificationsContext } from "contexts/Notifications";
import Grid from "@material-ui/core/Grid";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import HandymanIcon from "@mui/icons-material/Handyman";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BuildIcon from "@mui/icons-material/Build";
import FadeIn from "components/FadeIn";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
      flexGrow: 1,
    },
    hero: {
      marginTop: theme.spacing(10),
      height: "auto",
      backgroundColor: theme.palette.primary.main,
    },
    heroLeft: {
      paddingTop: theme.spacing(6),
      textAlign: "center",
      color: "#000000",
    },
    heroRight: {
      backgroundImage: "url(carpentry.jpeg)",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      [theme.breakpoints.up("sm")]: {
        height: theme.spacing(50),
      },
      [theme.breakpoints.up("xs")]: {
        height: theme.spacing(50),
      },
    },
    heroImg: {
      height: theme.spacing(29),
      width: theme.spacing(29),
    },
    heroSubTitle: {
      marginBottom: theme.spacing(4.75),
    },
    heroImgLeft: {
      height: theme.spacing(25),
      width: theme.spacing(30),
    },
    paper: {
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    button: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    features: {
      paddingTop: theme.spacing(6),
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.light,
    },
    featureHeading: {
      margin: theme.spacing(6),
    },
    featureIcon: {
      margin: theme.spacing(5),
      fontSize: theme.spacing(30),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(2),
      },
    },
    link: {
      color: "#000000",
      textDecoration: "none",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(5),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(3),
      },
    },
  }),
  { index: 1 }
);

const Home: React.FC = () => {
  const styles = useStyles();
  const redirectURL = Cookies.get("redirect");
  const error = Cookies.get("error");
  const { displayAPIErrorNotification } = useContext(notificationsContext);

  const features = [
    {
      title: "Hand Craft",
      icon: <HandymanIcon fontSize="large" className={styles.featureIcon} />,
      subTitle: "Made to order desks and cabinets.",
    },
    {
      title: "Deliver",
      icon: (
        <LocalShippingIcon fontSize="large" className={styles.featureIcon} />
      ),
      subTitle: "We ship anywhere within the state of GA.",
    },
    {
      title: "Installation & Assembly",
      icon: <BuildIcon fontSize="large" className={styles.featureIcon} />,
      subTitle: "So you dont have to.",
    },
  ];

  if (error) {
    const jsonErr = JSON.parse(error);
    displayAPIErrorNotification(jsonErr);
    Cookies.remove("error");
  }

  if (redirectURL) {
    Cookies.remove("redirect");
    return <Redirect to={redirectURL} />;
  }

  return (
    <FadeIn>
      <Grid container direction="row" className={styles.hero}>
        <Grid item xs={12} md={6} className={styles.heroLeft}>
          <Grid item xs={12}>
            <Typography variant="h2">Bildit</Typography>
          </Grid>
          <Grid item xs={12}>
            <img alt="broken link" src="bildit_logo_2.png" />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={styles.heroRight} />
      </Grid>
      <Grid container direction="row" className={styles.features}>
        <Grid item xs={12} className={styles.featureHeading}>
          <Typography variant="h2">What We Do</Typography>
        </Grid>
        {features.map((feature) => (
          <Grid item xs={12} md={4} lg={3} className={styles.featureHeading}>
            <Paper elevation={3} className={styles.card}>
              <Typography variant="h4">{feature.title}</Typography>
              <Grid item xs={12} />
              {feature.icon}
              <Grid item xs={12} />
              <Typography variant="h5">{feature.subTitle}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </FadeIn>
  );
};

export default Home;
