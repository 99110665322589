import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { userContext } from "contexts/User";
import { Grid, Container, Typography } from "@material-ui/core";
import FadeIn from "components/FadeIn";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(18),
    },
    subHeader: {
      marginTop: theme.spacing(5),
    },
  }),
  { index: 1 }
);

const CheckYourEmail: React.FC = () => {
  const styles = useStyles();

  const { user } = useContext(userContext);
  if (user !== null) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.root}>
      <Container>
        <Grid container alignItems="center" justify="center">
          <Typography variant="h4">Reset Link Sent</Typography>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <FadeIn>
            <Grid item>
              <Typography variant="subtitle2" className={styles.subHeader}>
                Check your email for the password reset lnk.
              </Typography>
            </Grid>
          </FadeIn>
        </Grid>
      </Container>
    </div>
  );
};

export default CheckYourEmail;
