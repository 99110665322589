import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { initializeVerifyPhoneNumber } from "http/endpoints";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { notificationsContext } from "contexts/Notifications";
import FadeIn from "components/FadeIn";
import AddIcon from "@material-ui/icons/Add";
import { PhoneNumber } from "http/models";
import { phoneNumbersContext } from "contexts/PhoneNumbers";
import AddPhoneNumberModal from "pages/Settings/AddPhoneNumberModal";
import PhoneNumberCard from "pages/Settings/PhoneNumberCard";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(17),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(1),
    },
    phoneNumbers: {
      marginTop: theme.spacing(-2),
    },
    subGrid: {
      padding: theme.spacing(5),
    },
    btn: {
      marginTop: theme.spacing(-4),
    },
    scoot: {
      marginTop: theme.spacing(5),
    },
    addIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

const PhoneNumberDetail: React.FC = () => {
  const history = useHistory();
  const [show, setShow] = useState<boolean>(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>("");
  const { phoneNumbers, fetchPhoneNumbers } = useContext(phoneNumbersContext);
  const styles = useStyles();

  const {
    displayNotification,
    displayAPIErrorNotification,
    displayCustomAPIErrorNotification,
  } = useContext(notificationsContext);

  useEffect(() => {
    fetchPhoneNumbers();
  }, []);

  const handleInitiateVerifyPhoneNumber = (id: string) => {
    initializeVerifyPhoneNumber({ id }).then((resp) => {
      if (resp.error) {
        if (resp.error.error.detail) {
          if (resp.error.error.detail.includes("not a valid phone number")) {
            displayCustomAPIErrorNotification(
              "failed to send verification code"
            );
            fetchPhoneNumbers();
            setShow(false);
            return;
          }
        }
        displayAPIErrorNotification(resp.error);
      } else {
        history.push(`/verify-phone-number/${id}`);
        displayNotification(`successfully texted you the code`, "success");
      }
    });
  };

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Typography variant="h3">Phone</Typography>
        </Grid>
        <Grid
          className={styles.subGrid}
          container
          alignItems="center"
          justify="center"
        >
          <FadeIn>
            <Button
              className={styles.btn}
              onClick={() => setShow(true)}
              type="submit"
              color="secondary"
              variant="contained"
            >
              New <AddIcon className={styles.addIcon} />
            </Button>
          </FadeIn>
          <AddPhoneNumberModal
            open={show}
            setOpen={setShow}
            newPhoneNumber={newPhoneNumber}
            setNewPhoneNumberHandler={setNewPhoneNumber}
            initiateVerifyPhoneNumber={handleInitiateVerifyPhoneNumber}
            closeHandler={() => setShow(false)}
          />
        </Grid>
        <Grid
          className={styles.phoneNumbers}
          container
          alignItems="center"
          justify="center"
        >
          {phoneNumbers ? (
            phoneNumbers.map((phoneNumber: PhoneNumber) => (
              <FadeIn>
                <PhoneNumberCard
                  key={phoneNumber.id}
                  phoneNumber={phoneNumber}
                  initiateVerifyPhoneNumberHandler={
                    handleInitiateVerifyPhoneNumber
                  }
                />
              </FadeIn>
            ))
          ) : (
            <Typography color="textSecondary">no numbers added yet</Typography>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default PhoneNumberDetail;
