import React, { useContext, useState } from "react";
import { updatePassword } from "http/endpoints";
import { userContext } from "contexts/User";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { notificationsContext } from "contexts/Notifications";
import FadeIn from "components/FadeIn";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    inputField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: "block",
    },
    root: {
      marginTop: theme.spacing(14),
      flexGrow: 1,
    },
    label: {
      marginTop: theme.spacing(3),
    },
    grid: {
      padding: theme.spacing(2),
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    scoot: {
      marginTop: theme.spacing(-2),
    },
    saveIcon: {
      marginLeft: theme.spacing(1),
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(3),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
    spacer5: {
      height: theme.spacing(5),
      width: "100%",
    },
    spacer6: {
      height: theme.spacing(6),
      width: "100%",
    },
    spacer7: {
      height: theme.spacing(7),
      width: "100%",
    },
    spacer8: {
      height: theme.spacing(8),
      width: "100%",
    },
    spacer9: {
      height: theme.spacing(9),
      width: "100%",
    },
    spacer10: {
      height: theme.spacing(10),
      width: "100%",
    },
  }),
  { index: 1 }
);

const Password: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isPending, setIsPending] = useState<boolean>(false);
  const { setUser } = useContext(userContext);
  const styles = useStyles();
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);
  const [currentPasswordHasError, setCurrentPasswordHasError] =
    useState<boolean>(false);
  const [newPasswordHasError, setNewPasswordHasError] =
    useState<boolean>(false);
  const [confirmedPasswordHasError, setConfirmedPasswordHasError] =
    useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    setCurrentPasswordHasError(false);
    setNewPasswordHasError(false);
    setConfirmedPasswordHasError(false);

    updatePassword(null, {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    }).then((resp) => {
      if (resp.error) {
        if (resp.error.error.detail) {
          if (
            resp.error.error.detail.includes("current password is incorrect") ||
            resp.error.error.detail.includes("current password is invalid")
          ) {
            setCurrentPasswordHasError(true);
          } else if (
            resp.error.error.detail.includes("new password is invalid")
          ) {
            setNewPasswordHasError(true);
          } else if (resp.error.error.detail.includes("passwords dont match")) {
            setConfirmedPasswordHasError(true);
          }
        }
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setUser();
          setNewPassword("");
          setCurrentPassword("");
          setConfirmPassword("");
          displayNotification("successfully updated password", "success");
          setIsPending(false);
        }, 500);
      }
    });
  };

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <form onSubmit={handleSubmit} noValidate>
            <Grid
              className={styles.grid}
              container
              alignItems="center"
              justify="center"
            >
              <Typography variant="h3">Password</Typography>
            </Grid>
            <FadeIn>
              <Grid item xs={12} className={styles.scoot}>
                <TextField
                  onChange={(e) => {
                    if (e.target.value.trim().length === 8) {
                      setCurrentPasswordHasError(false);
                    }
                    setCurrentPassword(e.target.value.trim());
                  }}
                  className={styles.inputField}
                  label="Current Password"
                  type="password"
                  value={currentPassword}
                  error={currentPasswordHasError}
                  fullWidth
                  required
                />
              </Grid>
              <div className={styles.spacer2} />
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setNewPassword(e.target.value.trim())}
                  className={styles.inputField}
                  label="New Password"
                  name="newpassword"
                  type="password"
                  value={newPassword}
                  error={newPasswordHasError}
                  fullWidth
                  required
                />
              </Grid>
              <div className={styles.spacer2} />
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setConfirmPassword(e.target.value.trim())}
                  className={styles.inputField}
                  label="Confirm New Password"
                  type="password"
                  value={confirmPassword}
                  error={confirmedPasswordHasError}
                  fullWidth
                  required
                />
              </Grid>
              <Grid
                className={styles.grid}
                direction="column"
                container
                alignItems="center"
                justify="center"
              >
                <div className={styles.spacer2} />
                <Grid item xs={12}>
                  {isPending ? (
                    <Button
                      className={styles.btn}
                      color="default"
                      disabled
                      variant="contained"
                    >
                      saving...
                    </Button>
                  ) : (
                    <Button
                      className={styles.btn}
                      type="submit"
                      color="secondary"
                      variant="contained"
                    >
                      save <SaveIcon className={styles.saveIcon} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </FadeIn>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default Password;
