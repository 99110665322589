import React, { useState, useContext, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOutlined from "@material-ui/icons/LockOutlined";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { useParams, useHistory } from "react-router-dom";
import { userContext } from "contexts/User";
import PhoneNumber from "pages/Settings/PhoneNumbers";
import UserAvatar from "pages/Settings/UserAvatar";
import TabPanel from "pages/Settings/TabPanel";
import Password from "pages/Settings/Password";
import Email from "pages/Settings/Email";
import Addresses from "pages/Settings/Address";
import { makeStyles } from "@material-ui/core";
import HomeIcon from "@mui/icons-material/Home";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
    },
    tabs: {
      width: "100%",
      marginTop: theme.spacing(-2),
      paddingTop: theme.spacing(10),
      height: "50px",
      backgroundColor: theme.palette.primary.dark,
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(3),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
    spacer5: {
      height: theme.spacing(5),
      width: "100%",
    },
    spacer6: {
      height: theme.spacing(6),
      width: "100%",
    },
    spacer7: {
      height: theme.spacing(7),
      width: "100%",
    },
    spacer8: {
      height: theme.spacing(8),
      width: "100%",
    },
    spacer9: {
      height: theme.spacing(9),
      width: "100%",
    },
    spacer10: {
      height: theme.spacing(10),
      width: "100%",
    },
    tabIcon: {
      marginTop: theme.spacing(1),
    },
    indicator: {
      backgroundColor: theme.palette.grey[50],
    },
  }),
  { index: 1 }
);

const tabNameToIndex = (name: string): number => {
  switch (name) {
    case "email":
      return 0;
    case "password":
      return 1;
    case "phone":
      return 2;
    case "avatar":
      return 3;
    case "address":
      return 4;
    default:
      return 0;
  }
};

const indexToTabName = (index: number): string => {
  switch (index) {
    case 0:
      return "email";
    case 1:
      return "password";
    case 2:
      return "phone";
    case 3:
      return "avatar";
    case 4:
      return "address";
    default:
      return "email";
  }
};

const Settings: React.FC = () => {
  const { setUser } = useContext(userContext);
  const styles = useStyles();
  const { tab } = useParams<{ tab: string }>();
  const [selectedTab, setSelectedTab] = useState<number>(tabNameToIndex(tab));
  const history = useHistory();

  useEffect(() => {
    if (!tab) {
      window.history.replaceState(null, "Settings", `/settings/email`);
    }
    setUser();
    setSelectedTab(tabNameToIndex(tab));
  }, [tab]);

  const tabProps = (index: number) => ({
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  });

  const handleChange = (e: any, newValue: any) => {
    e.preventDefault();
    history.push(`/settings/${indexToTabName(newValue)}`);
    setSelectedTab(newValue);
  };

  return (
    <div className={styles.root}>
      <AppBar>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          className={styles.tabs}
          classes={{ indicator: styles.indicator }}
          centered
        >
          <Tab
            className={styles.tabIcon}
            icon={<MailOutlineIcon />}
            aria-label="email"
            {...tabProps(0)}
          />
          <Tab
            className={styles.tabIcon}
            icon={<LockOutlined />}
            aria-label="password"
            {...tabProps(1)}
          />
          <Tab
            className={styles.tabIcon}
            icon={<PhoneIphoneIcon />}
            aria-label="phone number"
            {...tabProps(2)}
          />
          <Tab
            className={styles.tabIcon}
            icon={<AccountBoxIcon />}
            aria-label="avatar"
            {...tabProps(3)}
          />
          <Tab
            className={styles.tabIcon}
            icon={<HomeIcon />}
            aria-label="address"
            {...tabProps(4)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <Email />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <Password />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <PhoneNumber />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <UserAvatar />
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        <Addresses />
      </TabPanel>
    </div>
  );
};

export default Settings;
