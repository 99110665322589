import {
  Button,
  Grid,
  makeStyles,
  Typography,
  Tooltip,
  TextField,
} from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  colors,
} from "@mui/material";
import FadeIn from "components/FadeIn";
import React, { useContext, useEffect, useState } from "react";
import { productsContext } from "contexts/Products";
import ProductCard from "pages/Products/ProductCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { cartContext } from "contexts/Cart";
import { userContext } from "contexts/User";
import { notificationsContext } from "contexts/Notifications";
import { Redirect, useHistory } from "react-router-dom";
import {
  clearCart,
  createOrder,
  createPaymentIntent,
  calculateShipping,
} from "http/endpoints";
import { Address, AddressType, AddressTypes } from "http/models";
import AddressInput from "components/AddressInput";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PaymentIcon from "@mui/icons-material/Payment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import styled from "@emotion/styled";
import { blueGrey, grey } from "@material-ui/core/colors";
import {
  StripeCardElementChangeEvent,
  StripeElementStyle,
} from "@stripe/stripe-js";
import { Reddit } from "@material-ui/icons";

const CardElementContainer = styled.div`
  height: 40px;
  width: 400px;
  margin: auto;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  & .StripeElement {
    width: 100%;
    height: 100%;
    padding: 15px;
  }
`;

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      padding: theme.spacing(2),
    },
    checkCircle: {
      color: colors.green[500],
      marginLeft: theme.spacing(2),
    },
    warning: {
      color: colors.yellow[700],
      marginLeft: theme.spacing(2),
    },
    inputField: {
      width: "400px",
      maxWidth: "400px",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      display: "block",
      marginLeft: theme.spacing(3),
    },
    cardGrid: {
      marginLeft: theme.spacing(6),
      marginTop: theme.spacing(-10),
    },
    paymentGrid: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(10),
    },
    accordion: {
      margin: "auto",
      width: theme.spacing(60),
    },
    accordionSummary: {
      margin: "auto",
    },
    header: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    subGrid: {
      padding: theme.spacing(3),
    },
    payIcon: {
      marginLeft: theme.spacing(2),
    },
    center: {
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  }),
  { index: 1 }
);

interface CheckoutProps {
  salesTax: number;
  preTaxTotal: number;
  total: number;
}

const Checkout: React.FC<CheckoutProps> = ({
  salesTax,
  preTaxTotal,
  total,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useContext(userContext);
  const history = useHistory();
  const { products, fetchProducts } = useContext(productsContext);
  const { cartItems, fetchCartItems } = useContext(cartContext);
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);
  const styles = useStyles();

  // shipping
  const [shippingCompanyName, setShippingCompanyName] = useState<string>("");
  const [shippingStreet, setShippingStreet] = useState<string>("");
  const [shippingStreet2, setShippingStreet2] = useState<string>("");
  const [shippingCity, setShippingCity] = useState<string>("");
  const [shippingState, setShippingState] = useState<string>("");
  const [shippingCountry, setShippingCountry] = useState<string>("");
  const [shippingZipCode, setShippingZipCode] = useState<string>("");
  const [shippingType, setShippingType] = useState<AddressType>(
    AddressType.Shipping
  );
  const [shippingCost, setShippingCost] = useState<number>(0);

  const [existingShippingAddress, setExistingShippingAddress] =
    useState<Address | null>(null);

  useEffect(() => {
    if (existingShippingAddress) {
      calcShipping(existingShippingAddress);
    }
  }, [existingShippingAddress]);

  const [shippingExpanded, setShippingExpanded] = React.useState<
    string | false
  >(false);
  const handleToggleShippingExpanded =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setShippingExpanded(isExpanded ? panel : false);
    };

  // billing
  const [billingCompanyName, setBillingCompanyName] = useState<string>("");
  const [billingStreet, setBillingStreet] = useState<string>("");
  const [billingStreet2, setBillingStreet2] = useState<string>("");
  const [billingCity, setBillingCity] = useState<string>("");
  const [billingState, setBillingState] = useState<string>("");
  const [billingCountry, setBillingCountry] = useState<string>("");
  const [billingZipCode, setBillingZipCode] = useState<string>("");
  const [billingType, setBillingType] = useState<AddressType>(
    AddressType.Billing
  );
  const [existingBillingAddress, setExistingBillingAddress] =
    useState<Address | null>(null);

  const [billingExpanded, setBillingExpanded] = React.useState<string | false>(
    false
  );
  const handleToggleBillingExpanded =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setBillingExpanded(isExpanded ? panel : false);
    };

  // card
  const [cardName, setCardName] = React.useState<string | null>(null);
  const [cardComplete, setCardComplete] = React.useState<boolean>(false);
  const [cardExpanded, setCardExpanded] = React.useState<string | false>(false);
  const handleToggleCardExpanded =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setCardExpanded(isExpanded ? panel : false);
    };

  const [isPending, setIsPending] = React.useState<boolean>(false);

  const iframeStyles: StripeElementStyle = {
    base: {
      color: colors.grey[500],
      fontSize: "14px",
      backgroundColor: "white",
      iconColor: "white",
      "::placeholder": {
        color: colors.grey[500],
      },
    },
    invalid: {
      iconColor: "#C70039",
      color: "#C70039",
    },
    complete: {
      iconColor: "white",
    },
  };

  const cardElementOpts = {
    style: iframeStyles,
    hidePostalCode: true,
  };

  const handleChangeCardElement = (e: StripeCardElementChangeEvent) => {
    if (e.complete) {
      setCardExpanded(false);
    }
    setCardComplete(e.complete);
  };

  const toPrice = (priceString: string): number => {
    let price: number;
    if (priceString.includes(".")) {
      const f = parseFloat(priceString);
      price = Number(f.toFixed(2));
    } else {
      price = parseInt(priceString, 10);
    }
    return price;
  };

  const handleClearCart = () => {
    clearCart(null, null).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
      } else {
        fetchCartItems();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    });
  };

  const [redirectURL, setRedirectURL] = useState<string>("");
  const handleSubmit = async (e: any) => {
    setIsPending(true);

    if (!stripe || !elements) {
      setIsPending(false);
      return;
    }

    if (!existingShippingAddress) {
      setIsPending(false);
      return;
    }

    if (!existingBillingAddress) {
      setIsPending(false);
      return;
    }

    const resp = await createPaymentIntent(null, { amount: total.toFixed(2) });
    if (resp.error) {
      displayAPIErrorNotification(resp.error);
      setIsPending(false);
      return;
    }

    const clientSecret = resp.body.client_secret;
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      displayNotification("failed to get card element", "error");
      setIsPending(false);
      return;
    }

    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardName as string,
            address: {
              city: existingBillingAddress?.city,
              country: "US",
              line1: existingBillingAddress?.street[0],
              line2: existingBillingAddress?.street[1],
              postal_code: existingBillingAddress?.zip_code,
              state: existingBillingAddress?.state,
            },
          },
        },
      });

    if (stripeError && stripeError.message) {
      displayNotification(stripeError.message, "error");
      setIsPending(false);
      return;
    }

    if (!paymentIntent) {
      displayNotification("payment intent is null", "error");
      setIsPending(false);
      return;
    }

    createOrder(null, {
      payment_id: paymentIntent.id,
      shipping_address: existingShippingAddress,
      billing_address: existingBillingAddress,
      items: cartItems,
    }).then((createOrderResp) => {
      setIsPending(false);
      if (createOrderResp.error) {
        displayAPIErrorNotification(createOrderResp.error);
        return;
      }
      handleClearCart();
      setRedirectURL(`/order-confirmation/${createOrderResp.body.id}`);
      displayNotification("payment successful", "success");
    });
  };

  const calcShipping = (destination: Address) => {
    calculateShipping(null, destination).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        return;
      }
      setShippingCost(resp.body.miles * 2);
    });
  };

  if (redirectURL !== "") {
    return <Redirect to={redirectURL} />;
  }

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Accordion
            className={styles.accordion}
            expanded={shippingExpanded === "panel1"}
            onChange={handleToggleShippingExpanded("panel1")}
          >
            <AccordionSummary
              className={styles.accordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="h5">
                1. Shipping Information
                {existingShippingAddress ? (
                  <Tooltip title="good to go">
                    <CheckCircleIcon className={styles.checkCircle} />
                  </Tooltip>
                ) : (
                  <Tooltip title="needs attention">
                    <ErrorIcon className={styles.warning} />
                  </Tooltip>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddressInput
                addrType={shippingType}
                setAddressType={setShippingType}
                allowedToSelectExisting
                allowedToSetAddrType={false}
                companyName={shippingCompanyName}
                setCompanyName={setShippingCompanyName}
                street={shippingStreet}
                setStreet={setShippingStreet}
                street2={shippingStreet2}
                setStreet2={setShippingStreet2}
                city={shippingCity}
                setCity={setShippingCity}
                state={shippingState}
                setState={setShippingState}
                country={shippingCountry}
                setCountry={setShippingCountry}
                zipCode={shippingZipCode}
                setZipCode={setShippingZipCode}
                existingAddress={existingShippingAddress}
                setExistingAddress={setExistingShippingAddress}
                panel="panel-1"
                expanded={shippingExpanded === "panel1"}
                setExpanded={handleToggleShippingExpanded}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Accordion
            className={styles.accordion}
            expanded={billingExpanded === "panel2"}
            onChange={handleToggleBillingExpanded("panel2")}
          >
            <AccordionSummary
              className={styles.accordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography variant="h5">
                2. Billing Information
                {existingBillingAddress ? (
                  <Tooltip title="good to go">
                    <CheckCircleIcon className={styles.checkCircle} />
                  </Tooltip>
                ) : (
                  <Tooltip title="needs attention">
                    <ErrorIcon className={styles.warning} />
                  </Tooltip>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddressInput
                addrType={billingType}
                setAddressType={setBillingType}
                allowedToSelectExisting
                allowedToSetAddrType={false}
                companyName={billingCompanyName}
                setCompanyName={setBillingCompanyName}
                street={billingStreet}
                setStreet={setBillingStreet}
                street2={billingStreet2}
                setStreet2={setBillingStreet2}
                city={billingCity}
                setCity={setBillingCity}
                state={billingState}
                setState={setBillingState}
                country={billingCountry}
                setCountry={setBillingCountry}
                zipCode={billingZipCode}
                setZipCode={setBillingZipCode}
                existingAddress={existingBillingAddress}
                setExistingAddress={setExistingBillingAddress}
                panel="panel2"
                expanded={billingExpanded === "panel2"}
                setExpanded={handleToggleBillingExpanded}
                addressToCopy={existingShippingAddress}
                setSameAs={
                  existingShippingAddress
                    ? setExistingShippingAddress
                    : undefined
                }
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Accordion
            className={styles.accordion}
            expanded={cardExpanded === "panel3"}
            onChange={handleToggleCardExpanded("panel3")}
          >
            <AccordionSummary
              className={styles.accordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography variant="h5">
                3. Card
                {cardName !== null && cardName !== "" && cardComplete ? (
                  <Tooltip title="good to go">
                    <CheckCircleIcon className={styles.checkCircle} />
                  </Tooltip>
                ) : (
                  <Tooltip title="needs attention">
                    <ErrorIcon className={styles.warning} />
                  </Tooltip>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                onChange={(e) => setCardName(e.target.value)}
                className={styles.inputField}
                placeholder="first last"
                name="card_name"
                label="Name as it appears on card"
                value={cardName}
                fullWidth
              />
              <CardElementContainer>
                <CardElement
                  id="card"
                  onChange={(e) => handleChangeCardElement(e)}
                  options={cardElementOpts}
                />
              </CardElementContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Typography className={styles.header} variant="h3">
            Check Out
          </Typography>
        </Grid>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Typography>Cart: ${preTaxTotal.toFixed(2)}</Typography>
        </Grid>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Typography>GA Sales Tax(4%): ${salesTax.toFixed(2)}</Typography>
        </Grid>
        {existingShippingAddress ? (
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Typography>Shipping: ${shippingCost.toFixed(2)}</Typography>
          </Grid>
        ) : null}
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Typography>Total: ${(total + shippingCost).toFixed(2)}</Typography>
        </Grid>
        <Grid
          className={styles.paymentGrid}
          container
          alignItems="center"
          justify="center"
        >
          <Button
            onClick={(e) => handleSubmit(e)}
            color="secondary"
            variant="contained"
            disabled={
              isPending ||
              !existingBillingAddress ||
              !existingShippingAddress ||
              !cardName ||
              cardName === "" ||
              !cardComplete ||
              cartItems.length === 0
            }
          >
            {isPending ? "sending payment..." : "Pay"}
            <PaymentIcon className={styles.payIcon} />
          </Button>
        </Grid>
      </Grid>
    </FadeIn>
  );
};

export default Checkout;
