import { useHistory, useParams } from "react-router-dom";
import { verifyPhoneNumber } from "http/endpoints";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React, { useContext, useState } from "react";
import { notificationsContext } from "contexts/Notifications";
import FadeIn from "components/FadeIn";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    inputField: {
      marginTop: theme.spacing(-3),
      marginBottom: theme.spacing(1),
      display: "block",
    },
    root: {
      marginTop: theme.spacing(10),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(5),
    },
    verifyIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

const VerifyPhoneNumber: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [code, setCode] = useState<string>("");
  const [isPending, setIsPending] = useState<boolean>(false);
  const styles = useStyles();

  const {
    displayNotification,
    displayAPIErrorNotification,
    displayCustomAPIErrorNotification,
  } = useContext(notificationsContext);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    verifyPhoneNumber({ id, code }).then((resp) => {
      if (resp.error) {
        if (resp.error.error.detail) {
          if (
            resp.error.error.detail.includes("strconv") ||
            resp.error.error.detail.includes("failed to decode") ||
            resp.error.error.detail.includes("not found")
          ) {
            displayCustomAPIErrorNotification("bad verification code");
            setTimeout(() => {
              setIsPending(false);
            }, 2000);
            return;
          }
        }
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      } else {
        setTimeout(() => {
          history.push("/settings/phone");
          displayNotification("successfully verified phone number", "success");
        }, 1000);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      }
    });
  };

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <form onSubmit={(e) => handleSubmit(e)}>
            <Grid
              className={styles.grid}
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item>
                <Typography variant="h3">Verify</Typography>
              </Grid>
            </Grid>
            <FadeIn>
              <Grid item>
                <TextField
                  onChange={(e) => setCode(e.target.value.trim())}
                  className={styles.inputField}
                  fullWidth
                  label="Verification-Code"
                  type="text"
                  required
                >
                  {code}
                </TextField>
              </Grid>
              <Grid
                className={styles.grid}
                container
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  {isPending ? (
                    <Button color="default" variant="contained" disabled>
                      Verifying...
                    </Button>
                  ) : (
                    <Button type="submit" color="secondary" variant="contained">
                      <Typography>Verify</Typography>
                      <AssignmentTurnedInIcon className={styles.verifyIcon} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </FadeIn>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default VerifyPhoneNumber;
