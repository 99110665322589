import { useParams, useHistory, Redirect } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { getPasswordReset, resetPassword } from "http/endpoints";
import { userContext } from "contexts/User";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { notificationsContext } from "contexts/Notifications";
import FadeIn from "components/FadeIn";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(16),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(3),
    },
    inputField: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    btn: {
      marginTop: theme.spacing(4),
    },
    resetIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

const ResetPassword: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [newPassword, setNewPassword] = useState<string>("");
  const [isPending, setIsPending] = useState<boolean>(false);
  const { user, setUser } = useContext(userContext);
  const styles = useStyles();
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {
    getPasswordReset({ id }).then((resp) => {
      if (resp.status.code !== 200) {
        history.push("/link-expired");
      }
    });
  }, [history, id]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    resetPassword(null, { id, new_password: newPassword }).then((resp) => {
      switch (resp.status.code) {
        case 200:
          setUser();
          history.push("/");
          displayNotification("successfully reset password", "success");
          setTimeout(() => {
            setIsPending(false);
          }, 1000);
          break;
        case 201:
          history.push("/verify-multi-factor-auth");
          displayNotification(
            "successfully texted you the login code",
            "success"
          );
          setTimeout(() => {
            setIsPending(false);
          }, 1000);
          break;
        default:
          if (resp.error) {
            displayAPIErrorNotification(resp.error);
            setTimeout(() => {
              setIsPending(false);
            }, 500);
          }
      }
    });
  };

  if (user !== null) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          justify="center"
          alignItems="center"
        >
          <form onSubmit={handleSubmit}>
            <Grid item>
              <Typography variant="h4">Reset Password</Typography>
            </Grid>
            <FadeIn>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setNewPassword(e.target.value.trim())}
                  className={styles.inputField}
                  inputProps={{ minLength: 8 }}
                  label="New Password"
                  type="password"
                  fullWidth
                  required
                >
                  {newPassword}
                </TextField>
              </Grid>
              <Grid
                className={styles.grid}
                container
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  {isPending ? (
                    <Button
                      className={styles.btn}
                      color="default"
                      variant="contained"
                      disabled
                    >
                      Resetting...
                    </Button>
                  ) : (
                    <Button
                      className={styles.btn}
                      type="submit"
                      color="secondary"
                      variant="contained"
                    >
                      <Typography>Reset</Typography>{" "}
                      <RotateLeftIcon className={styles.resetIcon} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </FadeIn>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default ResetPassword;
