import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import VerifiedUserOutlined from "@material-ui/icons/VerifiedUserOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlined from "@material-ui/icons/ErrorOutlined";
import { userContext } from "contexts/User";
import { updateEmail, resendEmailVerificationCode } from "http/endpoints";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { notificationsContext } from "contexts/Notifications";
import FadeIn from "components/FadeIn";
import SaveIcon from "@material-ui/icons/Save";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    inputField: {
      marginTop: theme.spacing(-10),
      marginBottom: theme.spacing(1),
      width: "220px",
    },
    root: {
      marginTop: theme.spacing(14),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(2),
    },
    saveBtn: {
      marginTop: theme.spacing(1),
    },
    verifiedStatus: {
      marginTop: theme.spacing(-1),
      height: "35px",
      width: "35px",
      opacity: "0.7",
      color: "green",
      marginLeft: theme.spacing(1),
      borderRadius: "16px",
    },
    unVerifiedStatus: {
      marginTop: theme.spacing(-1),
      height: "25px",
      width: "25px",
      opacity: "0.7",
      color: "red",
      marginLeft: "10px",
      borderRadius: "16px",
    },
    saveIcon: {
      marginLeft: theme.spacing(1),
    },
    sendIcon: {
      marginLeft: theme.spacing(1),
    },
    header: {
      marginBottom: theme.spacing(-2),
    },
    resendCodeBtn: {
      marginBottom: theme.spacing(3),
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(3),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
    spacer5: {
      height: theme.spacing(5),
      width: "100%",
    },
    spacer6: {
      height: theme.spacing(6),
      width: "100%",
    },
    spacer7: {
      height: theme.spacing(7),
      width: "100%",
    },
    spacer8: {
      height: theme.spacing(8),
      width: "100%",
    },
    spacer9: {
      height: theme.spacing(9),
      width: "100%",
    },
    spacer10: {
      height: theme.spacing(10),
      width: "100%",
    },
  }),
  { index: 1 }
);

const Email: React.FC = () => {
  const history = useHistory();
  const [newEmail, setNewEmail] = useState<string>("");
  const [isPending, setIsPending] = useState<boolean>(false);
  const { user, setUser } = useContext(userContext);
  const styles = useStyles();
  const [isPendingCodeResend, setIsPendingCodeResend] =
    useState<boolean>(false);

  const {
    displayNotification,
    setShouldDisplayNotification,
    displayAPIErrorNotification,
  } = useContext(notificationsContext);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    updateEmail({ new_email: newEmail }).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setUser();
          history.push("/verify-email");
          displayNotification(
            "verification code emailed successfully",
            "success"
          );
        }, 1000);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      }
    });
  };

  const handleResendEmailVerificationCode = () => {
    setIsPendingCodeResend(true);
    resendEmailVerificationCode().then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
      }
      setTimeout(() => {
        setIsPendingCodeResend(false);
      }, 2000);
    });
  };

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <form onSubmit={handleSubmit} noValidate>
            <Grid
              className={styles.grid}
              container
              alignItems="center"
              justify="center"
            >
              <Typography className={styles.header} variant="h3">
                Email
              </Typography>
            </Grid>
            <FadeIn>
              <Grid
                className={styles.grid}
                container
                alignItems="center"
                justify="center"
              >
                <Typography>{user?.email}</Typography>
                <Typography>
                  {user?.is_verified ? (
                    <Grid
                      className={styles.grid}
                      container
                      alignItems="center"
                      justify="center"
                    >
                      <Tooltip title="verified">
                        <VerifiedUserOutlined
                          className={styles.verifiedStatus}
                        />
                      </Tooltip>
                    </Grid>
                  ) : (
                    <Grid
                      className={styles.grid}
                      container
                      alignItems="center"
                      justify="center"
                    >
                      <Tooltip title="not verified">
                        <span>
                          <ErrorOutlined className={styles.unVerifiedStatus} />
                        </span>
                      </Tooltip>
                    </Grid>
                  )}
                </Typography>
                <Grid
                  className={styles.grid}
                  container
                  alignItems="center"
                  justify="center"
                >
                  {user?.is_verified ? null : (
                    <>
                      {isPendingCodeResend ? (
                        <Button
                          className={styles.resendCodeBtn}
                          color="default"
                          disabled
                          variant="contained"
                        >
                          sending...
                        </Button>
                      ) : (
                        <Button
                          className={styles.resendCodeBtn}
                          onClick={() => {
                            handleResendEmailVerificationCode();
                            setTimeout(() => {
                              history.push("/verify-email");
                              setShouldDisplayNotification(true);
                              displayNotification(
                                "verification code emailed successfully",
                                "success"
                              );
                            }, 1000);
                          }}
                          type="submit"
                          color="secondary"
                          variant="contained"
                        >
                          Resend Code <SendIcon className={styles.sendIcon} />
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid
                direction="column"
                container
                alignItems="center"
                justify="center"
              >
                <div className={styles.spacer4} />
                <Grid item xs={12}>
                  <TextField
                    onChange={(e) => setNewEmail(e.target.value.trim())}
                    className={styles.inputField}
                    label="New Email"
                    type="email"
                    required
                  >
                    {newEmail}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  {isPending ? (
                    <Button
                      className={styles.saveBtn}
                      color="default"
                      disabled
                      variant="contained"
                    >
                      sending code...
                    </Button>
                  ) : (
                    <Button
                      className={styles.saveBtn}
                      type="submit"
                      color="secondary"
                      variant="contained"
                    >
                      save <SaveIcon className={styles.saveIcon} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </FadeIn>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default Email;
