import React from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import FadeIn from "components/FadeIn";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(20),
    },
  }),
  { index: 1 }
);

const LinkExpired: React.FC = () => {
  const styles = useStyles();

  return (
    <FadeIn>
      <div className={styles.root}>
        <Container>
          <Grid container alignItems="center" justify="center">
            <Typography variant="h3">Link Expired</Typography>
          </Grid>
        </Container>
      </div>
    </FadeIn>
  );
};

export default LinkExpired;
