import { useHistory } from "react-router-dom";
import React, { useContext, useState } from "react";
import { verifyEmail } from "http/endpoints";
import { userContext } from "contexts/User";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { notificationsContext } from "contexts/Notifications";
import FadeIn from "components/FadeIn";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(14),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(2),
    },
    inputField: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      display: "block",
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    verifyIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

const VerifyEmail = () => {
  const history = useHistory();
  const [code, setCode] = useState<string>("");
  const [isPending, setIsPending] = useState<boolean>(false);
  const { setUser } = useContext(userContext);
  const styles = useStyles();

  const {
    displayNotification,
    displayAPIErrorNotification,
    displayCustomAPIErrorNotification,
  } = useContext(notificationsContext);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    verifyEmail({ code }).then((resp) => {
      if (resp.error) {
        if (resp.error.error.detail) {
          if (
            resp.error.error.detail.includes("strconv") ||
            resp.error.error.detail.includes("not found") ||
            resp.error.error.detail.includes("expired")
          ) {
            displayCustomAPIErrorNotification("bad verification code");
            setTimeout(() => {
              setIsPending(false);
            }, 2000);
            return;
          }
        }
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setUser();
          history.push("/settings/email");
          displayNotification("successfully verified email", "success");
        }, 1000);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      }
    });
  };

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <form onSubmit={handleSubmit}>
            <Grid
              className={styles.grid}
              container
              alignItems="center"
              justify="center"
            >
              <Typography variant="h3">Verify Email</Typography>
            </Grid>
            <FadeIn>
              <Grid item>
                <TextField
                  onChange={(e) => setCode(e.target.value.trim())}
                  className={styles.inputField}
                  fullWidth
                  label="Verification-Code"
                  type="text"
                  required
                >
                  {code}
                </TextField>
              </Grid>
              <Grid
                className={styles.grid}
                container
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  {isPending ? (
                    <Button
                      className={styles.btn}
                      color="default"
                      disabled
                      variant="contained"
                    >
                      Verifying...
                    </Button>
                  ) : (
                    <Button
                      className={styles.btn}
                      type="submit"
                      color="secondary"
                      variant="contained"
                    >
                      <Typography>Verify</Typography>
                      <AssignmentTurnedInIcon className={styles.verifyIcon} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </FadeIn>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default VerifyEmail;
