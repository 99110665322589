import { Grid, makeStyles, Typography } from "@material-ui/core";
import FadeIn from "components/FadeIn";
import React, { useContext, useEffect } from "react";
import { productReviewsContext } from "contexts/ProductReviews";
import ReviewCard from "./ReviewCard";

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      padding: theme.spacing(2),
    },
    header: {
      marginTop: theme.spacing(16),
      marginBottom: theme.spacing(6),
    },
    subGrid: {
      padding: theme.spacing(3),
    },
    reviews: {
      marginTop: theme.spacing(12),
    },
  }),
  { index: 1 }
);

const Reviews: React.FC = () => {
  const { reviews, fetchProductReviews } = useContext(productReviewsContext);
  const styles = useStyles();

  useEffect(() => {
    fetchProductReviews();
  }, []);

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={styles.header} variant="h3">
          My Reviews
        </Typography>
      </Grid>
      <Grid
        container
        className={styles.reviews}
        alignItems="center"
        justify="center"
      >
        {reviews
          ? reviews.map((r) => (
              <ReviewCard key={r.id} review={r} allowRedirectToProductPage />
            ))
          : null}
      </Grid>
    </FadeIn>
  );
};

export default Reviews;
