import React, { createContext, useState } from "react";
import dayjs from "dayjs";
import { PhoneNumber } from "../http/models";
import { getPhoneNumbers } from "../http/endpoints";

interface Props {}

interface state {
  phoneNumbers: PhoneNumber[];
  fetchPhoneNumbers: () => void;
}

const initialState: state = {
  phoneNumbers: [],
  fetchPhoneNumbers: () => {},
};

export const phoneNumbersContext = createContext<state>(initialState);

export const PhoneNumbersContextProvider: React.FC<Props> = ({ children }) => {
  const [phoneNumbers, updatePhoneNumbers] = useState<PhoneNumber[]>([]);

  const fetchPhoneNumbers = () => {
    getPhoneNumbers().then((resp) => {
      if (!resp.error) {
        if (resp.body !== null) {
          const newPhoneNumbers = [...resp.body];
          const sortedNumbers = newPhoneNumbers.sort((a, b) => {
            if (dayjs(a.created_at).unix() < dayjs(b.created_at).unix()) {
              return -1;
            }
            return 1;
          });
          updatePhoneNumbers(sortedNumbers);
        } else {
          updatePhoneNumbers([]);
        }
      }
    });
  };

  return (
    <phoneNumbersContext.Provider value={{ phoneNumbers, fetchPhoneNumbers }}>
      {children}
    </phoneNumbersContext.Provider>
  );
};
