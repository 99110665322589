import React, { useState, useContext, useRef, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import NavigateNext from "@material-ui/icons/NavigateNext";
import Grid from "@material-ui/core/Grid";
import FadeIn from "components/FadeIn";
import { notificationsContext } from "contexts/Notifications";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import { userContext } from "contexts/User";
import CircularProgress from "@mui/material/CircularProgress";
import ImageCard from "../../components/ImageCard";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(14),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(-2),
    },
    uploadBtn: {
      marginTop: theme.spacing(4),
    },
    btnIcon: {
      marginLeft: theme.spacing(1),
    },
    skipBtn: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(-2),
    },
  }),
  { index: 1 }
);

const AddProductReviewImages: React.FC = () => {
  const { user } = useContext(userContext);
  const { product_id } = useParams<{ product_id: string }>();
  const { review_id } = useParams<{ review_id: string }>();
  const history = useHistory();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
  const [isPending, setIsPending] = useState<boolean>(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const styles = useStyles();
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {
    if (!user?.is_staff) {
      history.push("/not-found");
    }
  }, [selectedFiles]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files && event.target.files[0]) {
      const files: File[] = [];
      const fileNames: string[] = [];
      for (let i = 0; i < event.target.files.length; i += 1) {
        fileNames.push(event.target.files[i].name);
        files.push(event.target.files[i]);
      }
      setSelectedFileNames([...selectedFileNames, ...fileNames]);
      setSelectedFiles([...selectedFiles, ...files]);
    }
  };

  const handleDelete = (target: string) => {
    const fileNames: string[] = [];
    const files: File[] = [];
    for (let i = 0; i < selectedFileNames.length; i += 1) {
      if (target === selectedFileNames[i]) {
        // eslint-disable-next-line no-continue
        continue;
      } else {
        files.push(selectedFiles[i]);
        fileNames.push(selectedFileNames[i]);
      }
    }
    setSelectedFiles(files);
    setSelectedFileNames(fileNames);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    const data = new FormData();
    for (let i = 0; i < selectedFiles.length; i += 1) {
      data.append(
        "product-review-images",
        selectedFiles[i],
        selectedFileNames[i]
      );
    }
    fetch(`/api/v1/products/${product_id}/reviews/${review_id}/images`, {
      method: "POST",
      body: data,
    })
      .then((resp) => {
        if (resp.status === 201) {
          setIsPending(false);
          displayNotification(
            "successfully uploaded product review images",
            "success"
          );
          history.push(`/products/${product_id}`);
        } else {
          resp.json().then((json) => {
            displayAPIErrorNotification(json);
            setTimeout(() => {
              setIsPending(false);
            }, 2000);
          });
        }
      })
      .catch((error) => {
        displayAPIErrorNotification(error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      });
  };

  if (!user) {
    return <CircularProgress />;
  }

  if (!user.is_staff) {
    history.push("/not-found");
  }

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <form onSubmit={handleSubmit} noValidate>
            <Grid
              className={styles.grid}
              container
              alignItems="center"
              justify="center"
            >
              <Typography className={styles.header} variant="h3">
                Add Images to you review? (optional)
              </Typography>
            </Grid>
            <FadeIn>
              <Grid
                className={styles.skipBtn}
                container
                alignItems="center"
                justify="center"
              >
                <Button
                  onClick={(e) => history.push(`/product/${product_id}`)}
                  type="submit"
                  color="secondary"
                  variant="contained"
                >
                  Skip
                  <NavigateNext />
                </Button>
              </Grid>
              <Grid
                className={styles.grid}
                container
                alignItems="center"
                justify="center"
              >
                <Button
                  className={styles.uploadBtn}
                  color="secondary"
                  variant="contained"
                  onClick={handleClick}
                >
                  Upload <PermMediaIcon className={styles.btnIcon} />
                </Button>
                <input
                  name="product-images"
                  accept="image/*"
                  type="file"
                  onChange={handleAdd}
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  multiple
                />
              </Grid>
              {selectedFiles ? (
                <Grid
                  className={styles.grid}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isPending || selectedFiles.length === 0}
                  >
                    {isPending ? "saving..." : "Save"}
                    <SaveIcon className={styles.btnIcon} />
                  </Button>
                </Grid>
              ) : null}
              {selectedFiles.map((file) => (
                <ImageCard file={file} handleDelete={handleDelete} />
              ))}
            </FadeIn>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default AddProductReviewImages;
