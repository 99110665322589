export interface APIError {
  error: {
    message: string;
    detail?: string;
  };
}

export const errorFromResponseBody = (body: unknown): APIError | undefined => {
  if (!(body && typeof body === "object")) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const bodyErr = (body as any).error;
  if (bodyErr) {
    if (typeof bodyErr === "object") {
      return { error: bodyErr };
    }
    return { error: { message: String(bodyErr) } };
  }
  return undefined;
};
