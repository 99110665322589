import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  link: string;
  label: string;
  icon?: any;
  setUser?: () => void;
}

const useStyles = makeStyles(
  (theme) => ({
    listItem: {
      "&:hover": {
        boxShadow: `1px 2px 4px 2px ${theme.palette.grey[800]}`,
        backgroundColor: theme.palette.primary.dark,
        fontWeight: "bold",
        transition: "font-weight 0.3s ease",
        borderRadius: "8px",
        color: "white",
      },
    },
    link: {
      color: theme.palette.grey[800],
      textDecoration: "none",
    },
  }),
  { index: 1 }
);

const DropdownItem: React.FC<Props> = ({ link, setUser, icon, label }) => {
  const styles = useStyles();

  return (
    <Link to={link} className={styles.link}>
      <ListItem onClick={setUser} className={styles.listItem}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText>{label}</ListItemText>
      </ListItem>
    </Link>
  );
};

export default DropdownItem;
