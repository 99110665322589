import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { HexColorPicker } from "react-colorful";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      position: "absolute",
      width: 400,
      height: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 8, 3),
      transition: "all 3000ms ease;",
    },
    grid: {
      padding: theme.spacing(4),
    },
    gridModal: {
      marginTop: theme.spacing(2),
    },
    closeIcon: {
      marginBottom: theme.spacing(-3),
      marginLeft: "320px",
      marginTop: "-70px",
      color: theme.palette.grey[800],
      height: "40px",
      width: "40px",
      opacity: "0.5",
      "&:hover": {
        color: "red",
        boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
        cursor: "pointer",
        borderRadius: "8px",
      },
    },
    scoot: {
      marginTop: theme.spacing(-2),
    },
    inputField: {
      width: "60%",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      display: "block",
    },
    colorPicker: {
      marginLeft: theme.spacing(12),
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    sendBtn: {
      marginTop: theme.spacing(0),
    },
    sendIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

interface Props {
  open: boolean;
  setOpen: (shouldOpen: boolean) => void;
  setHexCode: React.Dispatch<React.SetStateAction<string>>;
}

const ColorPickerModal: React.FC<Props> = ({ open, setOpen, setHexCode }) => {
  const styles = useStyles();

  const modalStyle = (): React.CSSProperties => ({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    textAlign: "center",
    borderRadius: "8px",
    padding: "45px",
  });

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.paper} style={modalStyle()}>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setOpen(false)}
          />
          <Grid
            className={styles.gridModal}
            container
            alignItems="center"
            justify="center"
          />
          <Typography variant="h4">Pick a Color</Typography>
        </Grid>
        <HexColorPicker
          className={styles.colorPicker}
          defaultValue="#000"
          onChange={(color: any) => {
            setHexCode(color);
          }}
        />
        <Grid
          className={styles.gridModal}
          container
          alignItems="center"
          justify="center"
        />
        <Button
          onClick={() => setOpen(false)}
          className={styles.btn}
          color="secondary"
          variant="contained"
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ColorPickerModal;
