import React, { useContext } from "react";
import { userContext } from "contexts/User";
import DropdownItem from "components/NavBar/DropDownItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalShipping from "@material-ui/icons/LocalShipping";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import SettingsIcon from "@material-ui/icons/Settings";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { logOut } from "http/endpoints";
import List from "@material-ui/core/List";
import { notificationsContext } from "contexts/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import RateReviewIcon from "@mui/icons-material/RateReview";

const useStyles = makeStyles(
  (theme) => ({
    dropDown: {
      boxShadow: `1px 2px 4px 2px ${theme.palette.grey[800]}`,
      position: "absolute",
      backgroundColor: theme.palette.primary.main,
      top: theme.spacing(10),
      width: theme.spacing(24),
      borderRadius: "8px",
      transform: "translateX(-100%)",
      transition: "height 500ms ease",
      padding: "1rem",
      overflow: "visible",
      marginTop: "-0.2rem",
      marginLeft: theme.spacing(1),
      zIndex: 9999999,
    },
  }),
  { index: 1 }
);

const DropDown: React.FC = () => {
  const { user, setUser } = useContext(userContext);
  const styles = useStyles();
  const { displayNotification } = useContext(notificationsContext);

  let content: React.ReactNode = null;

  if (user !== null) {
    content = (
      <List>
        {user.is_staff ? (
          <DropdownItem
            icon={<ImportContactsIcon />}
            label="Catalog"
            link="/catalog"
          />
        ) : null}
        <DropdownItem icon={<ShoppingCartIcon />} label="Cart" link="/cart" />
        <DropdownItem icon={<LocalShipping />} label="Orders" link="/orders" />
        <DropdownItem
          icon={<RateReviewIcon />}
          label="Reviews"
          link="/reviews"
        />
        <DropdownItem
          icon={<SettingsIcon />}
          label="Settings"
          link="/settings/email"
        />
        <DropdownItem
          icon={<EmojiPeopleIcon />}
          label="Log Out"
          link="/"
          setUser={() => {
            logOut(null).then((resp) => {
              if (resp.status.code === 200) {
                setUser();
                displayNotification("successfully logged out", "success");
              }
            });
          }}
        />
      </List>
    );
  } else {
    content = (
      <List>
        <DropdownItem
          icon={<AssignmentIndIcon />}
          label="Sign Up"
          link="/sign-up"
        />
        <DropdownItem icon={<ExitToAppIcon />} label="Log In" link="/log-in" />
      </List>
    );
  }

  return <div className={styles.dropDown}>{content}</div>;
};

export default DropDown;
