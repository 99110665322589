import React, { createContext, useState } from "react";
import dayjs from "dayjs";
import { ProductReview } from "http/models";
import { listUserProductReviews } from "http/endpoints";

interface Props {}

interface state {
  reviews: ProductReview[];
  fetchProductReviews: () => void;
}

const initialState: state = {
  reviews: [],
  fetchProductReviews: () => {},
};

export const productReviewsContext = createContext<state>(initialState);

export const ProductsReviewsContextProvider: React.FC<Props> = ({
  children,
}) => {
  const [reviews, updateReviews] = useState<ProductReview[]>([]);

  const fetchProductReviews = () => {
    listUserProductReviews(null).then((resp) => {
      if (!resp.error) {
        if (resp.body !== null) {
          const newReviews = [...resp.body];
          const sortedReviews = newReviews.sort((a, b) => {
            if (dayjs(a.created_at).unix() < dayjs(b.created_at).unix()) {
              return -1;
            }
            return 1;
          });
          updateReviews(sortedReviews);
        } else {
          updateReviews([]);
        }
      }
    });
  };

  return (
    <productReviewsContext.Provider value={{ reviews, fetchProductReviews }}>
      {children}
    </productReviewsContext.Provider>
  );
};
