import React from "react";
import Modal from "@material-ui/core/Modal";
import { PhoneNumber } from "http/models";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      position: "absolute",
      width: 400,
      height: 260,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      transition: "all 3000ms ease;",
    },
    grid: {
      padding: theme.spacing(3),
    },
    closeIcon: {
      marginLeft: "300px",
      marginTop: "-40px",
      color: `${theme.palette.grey[800]}`,
      height: "40px",
      width: "40px",
      opacity: "0.5",
      "&:hover": {
        color: "red",
        boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
        cursor: "pointer",
        borderRadius: "8px",
      },
    },
    scoot: {
      marginTop: theme.spacing(-2),
    },
    header: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    btn: {
      marginTop: theme.spacing(5),
    },
    gridItem: {
      marginTop: theme.spacing(3),
    },
    disableIcon: {
      marginLeft: theme.spacing(1),
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(3),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
    spacer5: {
      height: theme.spacing(5),
      width: "100%",
    },
    spacer6: {
      height: theme.spacing(6),
      width: "100%",
    },
    spacer7: {
      height: theme.spacing(7),
      width: "100%",
    },
    spacer8: {
      height: theme.spacing(8),
      width: "100%",
    },
    spacer9: {
      height: theme.spacing(9),
      width: "100%",
    },
    spacer10: {
      height: theme.spacing(10),
      width: "100%",
    },
  }),
  { index: 1 }
);

interface Props {
  open: boolean;
  close: (shouldOpen: boolean) => void;
  phoneNumber: PhoneNumber;
  closeHandler: (show: boolean) => void;
  disableMFAHandler: (e: React.FormEvent<HTMLFormElement>, id: string) => void;
}

const ConfirmDisableMFAModal: React.FC<Props> = ({
  open,
  close,
  phoneNumber,
  closeHandler,
  disableMFAHandler,
}) => {
  const modalStyle = (): React.CSSProperties => ({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    textAlign: "center",
    borderRadius: "8px",
    padding: "45px",
  });

  const styles = useStyles();

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.paper} style={modalStyle()}>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <CloseIcon className={styles.closeIcon} onClick={() => close(true)} />
          <Typography variant="h4">Disable 2FA</Typography>
        </Grid>
        <form onSubmit={(e) => disableMFAHandler(e, phoneNumber.id)}>
          <Grid
            className={styles.scoot}
            container
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <Typography className={styles.gridItem}>
                2FA adds extra security to your account.
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={styles.gridItem}>
                We do not recommend disabling this feature.
              </Typography>
            </Grid>
          </Grid>
          <Button
            className={styles.btn}
            type="submit"
            color="secondary"
            variant="contained"
          >
            <Typography>Disable</Typography>
            <NotInterestedIcon className={styles.disableIcon} />
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ConfirmDisableMFAModal;
