import { Grid, makeStyles, Typography } from "@material-ui/core";
import FadeIn from "components/FadeIn";
import React, { useContext, useEffect, useState } from "react";
import { productsContext } from "contexts/Products";
import ProductCard from "pages/Products/ProductCard";
import { cartContext } from "contexts/Cart";
import { userContext } from "contexts/User";
import { Link, useHistory } from "react-router-dom";
import { Address, Order } from "http/models";
import { listOrders } from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import ItemsModal from "./ItemsModal";
import OrderStatusUpdater from "./OrderStatusUpdater";

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      padding: theme.spacing(2),
    },
    header: {
      marginTop: theme.spacing(16),
      marginBottom: theme.spacing(6),
    },
    subGrid: {
      padding: theme.spacing(3),
    },
    table: {
      margin: theme.spacing(3),
    },
  }),
  { index: 1 }
);

const Orders: React.FC = () => {
  const { user } = useContext(userContext);
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);
  const history = useHistory();
  const [orders, setOrders] = useState<readonly Order[] | null>(null);
  const [minTotal, setMinTotal] = useState<number>(0);
  const [maxTotal, setMaxTotal] = useState<number>(0);
  const [minCount, setMinCount] = useState<number>(0);
  const [maxCount, setMaxCount] = useState<number>(0);
  const [createdAtStart, setCreatedAtStart] = useState<string>("");
  const [createdAtEnd, setCreatedAtEnd] = useState<string>("");
  const [updatedAtStart, setUpdatedAtStart] = useState<string>("");
  const [updatedAtEnd, setUpdatedAtEnd] = useState<string>("");
  const [paymentId, setPaymentId] = useState<string>("");
  const styles = useStyles();
  const [show, setShow] = useState<boolean>(true);

  const [shouldOpenItemsModal, setShouldOpenItemsModal] =
    useState<boolean>(false);

  useEffect(() => {
    listOrders({}, null).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        return;
      }
      setOrders(resp.body);
    });
  }, []);

  const addrToString = (addr: Address): string => `
    ${addr.street[0]}
    ${addr.street.length > 1 ? addr.street[1] : ""}
    ${addr.city}, ${addr.state}
    ${addr.zip_code}
  `;

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={styles.header} variant="h3">
          Orders
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        {orders ? (
          <TableContainer className={styles.table} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="table">
              <TableHead>
                {user?.is_staff ? (
                  <TableRow>
                    <TableCell>Created By</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Last Updated By</TableCell>
                    <TableCell>Last Updated At</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Item Count</TableCell>
                    <TableCell>Shipping To</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>Item Count</TableCell>
                    <TableCell>Shipping To</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow
                    key={order.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {user?.is_staff ? (
                      <>
                        <TableCell align="left">
                          {order.created_by_username}
                        </TableCell>
                        <TableCell align="left">
                          {new Date(order.created_at).toLocaleString()}
                        </TableCell>
                        <TableCell align="left">
                          {order.last_updated_by_username}
                        </TableCell>
                        <TableCell align="left">
                          {new Date(order.updated_at).toLocaleString()}
                        </TableCell>
                        <TableCell align="left">${order.total}</TableCell>
                        <TableCell align="left">
                          <Link to={`/orders/${order.id}`}>
                            {order.item_count}
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          {addrToString(order.shipping_address)}
                        </TableCell>
                        <TableCell align="left">
                          <OrderStatusUpdater key={order.id} order={order} />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="left">${order.total}</TableCell>
                        <TableCell align="left">
                          <Link to={`/orders/${order.id}`}>
                            {order.item_count}
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          {addrToString(order.shipping_address)}
                        </TableCell>
                        <TableCell align="left">
                          <Chip label={order.status} color="primary" />
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Grid>
    </FadeIn>
  );
};

export default Orders;
