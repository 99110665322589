import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getPaymentsKey } from "./http/endpoints";

document.addEventListener("DOMContentLoaded", async () => {
  const resp = await getPaymentsKey(null, null);
  if (resp.error) {
    return;
  }

  if (!resp.body.publishable_key) {
    return;
  }
  const stripePromise = loadStripe(resp.body.publishable_key);

  ReactDOM.render(
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
