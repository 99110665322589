import React, { useState, useContext, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FadeIn from "components/FadeIn";
import { userContext } from "contexts/User";
import { makeStyles } from "@material-ui/core/styles";
import { ClickAwayListener } from "@material-ui/core";
import DropDown from "./DropDown";

const useStyles = makeStyles(
  (theme) => ({
    active: {
      marginTop: theme.spacing(1.5),
      cursor: "pointer",
      width: theme.spacing(6),
      height: theme.spacing(6),
      boxShadow: `1px 2px 4px 2px ${theme.palette.grey[800]}`,
      color: theme.palette.grey[50],
      fontWeight: "bold",
      transition: "font-weight 0.3s ease",
      zIndex: 999999999999,
    },
    inactive: {
      boxShadow: `1px 2px 4px 2px ${theme.palette.grey[800]}`,
      marginTop: theme.spacing(1.5),
      width: theme.spacing(6),
      height: theme.spacing(6),
      marginLeft: theme.spacing(2),
    },
  }),
  { index: 1 }
);

const UserAvatar: React.FC = () => {
  const { user } = useContext(userContext);
  const [open, setOpen] = useState<boolean>(false);
  const [protocol, setProtocol] = useState<string>("http://");
  const [avatar, setAvatar] = useState<string>("");
  const styles = useStyles();

  useEffect(() => {
    if (user && user.avatar) {
      setAvatar(user.avatar);
      if (user.avatar.includes("storage.googleapis.com/")) {
        setProtocol("https://");
      } else {
        setAvatar(`localhost:3000/img/avatars/${user.avatar}`);
      }
    } else {
      setAvatar("");
    }
  }, [user, open]);

  const close = () => {
    setOpen(false);
  };

  return (
    <List>
      <ListItem>
        {avatar ? (
          <Avatar
            onMouseOver={() => setOpen(true)}
            onClick={() => setOpen(true)}
            className={open ? styles.active : styles.inactive}
            src={`${protocol + avatar}`}
            style={{ height: "4rem", width: "4rem" }}
          />
        ) : (
          <Avatar
            onMouseOver={() => setOpen(true)}
            onClick={() => setOpen(true)}
            className={open ? styles.active : styles.inactive}
            style={{ height: "4rem", width: "4rem" }}
          >
            <AccountCircle style={{ height: "100%", width: "100%" }} />
          </Avatar>
        )}
        {open && (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div onClick={close} onMouseLeave={close} role="presentation">
              <FadeIn>
                <DropDown />
              </FadeIn>
            </div>
          </ClickAwayListener>
        )}
      </ListItem>
    </List>
  );
};

export default UserAvatar;
