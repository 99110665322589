import React, { useContext, useEffect, useState } from "react";
import { OrderAuditLogImage, ProductReviewImage } from "http/models";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import {
  deleteProductImage,
  setProductImageAsPrimary,
  setProductReviewImageAsPrimary,
} from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import { DeleteOutlined } from "@material-ui/icons";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    card: {
      display: "inline-block",
      margin: theme.spacing(1),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      padding: theme.spacing(1),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    setAsPrimaryBtn: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: "white",
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "white",
      },
    },
    primaryIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

interface Props {
  img: OrderAuditLogImage;
}

const OrderAuditLogImageCard: React.FC<Props> = ({ img }) => {
  const styles = useStyles();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  const resolveURL = (url: string | undefined): string => {
    if (!url) {
      return "";
    }
    let imgPath = `/img/order-audit-logs/${url}`;
    if (url.includes("storage.googleapis.com/")) {
      imgPath = `https://${url}`;
    }
    return imgPath;
  };

  return (
    <Card variant="outlined" className={styles.card}>
      <CardMedia
        component="img"
        height="194"
        image={resolveURL(img.url)}
        alt="order audit log image not found"
      />
    </Card>
  );
};

export default OrderAuditLogImageCard;
