import React, { useContext, useState, useRef } from "react";
import { userContext } from "contexts/User";
import { notificationsContext } from "contexts/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import FadeIn from "components/FadeIn";
import { Avatar, Button } from "@material-ui/core";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(14),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(-2),
    },
    avatar: {
      boxShadow: `1px 2px 4px 2px ${theme.palette.grey[800]}`,
      height: theme.spacing(20),
      width: theme.spacing(20),
      borderRadius: "50%",
      padding: 0,
      color: theme.palette.grey[300],
    },
    btnIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

const UserAvatar: React.FC = () => {
  const [formData, setFormData] = useState<File>();
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [isPending, setIsPending] = useState<boolean>(false);
  const { user, setUser } = useContext(userContext);
  const styles = useStyles();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files && event.target.files[0]) {
      setSelectedFile(URL.createObjectURL(event.target.files[0]));
      setFormData(event.target.files[0]);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    if (!formData) {
      return;
    }
    const data = new FormData();
    data.append("avatar", formData);
    fetch("/api/v1/settings/avatar", {
      method: "POST",
      body: data,
    })
      .then((resp) => {
        if (resp.status === 200) {
          setUser();
          setIsPending(false);
          displayNotification(
            "successfully updated profile picture",
            "success"
          );
        } else {
          resp.json().then((json) => {
            displayAPIErrorNotification(json);
            setTimeout(() => {
              setIsPending(false);
            }, 2000);
          });
        }
      })
      .catch((error) => {
        displayAPIErrorNotification(error);
        setTimeout(() => {
          setIsPending(false);
        }, 2000);
      });
  };

  let node: React.ReactNode = <AccountCircle className={styles.avatar} />;
  if (user && user.avatar) {
    let imgPath = `/img/avatars/${user.avatar}`;
    if (user.avatar.includes("storage.googleapis.com/")) {
      imgPath = `https://${user.avatar}`;
    }

    node = (
      <Avatar
        className={styles.avatar}
        src={imgPath}
        style={{ height: "10rem", width: "10rem" }}
      />
    );
  }

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <form onSubmit={handleSubmit} noValidate>
            <Grid
              className={styles.grid}
              container
              alignItems="center"
              justify="center"
            >
              <Typography className={styles.header} variant="h3">
                Avatar
              </Typography>
            </Grid>
            <FadeIn>
              <Grid
                className={styles.grid}
                container
                alignItems="center"
                justify="center"
              >
                {selectedFile ? (
                  <Avatar
                    className={styles.avatar}
                    src={selectedFile}
                    style={{ height: "10rem", width: "10rem" }}
                  />
                ) : (
                  node
                )}
              </Grid>
              <Grid
                className={styles.grid}
                container
                alignItems="center"
                justify="center"
              >
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleClick}
                >
                  Upload <PermMediaIcon className={styles.btnIcon} />
                </Button>
                <input
                  name="avatar"
                  accept="image/*"
                  type="file"
                  onChange={handleChange}
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                />
              </Grid>
              {selectedFile ? (
                <Grid
                  className={styles.grid}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isPending}
                  >
                    {isPending ? "saving..." : "save"}
                    <SaveIcon className={styles.btnIcon} />
                  </Button>
                </Grid>
              ) : null}
            </FadeIn>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default UserAvatar;
