import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { Product, ProductVariation } from "http/models";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { DeleteOutlined } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { deleteProduct } from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    editIcon: {
      marginTop: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      margin: theme.spacing(1),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      padding: theme.spacing(1),
      width: theme.spacing(36),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      right: 0,
      marginLeft: theme.spacing(3),
    },
  }),
  { index: 1 }
);

interface Props {
  product: Product;
}

const ProductCard: React.FC<Props> = ({ product }) => {
  const history = useHistory();
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);
  const styles = useStyles();
  const [show, setShow] = useState<boolean>(true);
  const [variation] = useState<ProductVariation>(
    product.variations.filter((v) => v.is_primary)[0]
  );

  const handleDelete = (id: string) => {
    deleteProduct({ id }).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
      } else {
        setShow(false);
        displayNotification("successfully deleted product", "success");
      }
    });
  };

  const resolveURL = (url: string | undefined): string => {
    if (!url) {
      return "";
    }
    let imgPath = `/img/product-variations/${url}`;
    if (url.includes("storage.googleapis.com/")) {
      imgPath = `https://${url}`;
    }
    return imgPath;
  };

  const handleEditProduct = (id: string) =>
    history.push(`/catalog/product/${id}`);

  const truncate = (s: string, len: number) =>
    s.length > len ? `${s.substring(0, len)}...` : s;

  return (
    <Card variant="outlined" className={show ? styles.card : styles.fadeOut}>
      <CardHeader
        action={
          <Tooltip title="delete" placement="top">
            <IconButton
              className={styles.deleteIcon}
              onClick={() => handleDelete(product.id)}
            >
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        }
        title={
          <Grid direction="row" justify="center" alignItems="center">
            <Typography className={styles.product} variant="h5">
              {truncate(variation?.name as string, 15)}
            </Typography>
          </Grid>
        }
      />
      {variation && variation.images ? (
        <CardMedia
          component="img"
          height="194"
          image={resolveURL(
            variation.images.find((img) => img.is_primary)?.url
          )}
          alt="product image not found"
        />
      ) : null}
      <CardContent>
        <Typography variant="body2">
          {truncate(variation.description, 30)}
        </Typography>
      </CardContent>
      <CardActions>
        <Tooltip title="edit product" placement="top">
          <IconButton
            className={styles.editIcon}
            onClick={() => handleEditProduct(product.id)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h5" align="right" className={styles.price}>
          ${variation.price}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
