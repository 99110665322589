import React, { createContext, useState } from "react";
import dayjs from "dayjs";
import { Address, AddressType } from "../http/models";
import { getAddresses } from "../http/endpoints";

interface Props {}

interface state {
  addresses: Address[];
  fetchAddresses: () => void;
}

const initialState: state = {
  addresses: [],
  fetchAddresses: () => {},
};

export const addressesContext = createContext<state>(initialState);

export const AddressesContextProvider: React.FC<Props> = ({ children }) => {
  const [addresses, updateAddresses] = useState<Address[]>([]);

  const fetchAddresses = () => {
    getAddresses().then((resp) => {
      if (!resp.error) {
        if (resp.body !== null) {
          const newAddresses = [...resp.body];
          const sortedAddresses = newAddresses.sort((a, b) => {
            if (dayjs(a.created_at).unix() < dayjs(b.created_at).unix()) {
              return -1;
            }
            return 1;
          });
          updateAddresses(sortedAddresses);
        } else {
          updateAddresses([]);
        }
      }
    });
  };

  return (
    <addressesContext.Provider value={{ addresses, fetchAddresses }}>
      {children}
    </addressesContext.Provider>
  );
};
