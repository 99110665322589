export interface User {
  readonly id: string;
  readonly name: string;
  readonly email: string;
  readonly avatar: string;
  readonly is_verified: boolean;
  readonly enabled_mfa: boolean;
  readonly is_staff: boolean;
  readonly created_at: string;
  readonly updated_at: string;
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface RegistrationForm {
  username: string;
  email: string;
  password: string;
}

export interface EmailUpdateRequest {
  new_email: string;
}

export interface PasswordUpdateRequest {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

export interface PhoneNumber {
  readonly id: string;
  readonly user_id: string;
  readonly user_name: string;
  readonly country_code: string;
  readonly phone_number: number;
  readonly type: string;
  readonly created_at: string;
  readonly updated_at: string;
  is_verified: boolean;
  is_mfa_number: boolean;
}

export interface PhoneNumberParams {
  id: string;
}

export interface CreatePhoneNumberRequest {
  country_code: string;
  phone_number: string;
  type: string;
}

export interface PasswordReset {
  readonly id: string;
  readonly user_id: string;
}

export interface GetPasswordResetRequest {
  id: string;
}

export interface ResetPasswordRequest {
  id: string;
  new_password: string;
}

export interface InitializePasswordResetRequest {
  email: string;
}

export interface VerifyEmailRequest {
  code: string;
}

export interface VerifyPhoneNumberRequest {
  id: string;
  code: string;
}

export interface VerifyMFARequest {
  code: string;
}

export interface Address {
  readonly id?: string;
  readonly user_id?: string;
  readonly organization: string;
  readonly street: string[];
  readonly city: string;
  readonly state: string;
  readonly zip_code: string;
  readonly country: string;
  readonly type: AddressType;
  readonly created_at?: string;
}

export interface AddressRequest {
  organization?: string;
  street: string[];
  city: string;
  state: string;
  zip_code: string;
  country: string;
  type: AddressType;
}

export interface AddressParams {
  id: string;
}

export interface Product {
  readonly id: string;
  readonly services: ProductService[];
  readonly variations: ProductVariation[];
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at: string;
}

export interface ProductParams {
  id: string;
  show_deleted?: boolean;
}

export interface ProductRequest {}

export interface ProductImage {
  readonly id: string;
  readonly product_id: string;
  readonly url: string;
  readonly is_primary: boolean;
  readonly created_at: string;
  readonly updated_at: string;
}

export interface ProductImageParams {
  id: string;
}

export interface CartItem {
  readonly id: string;
  readonly user_id: string;
  readonly product_id: string;
  readonly variation_id: string;
  readonly product_name: string;
  readonly product_image: string;
  readonly price: string;
  readonly size?: string;
  readonly color?: ProductColor;
  readonly additional_services?: ProductService[];
  readonly quantity: number;
  readonly created_at: string;
}

export interface CreateCartItemRequest {
  product_id: string;
  variation_id: string;
  additional_services: ProductService[] | null;
  size: string | null;
}

export interface UpdateCartItemRequest {
  product_id: string;
  quantity: number;
}

export interface CartItemParams {
  id: string;
}

export interface PaymentIntentRequest {
  amount: string;
}

export interface PaymentIntentResponse {
  client_secret: string;
  status: string;
}

export interface PaymentsKeyResponse {
  publishable_key: string;
}

export interface CreateOrderRequest {
  payment_id: string;
  shipping_address: Address;
  billing_address: Address;
  items: CartItem[];
}

export interface Order {
  readonly id: string;
  readonly payment_id: string;
  readonly shipping_address: Address;
  readonly billing_address: Address;
  readonly items: CartItem[];
  readonly item_count: number;
  readonly total: string;
  readonly sales_tax: string;
  readonly shipping_cost: string;
  readonly status: string;
  readonly created_at: string;
  readonly created_by_id: string;
  readonly created_by_username: string;
  readonly updated_at: string;
  readonly last_updated_by_id: string;
  readonly last_updated_by_username: string;
}

export interface OrderRequest {
  id: string;
}

export interface OrderFilter {
  payment_id?: string;
  product_id?: string;
  max_order_total?: string;
  min_order_total?: string;
  max_item_count?: number;
  min_item_count?: number;
  user_id?: string;
  username?: string;
  status?: string;
  created_at_start?: string;
  created_at_end?: string;
  updated_at_start?: string;
  updated_at_end?: string;
}

export interface UpdateOrderStatusBody {
  reason?: string;
}

export interface OrderAuditLogImage {
  readonly id: string;
  readonly order_audit_log_id: string;
  readonly url: string;
  readonly is_primary: boolean;
  readonly created_at: string;
  readonly updated_at: string;
}

export interface OrderAuditLog {
  readonly id: string;
  readonly order_id: string;
  readonly og_status: string;
  readonly new_status: string;
  readonly reason: string;
  readonly images: OrderAuditLogImage[];
  readonly updated_at: string;
  readonly last_updated_by_id: string;
  readonly last_updated_by_username: string;
}

export interface OrderAuditLogFilter {
  order_id?: string;
  status?: string;
  updated_at_start?: string;
  updated_at_end?: string;
}

export interface UpdateOrderStatusParams {
  id: string;
  status: string;
  notify?: boolean;
}
// eslint-disable-next-line no-shadow
export enum OrderStatus {
  Settled = "settled",
  Shipped = "shipped",
  Returning = "returning",
  Returned = "returned",
  Refunded = "refunded",
  Fulfilled = "fulfilled",
}

export const OrderStatuses = [
  OrderStatus.Settled,
  OrderStatus.Shipped,
  OrderStatus.Returning,
  OrderStatus.Returned,
  OrderStatus.Refunded,
  OrderStatus.Fulfilled,
];
// eslint-disable-next-line no-shadow
export enum AddressType {
  Shipping = "shipping",
  Billing = "billing",
  Both = "both",
}

export const AddressTypes = [
  AddressType.Shipping,
  AddressType.Billing,
  AddressType.Both,
];

export interface CalculateShippingResponse {
  miles: number;
}

export interface ProductReviewImage {
  readonly id: string;
  readonly product_review_id: string;
  readonly url: string;
  readonly is_primary: boolean;
  readonly created_at: string;
  readonly updated_at: string;
}

export interface ProductReview {
  readonly id: string;
  readonly product_id: string;
  readonly order_id: string;
  readonly variation_id: string;
  readonly headline: string;
  readonly stars: number;
  readonly review: string;
  readonly images: ProductReviewImage[];
  readonly created_at: string;
  readonly created_by_user_id: string;
  readonly created_by_username: string;
  readonly created_by_user_avatar: string;
  readonly updated_at: string;
}

export interface ProductService {
  id: string;
  product_id: string;
  name: string;
  description: string;
  total: string;
  enabled: boolean;
  created_at: string;
  created_by_user_id: string;
  created_by_username: string;
  updated_at: string;
  last_updated_by_user_id: string;
  last_updated_by_username: string;
}

export interface CreateProductReviewRequest {
  headline: string;
  stars: number;
  review: string;
  order_id: string;
  variation_id: string;
}

export interface ProductSize {
  product_id: string;
  variation_id: string;
  size: string;
}

export interface ProductColor {
  product_id?: string;
  variation_id?: string;
  name: string;
  hex: string;
}

export interface ProductVariationImage {
  readonly id: string;
  readonly product_variation_id: string;
  readonly url: string;
  readonly is_primary: boolean;
  readonly created_at: string;
  readonly updated_at: string;
}

export interface ProductVariation {
  id: string;
  product_id: string;
  name: string;
  description: string;
  size: ProductSize;
  sizes?: string[];
  color?: ProductColor;
  price: string;
  is_primary: boolean;
  readonly images: ProductVariationImage[];
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface ProductReviewParams {
  product_id: string;
  review_id?: string;
  img_id?: string;
}

export interface ProductVariationParams {
  product_id: string;
  variation_id?: string;
}

export interface CreateProductVariationRequest {
  name: string;
  description: string;
  sizes?: string[] | null;
  color?: ProductColor | null;
  price: string;
}

export interface ProductVariationImageParams {
  product_id: string;
  variation_id?: string;
  img_id?: string;
}

export interface UpdateProductServiceRequest {
  name?: string;
  description?: string;
  total?: string;
  enabled?: boolean;
}

export interface CreateProductServiceRequest {
  name: string;
  description: string;
  total: string;
}

export interface ProductServiceParams {
  product_id: string;
  service_id?: string;
}

export interface UpdateProductReviewRequest {
  stars: number;
  review: string;
  headline: string;
}

export interface ListProductReviewsResponse {
  readonly reviews: ProductReview[];
}

export const StateAbreviations = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "DC",
  "GU",
  "MH",
  "MP",
  "PR",
  "VI",
];
