import { Grid, makeStyles, Typography } from "@material-ui/core";
import FadeIn from "components/FadeIn";
import React, { useContext, useEffect } from "react";
import { productsContext } from "contexts/Products";
import ProductCard from "pages/Products/ProductCard";
import { cartContext } from "contexts/Cart";
import { userContext } from "contexts/User";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      padding: theme.spacing(2),
    },
    header: {
      marginTop: theme.spacing(16),
      marginBottom: theme.spacing(6),
    },
    subGrid: {
      padding: theme.spacing(3),
    },
  }),
  { index: 1 }
);

const Products: React.FC = () => {
  const { user } = useContext(userContext);
  const history = useHistory();
  const { products, fetchProducts } = useContext(productsContext);
  const { fetchCartItems } = useContext(cartContext);
  const styles = useStyles();

  useEffect(() => {
    fetchProducts();
    fetchCartItems();
  }, []);

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={styles.header} variant="h3">
          Products
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        {products.map((product) => (
          <ProductCard
            key={product.id}
            variation={product.variations.filter((v) => v.is_primary)[0]}
          />
        ))}
      </Grid>
    </FadeIn>
  );
};

export default Products;
