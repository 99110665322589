import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FadeIn from "components/FadeIn";
import AddIcon from "@material-ui/icons/Add";
import { Address, AddressType } from "http/models";
import { addressesContext } from "contexts/Addresses";
import AddAddressModal from "pages/Settings/AddAddressModal";
import AddressCard from "pages/Settings/AddressCard";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(17),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(1),
    },
    addresses: {
      marginTop: theme.spacing(-2),
    },
    subGrid: {
      padding: theme.spacing(5),
    },
    btn: {
      marginTop: theme.spacing(-4),
    },
    scoot: {
      marginTop: theme.spacing(5),
    },
    addIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

const Addresses: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [newAddress, setNewAddress] = useState<string>("");
  const { addresses, fetchAddresses } = useContext(addressesContext);
  const styles = useStyles();

  useEffect(() => {
    fetchAddresses();
  }, []);

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Typography variant="h3">Address</Typography>
        </Grid>
        <Grid
          className={styles.subGrid}
          container
          alignItems="center"
          justify="center"
        >
          <FadeIn>
            <Button
              className={styles.btn}
              onClick={() => setShow(true)}
              type="submit"
              color="secondary"
              variant="contained"
            >
              New <AddIcon className={styles.addIcon} />
            </Button>
          </FadeIn>
          <AddAddressModal
            open={show}
            setOpen={setShow}
            newAddress={newAddress}
            setNewAddressHandler={setNewAddress}
            closeHandler={() => setShow(false)}
          />
        </Grid>
        <Grid
          className={styles.addresses}
          container
          alignItems="center"
          justify="center"
        >
          {addresses ? (
            addresses.map((address: Address) => (
              <FadeIn>
                <AddressCard key={address.id} address={address} />
              </FadeIn>
            ))
          ) : (
            <Typography color="textSecondary">
              no addresses added yet
            </Typography>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default Addresses;
