import React from "react";
import { CardMedia } from "@material-ui/core";
import FadeIn from "components/FadeIn";

interface Props {
  src: string | undefined;
}

const CartItemImage: React.FC<Props> = ({ src }) => {
  const resolveURL = (url: string | undefined): string => {
    if (!url) {
      return "";
    }
    let imgPath = `/img/product-variations/${url}`;
    if (url.includes("storage.googleapis.com/")) {
      imgPath = `https://${url}`;
    }
    return imgPath;
  };

  return (
    <FadeIn>
      <CardMedia
        component="img"
        height="194"
        image={resolveURL(src)}
        alt="cart item"
      />
    </FadeIn>
  );
};

export default CartItemImage;
