import React, { useContext, useEffect, useState } from "react";
import { ProductReviewImage } from "http/models";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import {
  deleteProductImage,
  deleteProductReviewImage,
  setProductImageAsPrimary,
  setProductReviewImageAsPrimary,
} from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import { DeleteOutlined } from "@material-ui/icons";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    card: {
      margin: theme.spacing(1),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      padding: theme.spacing(1),
      width: theme.spacing(36),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    setAsPrimaryBtn: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: "white",
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "white",
      },
    },
    primaryIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

interface Props {
  productId: string;
  reviewImage: ProductReviewImage;
}

const ReviewImageCard: React.FC<Props> = ({ productId, reviewImage }) => {
  const styles = useStyles();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  const handleDelete = (id: string) => {
    deleteProductReviewImage({
      product_id: productId,
      review_id: reviewImage.product_review_id,
      img_id: id,
    }).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
      } else {
        setShow(false);
        displayNotification("successfully deleted product image", "success");
      }
    });
  };

  const setAsPrimary = (id: string) => {
    setIsPending(true);
    setProductReviewImageAsPrimary(
      {
        product_id: productId,
        review_id: reviewImage.product_review_id,
        img_id: reviewImage.id,
      },
      null
    ).then((resp) => {
      if (resp.error) {
        setTimeout(() => {
          displayAPIErrorNotification(resp.error);
        }, 1000);
      } else {
        setTimeout(() => {
          displayNotification("successfully updated primary image", "success");
        }, 500);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const resolveURL = (url: string | undefined): string => {
    if (!url) {
      return "";
    }
    let imgPath = `/img/reviews/${url}`;
    if (url.includes("storage.googleapis.com/")) {
      imgPath = `https://${url}`;
    }
    return imgPath;
  };

  return (
    <Card variant="outlined" className={show ? styles.card : styles.fadeOut}>
      <CardHeader
        action={
          <Tooltip title="delete" placement="top">
            <IconButton
              className={styles.deleteIcon}
              onClick={() => handleDelete(reviewImage.id)}
              disabled={isPending || reviewImage.is_primary}
            >
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        }
      />
      <CardMedia
        component="img"
        height="194"
        image={resolveURL(reviewImage.url)}
        alt="product review image not found"
      />
      <CardActions disableSpacing>
        <Button
          className={styles.setAsPrimaryBtn}
          type="button"
          color="secondary"
          variant="contained"
          onClick={() => setAsPrimary(reviewImage.id)}
          disabled={isPending || reviewImage.is_primary}
        >
          {isPending ? "Updating..." : "Set as Cover Image"}
          <ImageSearchIcon className={styles.primaryIcon} />
        </Button>
      </CardActions>
    </Card>
  );
};

export default ReviewImageCard;
