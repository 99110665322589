import React, { createContext, useState } from "react";
import { Color } from "@material-ui/lab/Alert";
import { Notification } from "components/Notification";
import { APIError } from "http/error";

interface Props {}

interface state {
  notification: Notification | null;
  displayNotification: (msg: string, level: Color) => void;
  shouldDisplayNotification: boolean;
  setShouldDisplayNotification: (shouldDisplay: boolean) => void;
  displayAPIErrorNotification: (resp: APIError) => void;
  displayCustomAPIErrorNotification: (msg: string) => void;
}

const initialState: state = {
  notification: null,
  displayNotification: (msg: string, level: Color) => {},
  shouldDisplayNotification: false,
  setShouldDisplayNotification: (shouldDisplay: boolean) => {},
  displayAPIErrorNotification: (resp: APIError) => {},
  displayCustomAPIErrorNotification: (msg: string) => {},
};

export const notificationsContext = createContext(initialState);

export const NotificationsContextProvider: React.FC<Props> = ({ children }) => {
  const [notification, setNotification] = useState<Notification | null>(null);
  const [shouldDisplayNotification, setShouldDisplayNotification] =
    useState<boolean>(false);

  const displayNotification = (msg: string, level: Color) => {
    setShouldDisplayNotification(true);
    setNotification({ message: msg, level });
  };

  const displayAPIErrorNotification = (resp: APIError) => {
    if (resp.error) {
      let msg: string;
      if (resp.error.detail) {
        msg = resp.error.detail;
      } else {
        msg = resp.error.message;
      }
      displayNotification(msg, "error");
    }
  };

  return (
    <notificationsContext.Provider
      value={{
        notification,
        displayNotification,
        shouldDisplayNotification,
        setShouldDisplayNotification,
        displayAPIErrorNotification,
        displayCustomAPIErrorNotification: (msg: string) =>
          displayNotification(msg, "error"),
      }}
    >
      {children}
    </notificationsContext.Provider>
  );
};
