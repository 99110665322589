import React, { createContext, useState } from "react";
import dayjs from "dayjs";
import { CartItem } from "http/models";
import { listCartItems } from "http/endpoints";

interface Props {}

interface state {
  cartItems: CartItem[];
  fetchCartItems: () => void;
  existsInCart: (productID: string) => boolean;
}

const initialState: state = {
  cartItems: [],
  fetchCartItems: () => {},
  existsInCart: (productID: string) => false,
};

export const cartContext = createContext<state>(initialState);

export const CartContextProvider: React.FC<Props> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);

  const fetchCartItems = () => {
    listCartItems(null, null).then((resp) => {
      if (!resp.error) {
        if (resp.body !== null) {
          const newCartItems = [...resp.body];
          const sortedCartItems = newCartItems.sort((a, b) => {
            if (dayjs(a.created_at).unix() < dayjs(b.created_at).unix()) {
              return -1;
            }
            return 1;
          });
          setCartItems(sortedCartItems);
        } else {
          setCartItems([]);
        }
      }
    });
  };

  const existsInCart = (productID: string): boolean => {
    const result = cartItems.find(
      (cartItem) => cartItem.product_id === productID
    );
    if (!result) {
      return false;
    }
    return true;
  };

  return (
    <cartContext.Provider value={{ cartItems, fetchCartItems, existsInCart }}>
      {children}
    </cartContext.Provider>
  );
};
