import React, { useContext, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { Address, Order, OrderAuditLog } from "http/models";
import FadeIn from "components/FadeIn";
import { getOrder, listOrderAuditLogs } from "http/endpoints";
import { useHistory, useParams } from "react-router-dom";
import { userContext } from "contexts/User";
import { CircularProgress } from "@material-ui/core";
import { notificationsContext } from "contexts/Notifications";
import ItemCard from "./ItemCard";
import OrderAuditLogCard from "./OrderAuditLogCard";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      position: "absolute",
      width: 400,
      height: 260,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      transition: "all 3000ms ease;",
    },
    grid: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(16),
    },
    closeIcon: {
      marginLeft: "300px",
      marginTop: "-40px",
      color: `${theme.palette.grey[800]}`,
      height: "40px",
      width: "40px",
      opacity: "0.5",
      "&:hover": {
        color: "red",
        boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
        cursor: "pointer",
        borderRadius: "8px",
      },
    },
    scoot: {
      marginTop: theme.spacing(-2),
    },
    header: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    btn: {
      marginTop: theme.spacing(5),
    },
    gridItem: {
      marginTop: theme.spacing(3),
    },
    disableIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

const OrderPage: React.FC = () => {
  const { user } = useContext(userContext);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const styles = useStyles();
  const [order, setOrder] = React.useState<Order | undefined>(undefined);
  const [orderAuditLogs, setOrderAuditLogs] = React.useState<OrderAuditLog[]>(
    []
  );
  const [additionalServicesTotal, setAdditionalServicesTotal] =
    React.useState<string>("");
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {
    getOrder({ id }).then((resp) => {
      if (resp.status.code !== 200) {
        history.push("/not-found");
        return;
      }

      const o = resp.body;
      setOrder(o);

      listOrderAuditLogs({ order_id: o?.id }).then((listResp) => {
        if (listResp.error) {
          displayAPIErrorNotification(listResp.error);
        } else {
          const logs = (listResp.body as OrderAuditLog[]).filter(
            (l) => l.order_id === id
          );
          setOrderAuditLogs(logs);
        }
      });

      if (o) {
        let servicesTotal: number = 0;
        for (let i = 0; i < o.items.length; i += 1) {
          const item = o.items[i];
          if (item.additional_services) {
            const numServices = item.additional_services.length;
            for (let j = 0; j < numServices; j += 1) {
              servicesTotal += toPrice(item.additional_services[j].total);
            }
          }
        }
        const f = parseFloat(servicesTotal.toString());
        const result = f.toFixed(2);
        setAdditionalServicesTotal(result);
      }
    });
  }, []);

  const toPrice = (priceString: string): number => {
    let price: number;
    if (priceString.includes(".")) {
      const f = parseFloat(priceString);
      price = Number(f.toFixed(2));
    } else {
      price = parseInt(priceString, 10);
    }
    return price;
  };

  // if (user && order) {
  //   if (order.created_by_id !== user.id && !user.is_staff) {
  //     history.push("/not-found");
  //   }
  // } else {
  //   history.push("/not-found");
  // }

  const addrToString = (addr: Address): string => `
    ${addr.street[0]}
    ${addr.street.length > 1 ? addr.street[1] : ""}
    ${addr.city}, ${addr.state}
    ${addr.zip_code}
  `;

  if (!order) {
    return <CircularProgress />;
  }

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={styles.header} variant="h3">
          Order
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Typography className={styles.header} variant="subtitle1">
          <strong>ID:</strong> {order?.id}
        </Typography>
      </Grid>

      <Grid container alignItems="center" justify="center">
        <Typography className={styles.header} variant="subtitle1">
          <strong>Shipping</strong>: ${order?.shipping_cost}
        </Typography>
      </Grid>

      <Grid container alignItems="center" justify="center">
        <Typography className={styles.header} variant="subtitle1">
          <strong>Sales Tax</strong>: ${order?.sales_tax}
        </Typography>
      </Grid>

      {/* TODO: calculate total of all additional services(for all items) */}
      <Grid container alignItems="center" justify="center">
        <Typography className={styles.header} variant="subtitle1">
          <strong>Additional Services Total</strong>: ${additionalServicesTotal}
        </Typography>
      </Grid>

      <Grid container alignItems="center" justify="center">
        <Typography className={styles.header} variant="subtitle1">
          <strong>Total</strong>: ${order?.total}
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Typography className={styles.header} variant="subtitle1">
          <strong>Shipping To:</strong>{" "}
          {addrToString(order?.shipping_address as Address)}
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Typography className={styles.header} variant="subtitle1">
          <strong>Status:</strong> {order?.status}
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Typography className={styles.header} variant="h3">
          Items({order?.item_count})
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        {order?.items?.map((item) => (
          <ItemCard key={item.id} item={item} />
        ))}
      </Grid>

      <Grid container alignItems="center" justify="center">
        <Typography className={styles.header} variant="h3">
          History
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Typography className={styles.header} variant="subtitle1">
          (Newest to Oldest in descending order)
        </Typography>
      </Grid>
      <Grid container alignItems="center" justify="center">
        {orderAuditLogs
          ?.sort((a, b) =>
            Date.parse(a.updated_at) > Date.parse(b.updated_at) ? -1 : 1
          )
          .map((l) => (
            <OrderAuditLogCard key={l.id} orderAuditLog={l} />
          ))}
      </Grid>
    </FadeIn>
  );
};

export default OrderPage;
