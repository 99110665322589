import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { productsContext } from "contexts/Products";
import { Button, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import FadeIn from "components/FadeIn";
import { userContext } from "contexts/User";
import CircularProgress from "@mui/material/CircularProgress";
import ProductCard from "./ProductCard";

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      padding: theme.spacing(2),
    },
    header: {
      marginTop: theme.spacing(16),
    },
    subGrid: {
      padding: theme.spacing(3),
    },
  }),
  { index: 1 }
);

const Catalog: React.FC = () => {
  const { user } = useContext(userContext);
  const styles = useStyles();
  const history = useHistory();
  const { products, fetchProducts } = useContext(productsContext);

  useEffect(() => {
    fetchProducts();
  }, []);

  if (!user) {
    return <CircularProgress />;
  }

  if (!user.is_staff) {
    history.push("/not-found");
  }

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={styles.header} variant="h3">
          Catalog
        </Typography>
      </Grid>
      <Grid
        className={styles.subGrid}
        container
        alignItems="center"
        justify="center"
      >
        <Button
          onClick={() => history.push("/catalog/product")}
          type="submit"
          color="secondary"
          variant="contained"
        >
          New <AddIcon />
        </Button>
      </Grid>
      <Grid container alignItems="center" justify="center">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </Grid>
    </FadeIn>
  );
};

export default Catalog;
