import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  MenuItem,
  TextField,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Address,
  AddressType,
  AddressTypes,
  StateAbreviations,
} from "http/models";
import { addressesContext } from "contexts/Addresses";
import { notificationsContext } from "contexts/Notifications";
import { createAddress } from "http/endpoints";
import { TitleOutlined } from "@material-ui/icons";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      position: "absolute",
      width: 400,
      height: 660,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 8, 3),
      transition: "all 3000ms ease;",
    },
    header: {
      marginBottom: theme.spacing(2),
    },
    grid: {
      padding: theme.spacing(4),
    },
    checkBoxGrid: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
    gridModal: {
      marginTop: theme.spacing(2),
    },
    closeIcon: {
      marginBottom: theme.spacing(-3),
      marginLeft: "250px",
      marginTop: "-70px",
      color: theme.palette.grey[800],
      height: "40px",
      width: "40px",
      opacity: "0.5",
      "&:hover": {
        color: "red",
        boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
        cursor: "pointer",
        borderRadius: "8px",
      },
    },
    scoot: {
      marginTop: theme.spacing(2),
    },
    inputField: {
      width: "60%",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      display: "block",
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    sendBtn: {
      marginTop: theme.spacing(0),
    },
    saveIcon: {
      marginLeft: theme.spacing(1),
    },
    spacer1: {
      width: "100%",
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(2.5),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
  }),
  { index: 1 }
);

interface AddressInputProps {
  title?: string;
  addrType?: AddressType;
  setAddressType: React.Dispatch<React.SetStateAction<AddressType>>;
  allowedToSetAddrType: boolean;
  allowedToSelectExisting: boolean;
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  street: string;
  setStreet: React.Dispatch<React.SetStateAction<string>>;
  street2: string;
  setStreet2: React.Dispatch<React.SetStateAction<string>>;
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  zipCode: string;
  setZipCode: React.Dispatch<React.SetStateAction<string>>;
  existingAddress: Address | null;
  setExistingAddress: React.Dispatch<React.SetStateAction<Address | null>>;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  panel?: string;
  expanded?: boolean;
  setExpanded?: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  addressToCopy?: Address | null;
  setSameAs?: React.Dispatch<React.SetStateAction<Address | null>>;
}

const AddressInput: React.FC<AddressInputProps> = ({
  title,
  addrType,
  setAddressType,
  allowedToSelectExisting,
  allowedToSetAddrType,
  companyName,
  setCompanyName,
  street,
  setStreet,
  street2,
  setStreet2,
  city,
  setCity,
  state,
  setState,
  country,
  setCountry,
  zipCode,
  setZipCode,
  existingAddress,
  setExistingAddress,
  setOpen,
  panel,
  expanded,
  setExpanded,
  addressToCopy,
  setSameAs,
}) => {
  const styles = useStyles();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
  const { addresses, fetchAddresses } = useContext(addressesContext);
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {
    fetchAddresses();
  }, []);

  const handleReset = () => {
    setIsPending(true);
    setCompanyName("");
    setStreet("");
    setStreet2("");
    setCity("");
    setState("");
    setCountry("");
    setZipCode("");
    setChecked(false);
    setIsPending(false);
    setDisableSaveButton(false);
    setExistingAddress(null);
  };

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckSameAsShipping = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!checked && setSameAs && addressToCopy) {
      setExistingAddress(addressToCopy);

      if (addressToCopy.organization) {
        setCompanyName(addressToCopy.organization);
      }
      setStreet(addressToCopy.street[0]);
      if (addressToCopy.street.length === 2) {
        setStreet2(addressToCopy.street[1]);
      }
      setCity(addressToCopy.city);
      setState(addressToCopy.state);
      setCountry(addressToCopy.country);
      setZipCode(addressToCopy.zip_code);
      setDisableSaveButton(true);
      if (setExpanded && panel && expanded !== undefined) {
        setExpanded(panel)(e, false);
      }
      displayNotification(
        "Copied shipping information over to billing section",
        "success"
      );
    } else {
      setCompanyName("");
      setStreet("");
      setStreet2("");
      setCity("");
      setState("");
      setCountry("");
      setZipCode("");
      setExistingAddress(null);
      setDisableSaveButton(false);
    }
    setChecked(!checked);
  };

  const handleAdd = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    const fullStreet = [street];
    if (street2 !== "") {
      fullStreet.push(street2);
    }
    if (!addrType) {
      return;
    }
    createAddress(null, {
      country,
      organization: companyName,
      street: fullStreet,
      city,
      state,
      zip_code: zipCode,
      type: addrType,
    }).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        setTimeout(() => {
          setIsPending(false);
        }, 1000);
      } else {
        setIsPending(false);
        if (setOpen) {
          setOpen(false);
        }
        setExistingAddress(resp.body);
        fetchAddresses();
        if (setExpanded && panel && expanded !== undefined) {
          setExpanded(panel)(e, false);
        }
        setDisableSaveButton(true);
        displayNotification("successfully added address", "success");
      }
    });
  };

  const handleSelectExisting = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const addrStr = e.target.value as unknown as string;
    const addr = JSON.parse(addrStr);
    setExistingAddress(addr);
    if (addr.organization) {
      setCompanyName(addr.organization);
    }
    setStreet(addr.street[0]);
    if (addr.street.length === 2) {
      setStreet2(addr.street[1]);
    }
    setCity(addr.city);
    setState(addr.state);
    setCountry(addr.country);
    setZipCode(addr.zip_code);
    setChecked(false);
    setDisableSaveButton(true);
    if (setExpanded && panel && expanded !== undefined) {
      setExpanded(panel)(e, false);
    }
    displayNotification(
      `filled form using existing ${addrType} address`,
      "success"
    );
  };

  return (
    <form onSubmit={handleAdd}>
      <Grid
        className={styles.scoot}
        container
        alignItems="center"
        justify="center"
      >
        {title ? (
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Typography className={styles.header} variant="h4">
              {title}
            </Typography>
          </Grid>
        ) : null}
        {addrType &&
        addrType.toLowerCase() === AddressType.Billing.toLowerCase() &&
        addressToCopy !== null &&
        setSameAs !== null ? (
          <Grid className={styles.checkBoxGrid} container justify="center">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckSameAsShipping}
                    checked={checked}
                  />
                }
                label="Use same as shipping"
              />
            </FormGroup>
          </Grid>
        ) : null}
        {allowedToSelectExisting && addresses.length > 0 ? (
          <TextField
            onChange={(e) => handleSelectExisting(e)}
            className={styles.inputField}
            placeholder={addrType}
            name="Fill form using saved addresses"
            label="Fill form using saved addresses"
            value={addrType}
            select
            fullWidth
          >
            {addresses
              .filter(
                (a: Address) =>
                  a.type.toLowerCase() === addrType?.toLowerCase() ||
                  a.type.toLowerCase() === AddressType.Both.toLowerCase()
              )
              .map((a: Address) => (
                <MenuItem key={a.id} value={JSON.stringify(a)}>
                  {`
              ${a?.street[0]}
              ${a?.street.length === 2 ? a.street[1] : ""}
              ${a?.city}, ${a?.state}
              ${a?.zip_code}
            `}
                </MenuItem>
              ))}
          </TextField>
        ) : null}
        <TextField
          onChange={(e) => setCompanyName(e.target.value)}
          className={styles.inputField}
          placeholder="Example Company Inc."
          name="company"
          helperText="optional"
          label="Company Name(optional)"
          type="text"
          value={companyName}
          fullWidth
          disabled={disableSaveButton}
        />
        <TextField
          onChange={(e) => setStreet(e.target.value)}
          className={styles.inputField}
          placeholder="1234 Sesame street"
          name="street"
          label="Street"
          type="text"
          value={street}
          fullWidth
          required
          disabled={disableSaveButton}
        />
        <TextField
          onChange={(e) => setStreet2(e.target.value)}
          className={styles.inputField}
          placeholder="suite 200"
          name="room"
          label="Apt/Suite/Room(optional)"
          type="text"
          value={street2}
          fullWidth
          disabled={disableSaveButton}
        />
        <div className={styles.spacer1} />
        <TextField
          onChange={(e) => setCity(e.target.value.trim())}
          style={{
            marginRight: "5px",
            marginLeft: "-10px",
            width: "115px",
          }}
          placeholder="examplesville"
          name="city"
          label="City"
          type="text"
          value={city}
          required
          disabled={disableSaveButton}
        />
        <TextField
          onChange={(e) => setState(e.target.value.trim())}
          style={{
            marginLeft: "5px",
            marginRight: "-10px",
            width: "115px",
          }}
          placeholder="GA"
          name="state"
          label="State"
          value={state}
          select
          required
          disabled={disableSaveButton}
        >
          {StateAbreviations.map((abv) => (
            <MenuItem key={abv} value={abv}>
              {abv}
            </MenuItem>
          ))}
        </TextField>
        <div className={styles.spacer1} />
        <TextField
          onChange={(e) => setCountry(e.target.value)}
          style={{
            marginRight: "5px",
            marginLeft: "-10px",
            width: "115px",
          }}
          placeholder="US"
          name="country"
          label="Country"
          type="text"
          value={country}
          required
          disabled={disableSaveButton}
        />
        <TextField
          onChange={(e) => setZipCode(e.target.value.trim())}
          style={{
            marginLeft: "5px",
            marginRight: "-10px",
            width: "115px",
          }}
          placeholder="12345"
          name="zip"
          label="Zip"
          type="text"
          value={zipCode}
          required
          disabled={disableSaveButton}
        />
      </Grid>
      <Grid
        className={styles.scoot}
        container
        alignItems="center"
        justify="center"
      >
        {allowedToSetAddrType ? (
          <TextField
            onChange={(e) =>
              setAddressType(e.target.value.trim() as AddressType)
            }
            className={styles.inputField}
            placeholder={addrType}
            name="type"
            label="Type"
            value={addrType}
            select
            fullWidth
            required
            disabled={disableSaveButton}
          >
            {AddressTypes.map((addressType: AddressType) => (
              <MenuItem key={addressType} value={addressType}>
                {addressType}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            className={styles.inputField}
            placeholder={addrType}
            name="type"
            label="Type"
            value={addrType}
            select
            fullWidth
            required
            disabled
          >
            <MenuItem key={addrType} value={addrType}>
              {addrType}
            </MenuItem>
          </TextField>
        )}
      </Grid>
      <div className={styles.spacer3} />
      <Grid
        className={styles.scoot}
        container
        alignItems="center"
        justify="center"
      >
        {disableSaveButton ? (
          <Button
            onClick={handleReset}
            className={styles.btn}
            color="secondary"
            variant="contained"
            disabled={isPending}
          >
            {isPending ? "resetting..." : "Reset"}
            {isPending ? "" : <ClearIcon className={styles.saveIcon} />}
          </Button>
        ) : (
          <Button
            className={styles.sendBtn}
            type="submit"
            color="secondary"
            variant="contained"
            disabled={disableSaveButton}
          >
            {isPending ? "saving..." : "Save"}
            {isPending ? "" : <SaveIcon className={styles.saveIcon} />}
          </Button>
        )}
        <div className={styles.spacer4} />
      </Grid>
    </form>
  );
};

export default AddressInput;
