import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@material-ui/core/Typography";
import { ProductVariation } from "http/models";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    editIcon: {
      marginTop: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      margin: theme.spacing(1),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      padding: theme.spacing(1),
      width: theme.spacing(36),
      "&:hover": {
        cursor: "pointer",
        boxShadow: `1px 1px 8px ${theme.palette.grey[800]}`,
      },
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      marginTop: theme.spacing(2),
    },
  }),
  { index: 1 }
);

interface Props {
  variation: ProductVariation;
}

const ProductCard: React.FC<Props> = ({ variation }) => {
  const history = useHistory();
  const styles = useStyles();
  const [show, setShow] = useState<boolean>(true);

  const resolveURL = (url: string | undefined): string => {
    if (!url) {
      return "";
    }
    let imgPath = `/img/product-variations/${url}`;
    if (url.includes("storage.googleapis.com/")) {
      imgPath = `https://${url}`;
    }
    return imgPath;
  };

  const handleClick = () => {
    history.push(`/product/${variation.product_id}`);
  };

  const truncate = (s: string, len: number) =>
    s.length > len ? `${s.substring(0, len)}...` : s;

  return (
    <Card
      variant="outlined"
      className={show ? styles.card : styles.fadeOut}
      onClick={handleClick}
    >
      <CardHeader
        title={
          <Grid direction="row" justify="center" alignItems="center">
            <Typography className={styles.product} variant="h5">
              {truncate(variation.name, 15)}
            </Typography>
          </Grid>
        }
      />
      {variation.images ? (
        <CardMedia
          component="img"
          height="194"
          image={resolveURL(
            variation.images.find((img) => img.is_primary)?.url
          )}
          alt="product image not found"
        />
      ) : null}
      <CardContent>
        <Typography variant="body1">
          {truncate(variation.description, 30)}
        </Typography>
        <Typography variant="h6" align="right" className={styles.price}>
          ${variation.price}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
