import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import FadeIn from "components/FadeIn";
import { notificationsContext } from "contexts/Notifications";
import { createProductReview, listOrders } from "http/endpoints";
import { userContext } from "contexts/User";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Rating from "@mui/material/Rating";
import { Order, ProductVariation } from "http/models";
import ProductVariationImageCard from "pages/Catalog/ProductVariationImageCard";

const useStyles = makeStyles(
  (theme) => ({
    inputField: {
      width: "220px",
    },
    selectInput: {
      width: "220px",
    },
    orderID: {},
    grid: {
      padding: theme.spacing(1),
    },
    btn: {
      marginTop: theme.spacing(-4),
    },
    submitBtn: {
      marginTop: theme.spacing(2),
    },
    saveBtn: {
      marginTop: theme.spacing(1),
    },
    saveIcon: {
      marginLeft: theme.spacing(1),
    },
    header: {
      marginTop: theme.spacing(16),
    },
  }),
  { index: 1 }
);

const AddProductReview: React.FC = () => {
  const { user } = useContext(userContext);
  const styles = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [stars, setStars] = useState<number | null>(1);
  const [review, setReview] = useState<string>("");
  const [orderID, setOrderID] = useState<string>("");
  const [headline, setHeadline] = useState<string>("");
  const [orders, setOrders] = useState<readonly Order[] | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<string>("");
  const [variationId, setVariationId] = useState<string>("");

  useEffect(() => {
    listOrders({ product_id: id }, null).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        return;
      }
      setOrders(resp.body);
    });
  }, []);

  const formatOrder = (o: Order): any => (
    <>
      <Typography variant="subtitle1">Order: {o.id}</Typography>
      <Typography variant="subtitle1">Total Price: ${o.total}</Typography>
      <Typography variant="subtitle1">Total Items: {o.items.length}</Typography>
      <Typography variant="subtitle1">
        Created: {new Date(o.created_at).toLocaleString()}
      </Typography>
    </>
  );

  const formatOrderToString = (o: Order): string => `
    Total($${o.total})
    Items(${o.items.length})
    Created(${new Date(o.created_at).toLocaleString()})
`;

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stars) {
      return;
    }
    setIsPending(true);
    createProductReview(
      { product_id: id },
      {
        headline,
        stars,
        review,
        order_id: orderID,
        variation_id: variationId,
      }
    ).then((resp) => {
      if (resp.error) {
        setTimeout(() => {
          setIsPending(false);
          displayAPIErrorNotification(resp.error);
        }, 500);
      } else {
        setTimeout(() => {
          setIsPending(false);
          history.push(
            `/product/${resp.body?.product_id}/reviews/${resp.body?.id}/images/edit`
          );
          displayNotification("successfully created product review", "success");
        }, 1000);
      }
    });
  };

  if (!user) {
    return <CircularProgress />;
  }

  if (!user.is_staff) {
    history.push("/not-found");
  }

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Typography className={styles.header} variant="h3">
          Add Product Review
        </Typography>
      </Grid>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        {orders && orders?.length > 0 ? (
          <FormControl className={styles.selectInput}>
            <InputLabel id="order-select">Select order</InputLabel>
            <Select
              labelId="order-select"
              id="order-select"
              value={selectedOrder}
              label="Order"
              onChange={(e) => {
                const o = JSON.parse(e.target.value as string) as Order;
                if (o) {
                  setOrderID(o.id);
                  setSelectedOrder(formatOrder(o));
                  if (o.items) {
                    o.items.forEach((currentItem) => {
                      if (currentItem.product_id === id) {
                        setVariationId(currentItem.variation_id);
                      }
                    });
                  }
                }
              }}
            >
              {orders.map((o) => (
                <MenuItem
                  key={o.id}
                  value={JSON.stringify(o)}
                  disabled={!user?.is_staff}
                >
                  {formatOrderToString(o)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </Grid>
      {orderID !== "" ? (
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Typography className={styles.orderID} variant="subtitle1">
            {selectedOrder}
          </Typography>
        </Grid>
      ) : null}
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Box sx={{ "& > legend": { mt: 2 } }}>
          <Typography component="legend">Rating</Typography>
          <Rating
            name="simple-controlled"
            value={stars}
            defaultValue={1}
            size="large"
            onChange={(e, newValue) => {
              setStars(newValue);
            }}
          />
        </Box>
      </Grid>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <form onSubmit={handleSubmit}>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <TextField
              fullWidth
              onChange={(e) => {
                setHeadline(e.target.value);
              }}
              className={styles.inputField}
              name="headline"
              label="Headline"
              type="text"
              value={headline}
              required
            />
          </Grid>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <TextField
              fullWidth
              onChange={(e) => {
                setReview(e.target.value);
              }}
              className={styles.inputField}
              name="review"
              label="Review"
              type="text"
              value={review}
              multiline
              rows={10}
              required
            />
          </Grid>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Grid item>
              {isPending ? (
                <Button
                  className={styles.submitBtn}
                  color="default"
                  variant="contained"
                  disabled={orderID === "" || review === "" || headline === ""}
                >
                  <Typography>Submitting...</Typography>
                  <SaveIcon />
                </Button>
              ) : (
                <Button
                  className={styles.submitBtn}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={orderID === "" || review === "" || headline === ""}
                >
                  <Typography>Submit</Typography> <SaveIcon />
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </FadeIn>
  );
};

export default AddProductReview;
