import React, { createContext, useState } from "react";
import { getUser } from "../http/endpoints";
import { User } from "../http/models";

interface Props {}

interface state {
  user: User | null;
  setUser: () => void;
}

const initialState: state = {
  user: null,
  setUser: () => {},
};

export const userContext = createContext(initialState);

export const UserContextProvider: React.FC<Props> = ({ children }) => {
  const [user, updateUser] = useState<User | null>(null);

  const setUser = () => {
    getUser().then((resp) => {
      if (resp.error) {
        updateUser(null);
      } else {
        updateUser(resp.body);
      }
    });
  };

  return (
    <userContext.Provider value={{ user, setUser }}>
      {children}
    </userContext.Provider>
  );
};
