import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { Address, AddressType } from "http/models";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { DeleteOutlined } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { deleteAddress } from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    btn: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    chip: {
      marginLeft: theme.spacing(1),
    },
    verifiedStatus: {
      height: theme.spacing(4),
      width: theme.spacing(4),
      opacity: "0.7",
      color: "green",
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
    unVerifiedStatus: {
      height: theme.spacing(4),
      width: theme.spacing(4),
      opacity: "0.7",
      color: "red",
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    address: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      margin: theme.spacing(1),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      padding: theme.spacing(1),
      width: theme.spacing(36),
    },
    sendIcon: {
      marginLeft: theme.spacing(1),
    },
    screenLockIcon: {
      marginLeft: theme.spacing(1),
    },
    disableIcon: {
      marginLeft: theme.spacing(1),
    },
    paper: {
      margin: theme.spacing(1),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
  }),
  { index: 1 }
);

interface Props {
  address: Address;
}

const AddressCard: React.FC<Props> = ({ address }) => {
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);
  const styles = useStyles();
  const [show, setShow] = useState<boolean>(true);

  const handleDelete = (id: string) => {
    deleteAddress({ id }).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
      } else {
        setShow(false);
        displayNotification("successfully deleted address", "success");
      }
    });
  };

  const addressID = address?.id as string;

  return (
    <Card variant="outlined" className={show ? styles.card : styles.fadeOut}>
      <CardHeader
        action={
          <Tooltip title="delete" placement="top">
            <IconButton
              className={styles.deleteIcon}
              onClick={() => handleDelete(addressID)}
            >
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        }
        title={
          <Grid direction="row" justify="center" alignItems="center">
            <Typography className={styles.address} variant="subtitle1">
              {address.street[0]}
            </Typography>
            {address.street[1] ? (
              <Typography className={styles.address} variant="subtitle1">
                {address.street[1]}
              </Typography>
            ) : null}
            <Typography className={styles.address} variant="subtitle1">
              {address.city}, {address.state}
            </Typography>
            <Typography className={styles.address} variant="subtitle1">
              {address.country} {address.zip_code}
            </Typography>
            {address.type.toLowerCase() === AddressType.Both.toLowerCase() ? (
              <>
                <Chip label="shipping" variant="outlined" />
                <Chip
                  className={styles.chip}
                  label="billing"
                  variant="outlined"
                />
              </>
            ) : (
              <Chip label={address.type} variant="outlined" />
            )}
          </Grid>
        }
      />
    </Card>
  );
};

export default AddressCard;
