import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import React, { useState, useContext } from "react";
import { deleteProductService, deleteProductVariation } from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProductService, ProductVariation } from "http/models";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    paper: {
      marginTop: theme.spacing(4),
      position: "absolute",
      width: 400,
      height: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 8, 3),
      transition: "all 3000ms ease;",
    },
    grid: {
      padding: theme.spacing(4),
    },
    gridModal: {
      marginTop: theme.spacing(2),
    },
    closeIcon: {
      marginBottom: theme.spacing(-3),
      marginLeft: "250px",
      marginTop: "-70px",
      color: theme.palette.grey[800],
      height: "40px",
      width: "40px",
      opacity: "0.5",
      "&:hover": {
        color: "red",
        boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
        cursor: "pointer",
        borderRadius: "8px",
      },
    },
    scoot: {
      marginTop: theme.spacing(-2),
    },
    inputField: {
      width: "60%",
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(-1),
      display: "block",
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    sendBtn: {
      marginTop: theme.spacing(0),
    },
    sendIcon: {
      marginLeft: theme.spacing(1),
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(3),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
    spacer5: {
      height: theme.spacing(5),
      width: "100%",
    },
    spacer6: {
      height: theme.spacing(6),
      width: "100%",
    },
    spacer7: {
      height: theme.spacing(7),
      width: "100%",
    },
    spacer8: {
      height: theme.spacing(8),
      width: "100%",
    },
    spacer9: {
      height: theme.spacing(9),
      width: "100%",
    },
    spacer10: {
      height: theme.spacing(10),
      width: "100%",
    },
    saveBtn: {
      marginTop: theme.spacing(1),
    },
    saveIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

interface Props {
  open: boolean;
  productVariation: ProductVariation;
  setOpen: (shouldOpen: boolean) => void;
  refresh: () => void;
  closeHandler: (show: boolean) => void;
}

const DeleteProductVariationModal: React.FC<Props> = ({
  open,
  setOpen,
  productVariation,
  refresh,
  closeHandler,
}) => {
  const styles = useStyles();
  const [isPending, setIsPending] = useState<boolean>(false);

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  const modalStyle = (): React.CSSProperties => ({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    textAlign: "center",
    borderRadius: "8px",
    padding: "45px",
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    deleteProductVariation(
      {
        product_id: productVariation.product_id,
        variation_id: productVariation.id,
      },
      null
    ).then((resp) => {
      setIsPending(false);
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        return;
      }
      closeHandler(false);
      refresh();
      displayNotification(
        `successfully deleted ${productVariation.name} variation`,
        "success"
      );
    });
  };

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.paper} style={modalStyle()}>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setOpen(false)}
          />
          <Grid
            className={styles.gridModal}
            container
            alignItems="center"
            justify="center"
          />
          <Typography variant="h4">Confirm Variation Delete</Typography>
        </Grid>
        <Grid
          className={styles.gridModal}
          container
          alignItems="center"
          justify="center"
        >
          <Typography variant="h6">
            Are you sure you want to delete the{" "}
            <strong>{productVariation.name}</strong> variation?
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit} noValidate>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            {isPending ? (
              <Button
                className={styles.saveBtn}
                color="default"
                disabled
                variant="contained"
              >
                deleting...
              </Button>
            ) : (
              <Button type="submit" color="secondary" variant="contained">
                Delete <DeleteIcon />
              </Button>
            )}
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default DeleteProductVariationModal;
