import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ToolBar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import UserAvatar from "components/NavBar/UserAvatar";
import React, { useContext, useEffect, useState } from "react";
import { userContext } from "contexts/User";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import DropdownItem from "components/NavBar/DropDownItem";
import FadeIn from "components/FadeIn";
import { ClickAwayListener } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: "red",
      display: "flex",
      position: "fixed",
      zIndex: 2000,
    },
    nav: {
      position: "fixed",
      zIndex: 2000,
      height: theme.spacing(10),
      backgroundColor: theme.palette.secondary.main,
    },
    title: {
      flexGrow: 1,
      marginTop: theme.spacing(2),
      textDecoration: "none",
      fontSize: theme.spacing(3),
      "&:hover": {
        transition: "all 500ms ease",
        fontSize: theme.spacing(4),
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    dropDown: {
      boxShadow: `1px 2px 4px 2px ${theme.palette.grey[800]}`,
      position: "absolute",
      backgroundColor: theme.palette.primary.main,
      top: theme.spacing(10),
      width: theme.spacing(24),
      borderRadius: "8px",
      transition: "height 500ms ease",
      padding: "1rem",
      marginTop: theme.spacing(-2),
      overflow: "visible",
      zIndex: 9999999,
    },
    hamburger: {
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  }),
  { index: 1 }
);

const NavBar: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const styles = useStyles();
  const history = useHistory();
  const { user } = useContext(userContext);

  useEffect(() => {}, [user]);

  const refresh = () => {
    window.location.assign("/");
  };

  return (
    <div className={styles.root}>
      <AppBar className={styles.nav}>
        <ToolBar>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={styles.hamburger}>
              {open ? (
                <ExpandMoreIcon
                  onClick={() => setOpen(false)}
                  style={{ marginTop: "14px", height: "50px", width: "50px" }}
                />
              ) : (
                <MenuIcon
                  onClick={() => setOpen(true)}
                  style={{ marginTop: "14px", height: "50px", width: "50px" }}
                />
              )}
              {open ? (
                <FadeIn>
                  <div className={styles.dropDown}>
                    <List onClick={() => setOpen(!open)}>
                      <DropdownItem label="Home" link="/" />
                      <DropdownItem label="Products" link="/products" />
                      <DropdownItem label="Contact Us" link="/contact" />
                      <DropdownItem label="About" link="/about" />
                    </List>
                  </div>
                </FadeIn>
              ) : null}
            </div>
          </ClickAwayListener>
          <Typography
            onClick={refresh}
            onKeyDown={refresh}
            className={styles.title}
          >
            Home
          </Typography>
          <Typography
            onClick={() => history.push("/products")}
            onKeyDown={() => history.push("/products")}
            className={styles.title}
          >
            Products
          </Typography>
          <Typography className={styles.title}>Contact Us</Typography>
          <Typography className={styles.title}>About Us</Typography>
          <div style={{ position: "fixed", right: "0" }}>
            <UserAvatar />
          </div>
        </ToolBar>
      </AppBar>
    </div>
  );
};

export default NavBar;
