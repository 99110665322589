import Typography from "@material-ui/core/Typography";
import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    editIcon: {
      marginTop: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      margin: theme.spacing(1),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      padding: theme.spacing(1),
      width: theme.spacing(36),
      "&:hover": {
        cursor: "pointer",
        boxShadow: `1px 1px 8px ${theme.palette.grey[800]}`,
      },
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      marginTop: theme.spacing(2),
    },
    box: {
      height: "60px",
      width: "116px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    btn: {
      margin: theme.spacing(4),
    },
  }),
  { index: 1 }
);

interface Props {
  size: string;
  selected: boolean;
  onClick: () => void;
}

const ProductSizeCard: React.FC<Props> = ({ size, onClick, selected }) => {
  const styles = useStyles();

  return (
    <Button
      onClick={onClick}
      className={styles.btn}
      color={selected ? "secondary" : "primary"}
      size="large"
      variant={selected ? "contained" : "outlined"}
    >
      <Typography variant="h5">{size}</Typography>
    </Button>
  );
};

export default ProductSizeCard;
