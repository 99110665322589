import { APIError } from "./error";

export interface ResponseStatus {
  readonly code: number;
  readonly text?: string;
}

interface APIResponseBase<T> {
  readonly body: T | undefined;
  readonly status: ResponseStatus;
  readonly error: APIError | undefined;
  readonly headers: Headers | undefined;
  readonly requestId: string | undefined;
  readonly wasRedirect: boolean;
}

export type APIResponse<T> = APIResponseSuccess<T> | APIResponseError;

// success

interface APIResponseSuccess<T> extends APIResponseBase<T> {
  readonly body: T;
  readonly status: ResponseStatus;
  readonly headers: Headers;
  readonly error: undefined;
}

export function makeAPIResponseSuccess<T>(
  body: T,
  status: APIResponseSuccess<T>["status"],
  headers: APIResponseSuccess<T>["headers"],
  requestId?: APIResponseSuccess<T>["requestId"],
  wasRedirect: APIResponseSuccess<T>["wasRedirect"] = false
): APIResponseSuccess<T> {
  return {
    status,
    headers,
    body,
    requestId,
    wasRedirect,
    error: undefined,
  };
}

// error

export interface APIResponseError extends APIResponseBase<undefined> {
  readonly body: undefined;
  readonly error: APIError;
}

export function makeAPIResponseError(
  error: APIResponseError["error"] | string,
  status: APIResponseError["status"] = { code: 0 },
  headers?: APIResponseError["headers"],
  requestId?: APIResponseError["requestId"],
  wasRedirect: APIResponseError["wasRedirect"] = false
): APIResponseError {
  return {
    status,
    headers,
    error: typeof error === "string" ? { error: { message: error } } : error,
    requestId,
    wasRedirect,
    body: undefined,
  };
}
