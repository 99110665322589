import Modal from "@material-ui/core/Modal";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import React, { useState, useContext } from "react";
import SendIcon from "@material-ui/icons/Send";
import {
  createPhoneNumber,
  createProductService,
  updateProductService,
} from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import { ProductService } from "http/models";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      position: "absolute",
      width: 400,
      height: 520,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 8, 3),
      transition: "all 3000ms ease;",
    },
    grid: {
      padding: theme.spacing(4),
    },
    gridModal: {
      marginTop: theme.spacing(2),
    },
    closeIcon: {
      marginBottom: theme.spacing(-3),
      marginLeft: "250px",
      marginTop: "-70px",
      color: theme.palette.grey[800],
      height: "40px",
      width: "40px",
      opacity: "0.5",
      "&:hover": {
        color: "red",
        boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
        cursor: "pointer",
        borderRadius: "8px",
      },
    },
    scoot: {
      marginTop: theme.spacing(-2),
    },
    inputField: {
      width: "60%",
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(-1),
      display: "block",
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    sendBtn: {
      marginTop: theme.spacing(0),
    },
    sendIcon: {
      marginLeft: theme.spacing(1),
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(3),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
    spacer5: {
      height: theme.spacing(5),
      width: "100%",
    },
    spacer6: {
      height: theme.spacing(6),
      width: "100%",
    },
    spacer7: {
      height: theme.spacing(7),
      width: "100%",
    },
    spacer8: {
      height: theme.spacing(8),
      width: "100%",
    },
    spacer9: {
      height: theme.spacing(9),
      width: "100%",
    },
    spacer10: {
      height: theme.spacing(10),
      width: "100%",
    },
    saveBtn: {
      marginTop: theme.spacing(1),
    },
    saveIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

interface Props {
  open: boolean;
  productService: ProductService;
  setOpen: (shouldOpen: boolean) => void;
  refresh: () => void;
  closeHandler: (show: boolean) => void;
}

const EditProductServiceModal: React.FC<Props> = ({
  open,
  setOpen,
  productService,
  refresh,
  closeHandler,
}) => {
  const styles = useStyles();
  const { id } = useParams<{ id: string }>();
  const [isPending, setIsPending] = useState<boolean>(false);
  // name
  const [name, setName] = useState<string>("");
  const [hasNameError, setHasNameError] = useState<boolean>(false);
  // description
  const [description, setDescription] = useState<string>("");
  const [hasDescriptionError, setHasDescriptionError] =
    useState<boolean>(false);
  // price(should this be a number type?)
  const [total, setTotal] = useState<string>("");
  const [hasPriceError, setHasPriceError] = useState<boolean>(false);

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  const modalStyle = (): React.CSSProperties => ({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    textAlign: "center",
    borderRadius: "8px",
    padding: "45px",
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    updateProductService(
      {
        product_id: productService.product_id,
        service_id: productService.id,
      },
      {
        name,
        description,
        total,
        enabled: productService.enabled,
      }
    ).then((resp) => {
      setIsPending(false);
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        return;
      }
      closeHandler(false);
      refresh();
      setName("");
      setDescription("");
      setTotal("");
      setTimeout(() => {
        displayNotification(
          `successfully updated ${productService.name} service`,
          "success"
        );
      }, 500);
    });
  };

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.paper} style={modalStyle()}>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setOpen(false)}
          />
          <Grid
            className={styles.gridModal}
            container
            alignItems="center"
            justify="center"
          />
          <Typography variant="h4">Edit Service</Typography>
        </Grid>
        <form onSubmit={handleSubmit} noValidate>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <TextField
              fullWidth
              onChange={(e) => {
                if (hasNameError) {
                  setHasNameError(false);
                }
                setName(e.target.value);
              }}
              className={styles.inputField}
              name="name"
              label="Name"
              type="text"
              value={name}
              placeholder={productService.name}
              error={hasNameError}
              required
            />
          </Grid>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <TextField
              fullWidth
              onChange={(e) => {
                if (hasDescriptionError) {
                  setHasDescriptionError(false);
                }
                setDescription(e.target.value);
              }}
              className={styles.inputField}
              name="description"
              label="Description"
              type="text"
              value={description}
              placeholder={productService.description}
              error={hasDescriptionError}
              required
            />
          </Grid>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <TextField
              fullWidth
              onChange={(e) => {
                if (hasPriceError) {
                  setHasPriceError(false);
                }
                setTotal(e.target.value.trim());
              }}
              className={styles.inputField}
              placeholder={productService.total}
              name="price"
              label="Price"
              type="text"
              value={total}
              error={hasPriceError}
              required
            />
          </Grid>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            {isPending ? (
              <Button
                className={styles.saveBtn}
                color="default"
                disabled
                variant="contained"
              >
                saving...
              </Button>
            ) : (
              <Button
                className={styles.saveBtn}
                type="submit"
                color="secondary"
                variant="contained"
              >
                Save <SaveIcon className={styles.saveIcon} />
              </Button>
            )}
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default EditProductServiceModal;
