import { useHistory, Redirect } from "react-router-dom";
import React, { useContext, useState } from "react";
import { userContext } from "contexts/User";
import { initializePasswordReset } from "http/endpoints";
import {
  Grid,
  Container,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { notificationsContext } from "contexts/Notifications";
import FadeIn from "components/FadeIn";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(17),
      flexGrow: 1,
    },
    form: {
      padding: theme.spacing(7),
    },
    inputField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    sendIcon: {
      marginLeft: theme.spacing(1),
    },
    btn: {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
    },
  }),
  { index: 1 }
);

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isPending, setIsPending] = useState(false);
  const { user } = useContext(userContext);
  const styles = useStyles();
  const { displayNotification } = useContext(notificationsContext);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    initializePasswordReset({ email }).then((resp) => {
      if (!resp.error) {
        displayNotification(
          `successfully sent reset instructions to ${email}`,
          "success"
        );
      }
      setTimeout(() => {
        history.push("/reset-password-session");
      }, 500);
      setTimeout(() => {
        setIsPending(false);
      }, 1000);
    });
  };

  if (user !== null) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.inputField}
          container
          alignItems="center"
          justify="center"
        >
          <form onSubmit={handleSubmit}>
            <Grid
              className={styles.inputField}
              container
              alignItems="center"
              justify="center"
            >
              <Typography variant="h4">Forgot Password</Typography>
            </Grid>
            <FadeIn>
              <Grid item>
                <TextField
                  onChange={(e) => setEmail(e.target.value.trim())}
                  className={styles.inputField}
                  fullWidth
                  label="Email"
                  type="email"
                  required
                >
                  {email}
                </TextField>
              </Grid>
              <Grid
                className={styles.inputField}
                container
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  {isPending ? (
                    <Button color="secondary" disabled variant="contained">
                      Sending...
                    </Button>
                  ) : (
                    <Button color="secondary" type="submit" variant="contained">
                      Send Reset Link <SendIcon className={styles.sendIcon} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </FadeIn>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default ForgotPassword;
