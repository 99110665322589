import { createAPIHandler, RequestQueryIndexed } from "./request";
import {
  User,
  LoginForm,
  RegistrationForm,
  EmailUpdateRequest,
  PasswordUpdateRequest,
  PhoneNumber,
  PhoneNumberParams,
  CreatePhoneNumberRequest,
  PasswordReset,
  GetPasswordResetRequest,
  VerifyEmailRequest,
  VerifyMFARequest,
  ResetPasswordRequest,
  InitializePasswordResetRequest,
  VerifyPhoneNumberRequest,
  Address,
  AddressRequest,
  AddressParams,
  Product,
  ProductRequest,
  ProductParams,
  ProductImageParams,
  CartItem,
  CartItemParams,
  PaymentIntentRequest,
  PaymentIntentResponse,
  PaymentsKeyResponse,
  Order,
  CreateOrderRequest,
  UpdateOrderStatusParams,
  OrderRequest,
  OrderFilter,
  UpdateCartItemRequest,
  CreateCartItemRequest,
  CalculateShippingResponse,
  ProductReview,
  CreateProductReviewRequest,
  ProductReviewParams,
  UpdateProductReviewRequest,
  OrderAuditLog,
  OrderAuditLogFilter,
  UpdateOrderStatusBody,
  ProductServiceParams,
  ProductService,
  CreateProductServiceRequest,
  UpdateProductServiceRequest,
  ProductVariation,
  ProductVariationParams,
  CreateProductVariationRequest,
  ProductVariationImageParams,
} from "./models";

export const register = createAPIHandler<User | null, null, RegistrationForm>({
  method: "POST",
  path: "/sessions/register",
});

export const verifyEmail = createAPIHandler<User | null, VerifyEmailRequest>({
  path: ({ code }) => `/sessions/verify-email?auth-code=${code}`,
});

export const logIn = createAPIHandler<User | null, null, LoginForm>({
  method: "POST",
  path: "/sessions/login",
});

export const logOut = createAPIHandler<null, null>({
  method: "POST",
  path: "/sessions/logout",
});

export const getUser = createAPIHandler<User>({
  path: "/sessions/user",
});

export const updateEmail = createAPIHandler<User | null, EmailUpdateRequest>({
  method: "POST",
  path: ({ new_email }) => `/settings/emails?email=${new_email}`,
});

export const resendEmailVerificationCode = createAPIHandler<null>({
  method: "POST",
  path: "/settings/emails/resend-verification-code",
});

export const updatePassword = createAPIHandler<
  User | null,
  null,
  PasswordUpdateRequest
>({
  method: "POST",
  path: "/settings/passwords",
});

export const getPhoneNumbers = createAPIHandler<PhoneNumber[]>({
  path: "/phone-numbers",
});

export const deletePhoneNumber = createAPIHandler<null, PhoneNumberParams>({
  method: "DELETE",
  path: ({ id }) => `/phone-number?phone-number=${id}`,
});

export const initializeVerifyPhoneNumber = createAPIHandler<
  null,
  PhoneNumberParams
>({
  method: "POST",
  path: ({ id }) => `/phone-number/send-code?phone-number=${id}`,
});

export const createPhoneNumber = createAPIHandler<
  PhoneNumber,
  null,
  CreatePhoneNumberRequest
>({
  method: "POST",
  path: "/phone-numbers",
});

export const enableMultiFactorAuth = createAPIHandler<
  User | null,
  PhoneNumberParams
>({
  method: "POST",
  path: ({ id }) => `/settings/multi-factor-auth/enable?phone-number=${id}`,
});

export const disableMultiFactorAuth = createAPIHandler<
  User | null,
  PhoneNumberParams
>({
  method: "POST",
  path: ({ id }) => `/settings/multi-factor-auth/disable?phone-number=${id}`,
});

export const getPasswordReset = createAPIHandler<
  PasswordReset,
  GetPasswordResetRequest
>({
  path: ({ id }) => `/passwords/validate-reset/${id}`,
});

export const initializePasswordReset = createAPIHandler<
  null,
  InitializePasswordResetRequest
>({
  path: ({ email }) => `/passwords/initialize-reset?email=${email}`,
});

export const resetPassword = createAPIHandler<null, null, ResetPasswordRequest>(
  {
    method: "POST",
    path: "/passwords/reset",
  }
);

export const verifyPhoneNumber = createAPIHandler<
  null,
  VerifyPhoneNumberRequest
>({
  method: "POST",
  path: ({ id, code }) =>
    `/phone-number/verify?phone-number=${id}&auth-code=${code}`,
});

export const verifyMFA = createAPIHandler<User | null, VerifyMFARequest>({
  method: "POST",
  path: ({ code }) => `/sessions/verify-multi-factor-auth?auth-code=${code}`,
});

export const createAddress = createAPIHandler<Address, null, AddressRequest>({
  method: "POST",
  path: "/settings/addresses",
});

export const getAddresses = createAPIHandler<Address[]>({
  path: "/settings/addresses",
});

export const deleteAddress = createAPIHandler<null, AddressParams>({
  method: "DELETE",
  path: ({ id }) => `/settings/addresses/${id}`,
});

export const createProduct = createAPIHandler<Product, null, ProductRequest>({
  method: "POST",
  path: "/products",
});

export const updateProduct = createAPIHandler<
  Product,
  ProductParams,
  ProductRequest
>({
  method: "POST",
  path: ({ id }) => `/products/${id}`,
});

export const getProduct = createAPIHandler<Product, ProductParams>({
  path: ({ id, show_deleted }) =>
    `/products/${id}${show_deleted ? "?show-deleted=true" : ""}`,
});

export const listProducts = createAPIHandler<Product[], null>({
  path: "/products",
});

export const deleteProduct = createAPIHandler<null, ProductParams>({
  method: "DELETE",
  path: ({ id, show_deleted }) =>
    `/products/${id}${show_deleted ? "?show-deleted=true" : ""}`,
});

export const setProductImageAsPrimary = createAPIHandler<
  null,
  ProductImageParams
>({
  method: "PUT",
  path: ({ id }) => `/product-images/${id}`,
});

export const setProductVariationAsPrimary = createAPIHandler<
  null,
  ProductVariationParams
>({
  method: "PUT",
  path: ({ product_id, variation_id }) =>
    `/products/${product_id}/variations/${variation_id}`,
});

export const deleteProductImage = createAPIHandler<null, ProductImageParams>({
  method: "DELETE",
  path: ({ id }) => `/product-images/${id}`,
});

export const createCartItem = createAPIHandler<
  CartItem,
  null,
  CreateCartItemRequest
>({
  method: "POST",
  path: "/cart",
});

export const listCartItems = createAPIHandler<CartItem[], null, null>({
  path: "/cart",
});

export const clearCart = createAPIHandler<null, null, null>({
  method: "DELETE",
  path: "/cart",
});

export const updateCartItem = createAPIHandler<
  CartItem,
  CartItemParams,
  UpdateCartItemRequest
>({
  method: "PATCH",
  path: ({ id }) => `/cart/${id}`,
});

export const getCartItem = createAPIHandler<null, CartItemParams>({
  path: ({ id }) => `/cart/${id}`,
});

export const deleteCartItem = createAPIHandler<null, CartItemParams>({
  method: "DELETE",
  path: ({ id }) => `/cart/${id}`,
});

export const createPaymentIntent = createAPIHandler<
  PaymentIntentResponse,
  null,
  PaymentIntentRequest
>({
  method: "POST",
  path: "/payments/intent",
});

export const getPaymentsKey = createAPIHandler<PaymentsKeyResponse, null, null>(
  {
    path: "/payments/key",
  }
);

export const createOrder = createAPIHandler<Order, null, CreateOrderRequest>({
  method: "POST",
  path: "/orders",
});

export const updateOrderStatus = createAPIHandler<
  Order,
  UpdateOrderStatusParams,
  UpdateOrderStatusBody
>({
  method: "PUT",
  path: ({ id, status, notify }) =>
    `/orders/${id}?status=${status}${notify ? `&notify=${notify}` : ""}`,
});

export const getOrder = createAPIHandler<Order, OrderRequest>({
  path: ({ id }) => `/orders/${id}`,
});

export const calculateShipping = createAPIHandler<
  CalculateShippingResponse,
  null,
  Address
>({
  method: "POST",
  path: "/distance",
});

export const listOrders = createAPIHandler<Order[], OrderFilter>({
  path: "/orders",
  query: (f: OrderFilter): RequestQueryIndexed => ({
    payment_id: f.payment_id,
    product_id: f.product_id,
    max_order_total: f.max_order_total,
    min_order_total: f.min_order_total,
    max_item_count: f.max_item_count,
    min_item_count: f.min_item_count,
    user_id: f.user_id,
    username: f.username,
    status: f.status,
    created_at_start: f.created_at_start,
    created_at_end: f.created_at_end,
    updated_at_start: f.updated_at_start,
    updated_at_end: f.updated_at_end,
  }),
});

export const listOrderAuditLogs = createAPIHandler<
  OrderAuditLog[],
  OrderAuditLogFilter
>({
  path: "/order-logs",
  query: (f: OrderAuditLogFilter): RequestQueryIndexed => ({
    order_id: f.order_id,
    status: f.status,
    updated_at_start: f.updated_at_start,
    updated_at_end: f.updated_at_end,
  }),
});

export const createProductReview = createAPIHandler<
  ProductReview,
  ProductReviewParams,
  CreateProductReviewRequest
>({
  method: "POST",
  path: ({ product_id }) => `/products/${product_id}/reviews`,
});

export const updateProductReview = createAPIHandler<
  ProductReview,
  ProductReviewParams,
  UpdateProductReviewRequest
>({
  method: "POST",
  path: ({ product_id, review_id }) =>
    `/products/${product_id}/reviews/${review_id}`,
});

export const getProductReview = createAPIHandler<
  ProductReview,
  ProductReviewParams
>({
  path: ({ product_id, review_id }) =>
    `/products/${product_id}/reviews/${review_id}`,
});

export const listProductReviews = createAPIHandler<
  ProductReview[],
  ProductReviewParams,
  null
>({
  path: ({ product_id }) => `/products/${product_id}/reviews`,
});

export const listUserProductReviews = createAPIHandler<ProductReview[]>({
  path: () => "/product-reviews",
});

export const deleteProductReview = createAPIHandler<null, ProductReviewParams>({
  method: "DELETE",
  path: ({ product_id, review_id }) =>
    `/products/${product_id}/reviews/${review_id}`,
});

export const setProductReviewImageAsPrimary = createAPIHandler<
  null,
  ProductReviewParams
>({
  method: "POST",
  path: ({ product_id, review_id, img_id }) =>
    `/products/${product_id}/reviews/${review_id}/images/${img_id}`,
});

export const deleteProductReviewImage = createAPIHandler<
  null,
  ProductReviewParams
>({
  method: "DELETE",
  path: ({ product_id, review_id, img_id }) =>
    `/products/${product_id}/reviews/${review_id}/images/${img_id}`,
});

export const createProductService = createAPIHandler<
  ProductService,
  ProductServiceParams,
  CreateProductServiceRequest
>({
  method: "POST",
  path: ({ product_id }) => `/products/${product_id}/services`,
});

export const updateProductService = createAPIHandler<
  ProductService,
  ProductServiceParams,
  UpdateProductServiceRequest
>({
  method: "PATCH",
  path: ({ product_id, service_id }) =>
    `/products/${product_id}/services/${service_id}`,
});

export const listProductServices = createAPIHandler<
  ProductService[],
  ProductServiceParams,
  null
>({
  path: ({ product_id }) => `/products/${product_id}/services`,
});

export const deleteProductService = createAPIHandler<
  null,
  ProductServiceParams
>({
  method: "DELETE",
  path: ({ product_id, service_id }) =>
    `/products/${product_id}/services/${service_id}`,
});

export const listProductVariations = createAPIHandler<
  ProductVariation[],
  ProductVariationParams,
  null
>({
  path: ({ product_id, variation_id }) =>
    `/products/${product_id}/variations/${variation_id}`,
});

export const createProductVariation = createAPIHandler<
  ProductVariation,
  ProductVariationParams,
  CreateProductVariationRequest
>({
  method: "POST",
  path: ({ product_id }) => `/products/${product_id}/variations`,
});

export const updateProductVariation = createAPIHandler<
  ProductVariation,
  ProductVariationParams,
  CreateProductVariationRequest
>({
  method: "PATCH",
  path: ({ product_id, variation_id }) =>
    `/products/${product_id}/variations/${variation_id}`,
});

export const deleteProductVariation = createAPIHandler<
  null,
  ProductVariationParams
>({
  method: "DELETE",
  path: ({ product_id, variation_id }) =>
    `/products/${product_id}/variations/${variation_id}`,
});

export const setProductVariationImageAsPrimary = createAPIHandler<
  null,
  ProductVariationImageParams
>({
  method: "POST",
  path: ({ product_id, variation_id, img_id }) =>
    `/products/${product_id}/variations/${variation_id}/images/${img_id}`,
});

export const deleteProductVariationImage = createAPIHandler<
  null,
  ProductVariationImageParams
>({
  method: "DELETE",
  path: ({ product_id, variation_id, img_id }) =>
    `/products/${product_id}/variations/${variation_id}/images/${img_id}`,
});
