import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { notificationsContext } from "contexts/Notifications";
import { Button, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FadeIn from "components/FadeIn";
import {
  Product,
  ProductColor,
  ProductReview,
  ProductService,
  ProductSize,
  ProductVariation,
} from "http/models";
import {
  getProduct,
  createCartItem,
  listProductReviews,
  listOrders,
} from "http/endpoints";
import { userContext } from "contexts/User";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "pages/Products/Slider";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
import ManageCart from "@material-ui/icons/ShoppingCart";
import { cartContext } from "contexts/Cart";
import ReviewCard from "pages/Reviews/ReviewCard";
import ProductServiceCard from "pages/Products/ProductServiceCard";
import ProductColorCard from "./ProductColorCard";
import ProductSizeCard from "./ProductSizeCard";

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      marginTop: theme.spacing(8),
      padding: theme.spacing(1),
    },
    reviewsGrid: {
      marginTop: theme.spacing(-8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(1),
    },
    checkBoxGrid: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
    header: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
      textAlign: "center",
    },
    reviewsHeader: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      textAlign: "center",
    },
    text: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    description: {
      textAlign: "center",
    },
    inputField: {
      marginTop: theme.spacing(3),
      width: "300px",
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    btn: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(16),
    },
    addReviewBtn: {
      marginTop: theme.spacing(-8),
      marginBottom: theme.spacing(6),
    },
    variations: {
      marginBottom: theme.spacing(1),
    },
    colorsHeader: {
      marginTop: theme.spacing(5),
    },
    sizesHeader: {
      marginTop: theme.spacing(5),
    },
    variationsHeader: {
      marginTop: theme.spacing(18),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(8),
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
      },
    },
    slider: {
      marginTop: theme.spacing(-8),
    },
    subHeader: {
      marginTop: theme.spacing(-4),
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(8),
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(0),
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(10),
      },
    },
  }),
  { index: 1 }
);

const ProductDetail: React.FC = () => {
  const { user } = useContext(userContext);
  const { fetchCartItems, existsInCart } = useContext(cartContext);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const styles = useStyles();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [purchased, setPurchased] = useState<boolean>(false);
  const [reviewed, setReviewed] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<
    ProductService[] | null
  >(null);
  const [colors, setColors] = useState<ProductColor[] | null>(null);
  const [sizes, setSizes] = useState<string[] | null>(null);
  const [selectedSize, setSelectedSize] = useState<string>("");

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  const [product, setProduct] = useState<Product | null>(null);
  const [variation, setVariation] = useState<ProductVariation | null>(null);
  const [reviews, setReviews] = useState<ProductReview[] | null>(null);

  useEffect(() => {
    console.log("reached use effect");
    getProduct({ id }).then((resp) => {
      fetchCartItems();
      if (resp.status.code !== 200 || !resp.body) {
        displayNotification("product not found", "error");
        history.push("/catalog");
        return;
      }
      const p = resp.body;
      setProduct(p);

      const filtered = p.variations.filter(
        (currenVariation) => currenVariation.is_primary
      );
      const v = filtered[0];
      setVariation(v);

      if (v.sizes && v.sizes.length > 0) {
        setSizes(v.sizes);
      }

      const allColors: ProductColor[] = [];
      p.variations.forEach((currentVariation) => {
        if (currentVariation.color) {
          allColors.push({
            product_id: currentVariation.product_id,
            variation_id: currentVariation.id,
            name: currentVariation.color.name,
            hex: currentVariation.color.hex,
          });
        }
      });

      if (allColors.length > 0) {
        setColors(allColors);
      }

      listProductReviews({ product_id: p.id }, null).then((r) => {
        if (r.error) {
          displayAPIErrorNotification(r.error);
          return;
        }

        if (r.body) {
          const responseReviews = [...r.body];
          setReviews(responseReviews);
          if (user) {
            const userReviews = responseReviews.filter(
              (review) => review.created_by_user_id === user.id
            );
            setReviewed(userReviews.length > 0);
          }
        }
      });
    });
  }, []);

  const handleAddToCart = () => {
    if (!user) {
      history.push("/log-in");
      return;
    }
    if (!product) {
      return;
    }
    if (!variation) {
      return;
    }
    setIsPending(true);
    createCartItem(null, {
      product_id: product.id,
      variation_id: variation.id,
      additional_services: selectedServices,
      size: selectedSize === "" ? null : selectedSize,
    }).then((resp) => {
      if (resp.error) {
        setTimeout(() => {
          setIsPending(false);
          displayAPIErrorNotification(resp.error);
        }, 500);
      } else {
        setTimeout(() => {
          fetchCartItems();
          setIsPending(false);
          displayNotification("successfully added product to cart", "success");
        }, 1000);
      }
    });
  };

  const handleColorClick = (variationId: string) => {
    product?.variations.forEach((currentVariation) => {
      if (currentVariation.id === variationId) {
        setVariation(currentVariation);
        if (currentVariation.sizes) {
          setSelectedSize("");
          setSizes(currentVariation.sizes);
        }
      }
    });
  };

  const handleManageCart = () => {
    if (!user) {
      history.push("/log-in");
      return;
    }
    history.push("/cart");
  };

  if (!product) {
    return <CircularProgress />;
  }

  if (user && product) {
    listOrders({
      product_id: product.id,
      user_id: user.id,
    }).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        return;
      }
      if (resp.body && resp.body.length > 0) {
        setPurchased(true);
      }
    });
  }

  return (
    variation && (
      <FadeIn>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <Typography className={styles.header} variant="h3">
              {variation?.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center">
          <Grid xs={12} xl={6} alignItems="center" justify="center">
            <Slider variation={variation as ProductVariation} />
          </Grid>

          <Grid xs={12} xl={6} alignItems="center" justify="center">
            {existsInCart(product.id) ? (
              <Grid alignItems="center" justify="center">
                <Typography className={styles.text} variant="body1">
                  This item is already in your cart
                </Typography>
              </Grid>
            ) : null}

            {colors && colors.length > 0 ? (
              <Grid
                className={styles.colorsHeader}
                container
                alignItems="center"
                justify="center"
              >
                <Typography className={styles.text} variant="h4">
                  Colors
                </Typography>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={8}
                >
                  {colors.map((c) => (
                    <ProductColorCard
                      onClick={() => handleColorClick(c.variation_id as string)}
                      color={c}
                    />
                  ))}
                </Grid>
              </Grid>
            ) : null}

            {sizes && sizes.length > 0 ? (
              <Grid
                className={styles.sizesHeader}
                container
                alignItems="center"
                justify="center"
              >
                <Typography className={styles.text} variant="h4">
                  Sizes
                </Typography>
                <Grid
                  container
                  className={styles.variations}
                  justify="center"
                  alignItems="center"
                  spacing={8}
                >
                  {sizes.map((s) => (
                    <ProductSizeCard
                      selected={s === selectedSize}
                      onClick={() => {
                        setSelectedSize(s);
                      }}
                      size={s}
                    />
                  ))}
                </Grid>
              </Grid>
            ) : null}

            {product && product.services && product.services.length > 0 ? (
              <Grid container alignItems="center" justify="center">
                <Typography className={styles.text} variant="h4">
                  Additional Services
                </Typography>
              </Grid>
            ) : null}

            {product && product.services && product.services.length
              ? product.services.map((service) => (
                  <ProductServiceCard
                    key={service.id}
                    inCart={existsInCart(product.id)}
                    productService={service}
                    appendToSelectedServices={() => {
                      let services: ProductService[] | null;
                      if (selectedServices) {
                        services = [...selectedServices, service];
                      } else {
                        services = [service];
                      }
                      setSelectedServices(services);
                    }}
                    removeFromSelectedServices={() => {
                      if (selectedServices) {
                        const services = selectedServices.filter(
                          (s) => s.id === service.id
                        );
                        setSelectedServices(services);
                      }
                    }}
                  />
                ))
              : null}

            <Grid item xs={12}>
              <Typography className={styles.text} variant="h4">
                ${variation?.price}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className={styles.description} variant="h4">
                {variation?.description}
              </Typography>
            </Grid>

            <Grid container alignItems="center" justify="center">
              {isPending ? (
                <Button
                  className={styles.btn}
                  type="button"
                  color="default"
                  disabled
                  variant="contained"
                >
                  adding...
                </Button>
              ) : (
                <Button
                  className={styles.btn}
                  onClick={
                    existsInCart(product.id)
                      ? handleManageCart
                      : handleAddToCart
                  }
                  type="button"
                  color="secondary"
                  variant="contained"
                >
                  {existsInCart(product.id) ? "Manage Cart" : "Add To Cart"}
                  {existsInCart(product.id) ? (
                    <ManageCart className={styles.icon} />
                  ) : (
                    <AddShoppingCart className={styles.icon} />
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={styles.reviewsGrid}
          container
          alignItems="center"
          justify="center"
        >
          <Typography className={styles.reviewsHeader} variant="h3">
            Reviews({reviews ? reviews.length : 0})
          </Typography>
        </Grid>
        {purchased && !reviewed ? (
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Button
              className={styles.addReviewBtn}
              onClick={(e) => {
                history.push(`/product/${product.id}/reviews`);
              }}
              type="button"
              color="secondary"
              variant="contained"
            >
              {" "}
              Add Your Review
            </Button>
          </Grid>
        ) : null}
        {reviews ? (
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            {reviews.map((r) => (
              <ReviewCard
                key={r.id}
                review={r}
                allowRedirectToProductPage={false}
              />
            ))}
          </Grid>
        ) : null}
      </FadeIn>
    )
  );
};

export default ProductDetail;
