import Typography from "@material-ui/core/Typography";
import { Product, CartItem as Item } from "http/models";
import { Grid, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import { getProduct } from "http/endpoints";
import CircularProgress from "@mui/material/CircularProgress";
import CartItemImage from "pages/Cart/CartItemImage";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    cost: {
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(8),
      },
    },
    editIcon: {
      marginTop: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      marginTop: theme.spacing(2),
    },
    item: {
      borderRadius: "4px",
      margin: "0 auto",
      padding: theme.spacing(1),
      width: "90%",
      textAlign: "center",
      boxShadow: `1px 1px 8px ${theme.palette.grey[800]}`,
    },
    header: {
      margin: theme.spacing(2),
    },
    value: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(12),
      },
    },
    addIcon: {
      marginLeft: theme.spacing(3),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      borderRadius: "4px",
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
    removeIcon: {
      marginRight: theme.spacing(3),
      borderRadius: "4px",
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
  }),
  { index: 1 }
);

interface Props {
  item: Item;
}

const ItemCard: React.FC<Props> = ({ item }) => {
  const styles = useStyles();
  const [show, setShow] = useState<boolean>(true);
  const [product, setProduct] = useState<Product | null>(null);
  const [additionalServicesTotal, setAdditionalServicesTotal] =
    React.useState<string>("");

  useEffect(() => {
    getProduct({ id: item.product_id, show_deleted: true }).then((resp) => {
      if (resp.status.code !== 200 || !resp.body) {
        return;
      }
      setProduct(resp.body);
      calculateAdditionalServices();
    });
  }, []);

  const toPrice = (priceString: string): number => {
    let price: number;
    if (priceString.includes(".")) {
      const f = parseFloat(priceString);
      price = Number(f.toFixed(2));
    } else {
      price = parseInt(priceString, 10);
    }
    return price;
  };

  const calculateItemTotal = (cartItem: Item): number => {
    let price = toPrice(cartItem.price) * cartItem.quantity;

    if (cartItem.additional_services?.length) {
      for (let i = 0; i < cartItem.additional_services.length; i += 1) {
        price += toPrice(cartItem.additional_services[i].total);
      }
    }
    const itemTotal = Number(price.toFixed(2));
    return itemTotal;
  };

  const calculateAdditionalServices = () => {
    let additionalServicesTotalCost: number = 0;

    if (item.additional_services?.length) {
      for (let i = 0; i < item.additional_services.length; i += 1) {
        additionalServicesTotalCost += toPrice(
          item.additional_services[i].total
        );
      }
    }
    const f = parseFloat(additionalServicesTotalCost.toString());
    const result = f.toFixed(2);
    setAdditionalServicesTotal(result);
  };

  if (!product) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ flexGrow: 1 }} className={show ? styles.card : styles.fadeOut}>
      <Grid container className={styles.item}>
        <Grid item xs={12} md={4}>
          <Typography className={styles.header} variant="h4">
            Item
          </Typography>
          <Typography variant="h6">{item.product_name}</Typography>
          <CartItemImage src={item.product_image} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className={styles.header} variant="h4">
            Quantity
          </Typography>
          <Typography className={styles.value} variant="h4">
            {item.quantity}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className={styles.header} variant="h4">
            Price
          </Typography>
          <Typography className={styles.cost} variant="subtitle1">
            Cost(${item.price})
          </Typography>
          <Typography>
            {item.additional_services?.length
              ? `+ Additional Services($${additionalServicesTotal})`
              : ""}
          </Typography>
          <Typography>x Quantity({item.quantity})</Typography>
          <Typography>
            <strong>Item Total: ${calculateItemTotal(item).toFixed(2)}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ItemCard;
