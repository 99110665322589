import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Order, OrderStatus, OrderStatuses } from "http/models";
import { userContext } from "contexts/User";
import ConfirmOrderStatusUpdateModal from "./ConfirmOrderStatusUpdateModal";

interface Props {
  order: Order;
}

const OrderStatusUpdater: React.FC<Props> = ({ order }) => {
  const { user } = useContext(userContext);
  const [status, setStatus] = useState<string>("");
  const [newStatus, setNewStatus] = useState<string>("");
  const [
    shouldOpenConfirmOrderUpdateModal,
    setShouldOpenConfirmOrderUpdateModal,
  ] = useState<boolean>(false);

  useEffect(() => {
    setStatus(order.status);
  }, []);

  const openConfirmPrompt = (event: SelectChangeEvent) => {
    setNewStatus(event.target.value);
    setShouldOpenConfirmOrderUpdateModal(true);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="status-select">Status</InputLabel>
        <Select
          labelId="status-select"
          id="status-select"
          value={status}
          label="Status"
          onChange={openConfirmPrompt}
        >
          {OrderStatuses.map((orderStatus) => (
            <MenuItem
              key={orderStatus}
              value={orderStatus}
              disabled={!user?.is_staff}
            >
              {orderStatus}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ConfirmOrderStatusUpdateModal
        open={shouldOpenConfirmOrderUpdateModal}
        closeHandler={() => setShouldOpenConfirmOrderUpdateModal(false)}
        order={order}
        newStatus={newStatus as OrderStatus}
        ogStatus={order.status as OrderStatus}
        setStatus={setStatus}
      />
    </Box>
  );
};

export default OrderStatusUpdater;
