import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ProductService } from "http/models";
import { Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React, { useContext, useEffect, useRef } from "react";
import Switch from "@mui/material/Switch";
import { updateProductService } from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditProductServiceModal from "./EditProductServiceModal";
import DeleteProductServiceModal from "./DeleteProductServiceModal";

const label = { inputProps: { "aria-label": "Switch demo" } };

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    accordion: {
      width: theme.spacing(108),
      margin: theme.spacing(2),
    },
    cost: {
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    editIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "black",
      },
    },
    product: {
      marginTop: theme.spacing(-1),
      width: theme.spacing(24),
    },
    card: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
    price: {
      marginTop: theme.spacing(2),
    },
    item: {
      borderRadius: "4px",
      margin: "0 auto",
      padding: theme.spacing(1),
      width: "90%",
      textAlign: "center",
      boxShadow: `1px 1px 8px ${theme.palette.grey[800]}`,
    },
    header: {
      padding: theme.spacing(2),
    },
    editAction: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(-4),
    },
    deleteAction: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(0),
    },
    value: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(12),
      },
    },
    addIcon: {
      marginLeft: theme.spacing(3),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      borderRadius: "4px",
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
    removeIcon: {
      marginRight: theme.spacing(3),
      borderRadius: "4px",
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        fontSize: theme.spacing(4),
        cursor: "pointer",
      },
    },
  }),
  { index: 1 }
);

interface Props {
  productService: ProductService;
  refresh: () => void;
}

const ProductServiceCard: React.FC<Props> = ({ productService, refresh }) => {
  const styles = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [openEditServiceModal, setOpenEditServiceModal] =
    React.useState<boolean>(false);
  const [openDeleteServiceModal, setOpenDeleteServiceModal] =
    React.useState<boolean>(false);

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  useEffect(() => {}, [productService]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const toggleProductService = () => {
    updateProductService(
      {
        product_id: productService.product_id,
        service_id: productService.id,
      },
      {
        name: productService.name,
        description: productService.description,
        enabled: !productService.enabled,
      }
    ).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
        return;
      }

      let status = "disabled";
      if (resp.body.enabled) {
        status = "enabled";
      }
      displayNotification(
        `${status} ${productService.name} service`,
        "success"
      );
      refresh();
    });
  };

  const truncate = (s: string, len: number) =>
    s.length > len ? `${s.substring(0, len)}...` : s;

  return (
    <>
      <Accordion
        className={styles.accordion}
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large" />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Grid item xs={12} md={4}>
            <Typography className={styles.header} variant="h6">
              Name
            </Typography>
            <Typography className={styles.cost} variant="subtitle1">
              {truncate(productService.name, 20)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={styles.header} variant="h6">
              Price
            </Typography>
            <Typography className={styles.cost} variant="subtitle1">
              ${productService.total}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={styles.header} variant="h6">
              Enabled
            </Typography>
            <Typography className={styles.cost} variant="subtitle1">
              <Switch
                {...label}
                onChange={() => {
                  toggleProductService();
                  setExpanded(false);
                }}
                checked={productService.enabled}
              />
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={4}>
              <Typography className={styles.header} variant="h6">
                Description
              </Typography>
              <Typography className={styles.cost} variant="subtitle1">
                {productService.description}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={styles.editAction} variant="h6">
                Edit
              </Typography>
              <Tooltip title="edit product service" placement="top">
                <IconButton
                  className={styles.editIcon}
                  onClick={() => setOpenEditServiceModal(true)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Typography className={styles.deleteAction} variant="h6">
                Delete
              </Typography>
              <Tooltip title="delete product service" placement="top">
                <IconButton
                  className={styles.deleteIcon}
                  onClick={() => setOpenDeleteServiceModal(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <EditProductServiceModal
        open={openEditServiceModal}
        setOpen={setOpenEditServiceModal}
        productService={productService}
        refresh={refresh}
        closeHandler={() => setOpenEditServiceModal(false)}
      />
      <DeleteProductServiceModal
        open={openDeleteServiceModal}
        setOpen={setOpenDeleteServiceModal}
        productService={productService}
        refresh={refresh}
        closeHandler={() => setOpenDeleteServiceModal(false)}
      />
    </>
  );
};

export default ProductServiceCard;
