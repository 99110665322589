import React from "react";
import Router from "Router";
import { UserContextProvider } from "contexts/User";
import { PhoneNumbersContextProvider } from "contexts/PhoneNumbers";
import { NotificationsContextProvider } from "contexts/Notifications";
import { AddressesContextProvider } from "contexts/Addresses";
import { DisplayNotification } from "components/Notification";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import { ProductsContextProvider } from "contexts/Products";
import { CartContextProvider } from "contexts/Cart";
import { ProductsReviewsContextProvider } from "contexts/ProductReviews";

const customTheme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: {
      main: "#000000",
      dark: blueGrey[600],
    },
  },
});

const App: React.FC = () => (
  <ThemeProvider theme={customTheme}>
    <UserContextProvider>
      <NotificationsContextProvider>
        <PhoneNumbersContextProvider>
          <AddressesContextProvider>
            <ProductsContextProvider>
              <CartContextProvider>
                <ProductsReviewsContextProvider>
                  <DisplayNotification />
                  <Router />
                </ProductsReviewsContextProvider>
              </CartContextProvider>
            </ProductsContextProvider>
          </AddressesContextProvider>
        </PhoneNumbersContextProvider>
      </NotificationsContextProvider>
    </UserContextProvider>
  </ThemeProvider>
);

export default App;
