import Modal from "@material-ui/core/Modal";
import { MenuItem, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import React, { useState, useContext } from "react";
import SaveIcon from "@material-ui/icons/Save";
import { createAddress } from "http/endpoints";
import { notificationsContext } from "contexts/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import { addressesContext } from "contexts/Addresses";
import { Address, AddressType } from "http/models";
import AddressInput from "components/AddressInput";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(4),
      position: "absolute",
      width: 400,
      height: 660,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 8, 3),
      transition: "all 3000ms ease;",
    },
    grid: {
      padding: theme.spacing(4),
    },
    gridModal: {
      marginTop: theme.spacing(2),
    },
    closeIcon: {
      marginBottom: theme.spacing(-3),
      marginLeft: "250px",
      marginTop: "-70px",
      color: theme.palette.grey[800],
      height: "40px",
      width: "40px",
      opacity: "0.5",
      "&:hover": {
        color: "red",
        boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
        cursor: "pointer",
        borderRadius: "8px",
      },
    },
    scoot: {
      marginTop: theme.spacing(-2),
    },
    inputField: {
      width: "60%",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      display: "block",
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    sendBtn: {
      marginTop: theme.spacing(0),
    },
    saveIcon: {
      marginLeft: theme.spacing(1),
    },
    spacer1: {
      width: "100%",
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(2.5),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
  }),
  { index: 1 }
);

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  newAddress: string;
  setNewAddressHandler: React.Dispatch<React.SetStateAction<string>>;
  closeHandler: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddAddressModal: React.FC<Props> = ({ open, setOpen, closeHandler }) => {
  const styles = useStyles();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [street2, setStreet2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [type, setType] = useState<AddressType>(AddressType.Both);
  const [address, setAddress] = useState<Address | null>(null);
  const { fetchAddresses } = useContext(addressesContext);
  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  const modalStyle = (): React.CSSProperties => ({
    top: `60%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    textAlign: "center",
    borderRadius: "8px",
    padding: "45px",
  });

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.paper} style={modalStyle()}>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setOpen(false)}
          />
          <Grid
            className={styles.gridModal}
            container
            alignItems="center"
            justify="center"
          />
          <Typography variant="h4">Add New Address</Typography>
        </Grid>
        <AddressInput
          addrType={type}
          setAddressType={setType}
          allowedToSelectExisting={false}
          allowedToSetAddrType
          companyName={companyName}
          setCompanyName={setCompanyName}
          street={street}
          setStreet={setStreet}
          street2={street2}
          setStreet2={setStreet2}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          country={country}
          setCountry={setCountry}
          zipCode={zipCode}
          setZipCode={setZipCode}
          existingAddress={address}
          setExistingAddress={setAddress}
          setOpen={setOpen}
        />
      </div>
    </Modal>
  );
};

export default AddAddressModal;
