import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FadeIn from "components/FadeIn";
import { notificationsContext } from "contexts/Notifications";
import {
  createProduct,
  createProductVariation,
  getProduct,
} from "http/endpoints";
import { userContext } from "contexts/User";
import CircularProgress from "@mui/material/CircularProgress";
import { Product } from "http/models";
import AddIcon from "@material-ui/icons/Add";
import ProductVariationCard from "pages/Catalog/ProductVariationCard";
import ProductServiceCard from "./ProductServiceCard";
import AddProductServiceModal from "./AddProductServiceModal";

const useStyles = makeStyles(
  (theme) => ({
    inputField: {
      width: "220px",
    },
    paper: {
      padding: theme.spacing(3),
    },
    grid: {
      padding: theme.spacing(1),
    },
    btn: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    addProductVariationBtn: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    addServiceBtn: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(16),
    },
    header: {
      marginTop: theme.spacing(20),
    },
    subHeader: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    secondaryHeader: {
      marginTop: theme.spacing(3),
    },
    uploadBtn: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    btnIcon: {
      marginLeft: theme.spacing(1),
    },
    accordion: {
      margin: theme.spacing(1),
    },
    accordionSummary: {
      marginLeft: theme.spacing(2),
    },
    addIcon: {
      marginLeft: theme.spacing(1),
    },
    noneAdded: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    noneAddedVariations: {
      marginTop: theme.spacing(4),
    },
    serviceCard: {
      margin: theme.spacing(4),
    },
  }),
  { index: 1 }
);

const AddProduct: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useContext(userContext);
  const styles = useStyles();
  const history = useHistory();
  const [product, setProduct] = useState<Product | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showAddProductServiceModal, setShowAddProductServiceModal] =
    useState<boolean>(false);
  const [showAddProductVariationModal, setShowAddProductVariationModal] =
    useState<boolean>(false);

  const refreshProduct = (pid: string) => {
    getProduct({ id: pid }).then((resp) => {
      if (resp.status.code !== 200) {
        history.push("/not-found");
        return;
      }
      const p = resp.body as Product;
      setProduct(p);
    });
  };

  useEffect(() => {
    if (id) {
      refreshProduct(id);
      return;
    }

    if (product) {
      refreshProduct(product.id);
      return;
    }

    if (!product) {
      createProduct(null, {}).then((resp) => {
        const p = resp.body as Product;
        setProduct(p);
        const newPath = `${window.location.origin}${window.location.pathname}/${p.id}`;
        window.history.pushState("object or string", "Title", newPath);
        createNewVariation(p);
      });
    }
  }, [refresh]);

  const createNewVariation = (p: Product) => {
    createProductVariation(
      { product_id: p.id },
      {
        name: `variation ${p.variations ? p.variations.length + 1 : "1"}`,
        description: "update product description here",
        price: "0",
      }
    ).then((resp) => {
      if (resp.error) {
        displayAPIErrorNotification(resp.error);
      } else {
        setRefresh(!refresh);
      }
    });
  };

  const handleAddProductVariation = () => {
    if (product) {
      createNewVariation(product);
      displayNotification("successfully created product variation", "success");
    }
  };

  const { displayNotification, displayAPIErrorNotification } =
    useContext(notificationsContext);

  if (!user) {
    return <CircularProgress />;
  }

  if (!user.is_staff) {
    history.push("/not-found");
  }

  return (
    <FadeIn>
      <Grid
        className={styles.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Grid container alignItems="center" justify="center">
          <Typography className={styles.header} variant="h2">
            {product ? "Edit" : "Add"} Product
          </Typography>
        </Grid>

        <Grid container alignItems="center" justify="center">
          <Typography variant="h3" className={styles.subHeader}>
            Variations (
            {product && product.variations ? product.variations.length : 0})
          </Typography>
        </Grid>

        {product && product.variations ? (
          product.variations.map((v) => (
            <Grid container alignItems="center" justify="center">
              <ProductVariationCard
                key={v.id}
                productVariation={v}
                refresh={() => setRefresh(!refresh)}
              />
            </Grid>
          ))
        ) : (
          <Typography className={styles.noneAdded} variant="h4">
            No product variations added yet
          </Typography>
        )}

        <Grid container alignItems="center" justify="center">
          <Button
            className={styles.addProductVariationBtn}
            onClick={() => handleAddProductVariation()}
            color="secondary"
            variant="contained"
          >
            Add New <AddIcon className={styles.addIcon} />
          </Button>
        </Grid>

        <Grid container alignItems="center" justify="center">
          <Typography variant="h3" className={styles.subHeader}>
            Services (
            {product && product.services ? product.services.length : 0})
          </Typography>
        </Grid>

        {product && product.services ? (
          product.services.map((service, i) => (
            <Grid container alignItems="center" justify="center">
              <ProductServiceCard
                key={service.id}
                refresh={() => setRefresh(!refresh)}
                productService={service}
              />
            </Grid>
          ))
        ) : (
          <Typography className={styles.noneAdded} variant="h4">
            No product services added yet
          </Typography>
        )}

        <Grid container alignItems="center" justify="center">
          <Button
            className={styles.addServiceBtn}
            onClick={() => setShowAddProductServiceModal(true)}
            color="secondary"
            variant="contained"
          >
            Add New <AddIcon className={styles.addIcon} />
          </Button>
        </Grid>

        {product ? (
          <AddProductServiceModal
            id={product.id}
            open={showAddProductServiceModal}
            setOpen={setShowAddProductServiceModal}
            refresh={() => setRefresh(!refresh)}
            closeHandler={() => setShowAddProductServiceModal(false)}
          />
        ) : null}
      </Grid>
    </FadeIn>
  );
};

export default AddProduct;
