import { useHistory, useParams } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import { getOrder, verifyEmail } from "http/endpoints";
import { userContext } from "contexts/User";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { notificationsContext } from "contexts/Notifications";
import FadeIn from "components/FadeIn";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { makeStyles } from "@material-ui/core/styles";
import { Address, Order } from "http/models";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { colors } from "@mui/material";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(14),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(2),
    },
    checkCircle: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      color: colors.green[500],
    },
    inputField: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      display: "block",
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    verifyIcon: {
      marginLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

const OrderConfirmation = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const styles = useStyles();
  const [order, setOrder] = React.useState<Order | undefined>(undefined);
  const [shippingTo, setShippingTo] = React.useState<string>("");

  useEffect(() => {
    getOrder({ id }).then((resp) => {
      if (resp.status.code !== 200) {
        history.push("/not-found");
        return;
      }
      setOrder(resp.body);
      if (resp.body?.shipping_address) {
        setShippingTo(addrToString(resp.body.shipping_address));
      }
    });
  }, []);

  const addrToString = (addr: Address): string => `
    ${addr.street[0]}
    ${addr.street.length > 1 ? addr.street[1] : ""}
    ${addr.city}, ${addr.state}
    ${addr.zip_code}
  `;

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Typography variant="h3">Order Confirmation</Typography>
          </Grid>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Typography variant="h5">
              Your payment was processed successfully
              <CheckCircleIcon className={styles.checkCircle} />
            </Typography>
          </Grid>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Typography variant="subtitle1">
              <strong>Order ID:</strong> {order?.id}
            </Typography>
          </Grid>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Typography variant="subtitle1">
              <strong>Shipping to:</strong> {shippingTo}
            </Typography>
          </Grid>
          <Grid
            className={styles.grid}
            container
            alignItems="center"
            justify="center"
          >
            <Typography variant="subtitle1">
              <strong>Total:</strong> ${order?.total}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default OrderConfirmation;
