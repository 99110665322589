import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { DeleteOutlined } from "@material-ui/icons";
import FadeIn from "components/FadeIn";

const useStyles = makeStyles(
  (theme) => ({
    deleteIcon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
      "&:hover": {
        color: "red",
      },
    },
    card: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      boxShadow: `1px 1px 4px ${theme.palette.grey[800]}`,
    },
    fadeOut: {
      opacity: 0,
      transition: "all 500ms linear;",
    },
  }),
  { index: 1 }
);

interface Props {
  file: File;
  handleDelete: (file: string) => void;
}

const ImageCard: React.FC<Props> = ({ file, handleDelete }) => {
  const styles = useStyles();
  const [show, setShow] = useState<boolean>(true);

  return (
    <FadeIn>
      <Card variant="outlined" className={show ? styles.card : styles.fadeOut}>
        <CardHeader
          title={file.name}
          action={
            <Tooltip title="delete" placement="top">
              <IconButton
                className={styles.deleteIcon}
                onClick={() => {
                  setShow(false);
                  setTimeout(() => {
                    handleDelete(file.name);
                  }, 500);
                }}
              >
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          }
        />
        <CardMedia
          component="img"
          height="194"
          image={URL.createObjectURL(file)}
          alt="file"
        />
      </Card>
    </FadeIn>
  );
};

export default ImageCard;
