// <reference lib="dom" />
import { errorFromResponseBody } from "http/error";
import {
  APIResponse,
  makeAPIResponseError,
  makeAPIResponseSuccess,
} from "http/response";

export type RequestParams = {} | null; // eslint-disable-line

export type RequestBody = BodyInit | {} | null; // eslint-disable-line

export type RequestQueryIndexed = {
  [queryName: string]: string | boolean | number | undefined;
};

export type RequestQuery = RequestQueryIndexed | null;

type RequestMethodWithBody = "POST" | "PATCH" | "PUT";
type RequestMethodWithoutBody =
  | "GET"
  | "DELETE"
  | "OPTIONS"
  | "CONNECT"
  | "HEAD";
export type RequestMethod = RequestMethodWithBody | RequestMethodWithoutBody;
export type BodyTransformer<R, P extends RequestParams | unknown = unknown> = (
  response: Response,
  requestParams: P
) => Promise<R extends (infer U)[] ? Readonly<U[]> : R>;

export interface BasicResponseBody {
  msg: string;
}

export interface RequestOptions<
  R extends unknown,
  P extends RequestParams | undefined = undefined,
  M extends RequestMethod = "GET"
> {
  path: string | ((requestParams: P) => string);
  query?: RequestQueryIndexed | ((requestParams: P) => RequestQueryIndexed);
  method?: M;
  prefixPath?: string;
  host?:
    | string
    | ((requestParams: P) => Promise<string>)
    | ((requestParams: P) => string);
  headers?: Record<string, string>;
  accept?: string;
  contentType?: string;
  bodyTransformer?: BodyTransformer<R, P>;
  timeout?: number;

  withRetry?: {
    allowedStatusCodes?: number[];
    maxTries?: number;
  };
}

// eslint-disable-next-line
interface RequestInitWithTimeout extends Omit<RequestInit, "signal"> {
  timeout: number; // eslint-disable-line
}

interface RequestInitWithRetry extends RequestInitWithTimeout {
  allowedStatusCodes?: number[];
  maxTries?: number;
}

const JSON_CONTENT_TYPE = "application/json";

const responseIndicatesError = (
  res: Response,
  allowedStatusCodes?: number[]
): boolean => {
  if (
    allowedStatusCodes &&
    allowedStatusCodes.find((code) => code === res.status)
  ) {
    return false;
  }
  return res.status >= 300;
};

export function fetchWithTimeout(
  requestInfo: RequestInfo, // eslint-disable-line
  { timeout, ...requestInit }: RequestInitWithTimeout
): Promise<Response> {
  return new Promise((resolve, reject) => {
    const controller = new AbortController();

    const timeoutId = window.setTimeout(() => {
      controller.abort();

      // eslint-disable-next-line prefer-promise-reject-errors
      reject("Timed out");
    }, timeout);

    fetch(requestInfo, {
      ...requestInit,
      signal: controller.signal,
    })
      .then(resolve)
      .catch(reject)
      .finally(() => window.clearTimeout(timeoutId));
  });
}

export async function fetchWithRetryExpDecay(
  requestInfo: RequestInfo, // eslint-disable-line
  { allowedStatusCodes, maxTries = 3, ...requestInit }: RequestInitWithRetry
): Promise<Response> {
  const response = await fetchWithTimeout(requestInfo, requestInit);

  if (!responseIndicatesError(response, allowedStatusCodes)) return response;

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < maxTries; i++) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => setTimeout(resolve, exponentialDelay(i)));

    // eslint-disable-next-line no-await-in-loop
    const retryRes = await fetchWithTimeout(requestInfo, requestInit);
    if (!responseIndicatesError(retryRes, allowedStatusCodes)) return retryRes;
  }

  return response;
}

/**
 * A helpful wrapper around `fetch` that builds requests in a form our API and front-end anticipates.
 * Generally, this should not be used directly if invoked without a try/catch block.
 * Instead, use `createAPIHandler` to guarantee an API response object regardless if an runtime error occured.
 *
 * @param options Request options. Optional if used with `createAPIHandler`
 * @param requestParams potential object containing parameters used with a `path` and query builder function
 * @param requestBody optional payload sent along with the request
 * @param queryParams optional query params appended to the request URL
 */
export async function MakeRequest<
  R extends unknown,
  P extends RequestParams | undefined,
  B extends RequestBody | unknown | undefined,
  M extends RequestMethod
>(
  options: RequestOptions<R, P, M>,
  requestParams: P,
  requestBody: B
): Promise<APIResponse<R>> {
  const {
    method = "GET",
    path: pathBuilder,
    query: queryBuilder,
    prefixPath = "/api/v1",
    host: hostBuilder = getOrigin(),
    timeout = 15000,
    contentType: requestContentType = JSON_CONTENT_TYPE,
    bodyTransformer,
    accept,
    headers = {},
    withRetry,
  } = options;

  const path =
    typeof pathBuilder === "string" ? pathBuilder : pathBuilder(requestParams);
  const host =
    typeof hostBuilder === "string"
      ? hostBuilder
      : await hostBuilder(requestParams);

  if (!path.startsWith("/")) {
    throw new Error("request path must start with /");
  }

  if (requestBody) {
    if (
      requestContentType === JSON_CONTENT_TYPE &&
      typeof requestBody !== "string"
    ) {
      // eslint-disable-next-line no-param-reassign
      requestBody = JSON.stringify(requestBody) as B;
    }

    headers["content-type"] = requestContentType;
  }

  if (accept) {
    headers.Accept = accept;
  }

  const requestUrl = new URL(host + prefixPath + path);

  if (queryBuilder) {
    const queryParams =
      typeof queryBuilder === "function"
        ? queryBuilder(requestParams)
        : queryBuilder;

    assignQueryParams(requestUrl.searchParams, queryParams);
  }

  const isRequestToSameOrigin = requestUrl.origin === window.location.origin;

  const response = await fetchWithRetryExpDecay(requestUrl.href, {
    timeout,
    method,
    headers,
    credentials: "include",
    body: requestBody as BodyInit, // eslint-disable-line
    redirect: isRequestToSameOrigin ? "follow" : "manual",
    allowedStatusCodes: withRetry?.allowedStatusCodes,
    maxTries: withRetry?.maxTries || 1,
  });

  const responseContentType = response.headers.get("Content-Type") || undefined;
  const requestId = response.headers.get("Request-ID") || undefined;

  let body: R;
  const statusCodeIndicatesError = responseIndicatesError(response);

  if (!caseInsensitiveContains(responseContentType, requestContentType)) {
    // eslint-disable-next-line
    console.warn(
      `Request content type "${requestContentType}" did not match the response content type "${responseContentType}"`,
      requestUrl,
      response
    );
  }

  // 204: no-content returned.
  if (response.status === 204) {
    body = null as R;
  } else {
    try {
      if (bodyTransformer && !statusCodeIndicatesError) {
        body = (await bodyTransformer(response, requestParams)) as R;
      } else if (responseContentType?.includes("json")) {
        body = (await response.json()) as R;
      } else {
        body = (await response.text()) as R;
      }
    } catch (parseError) {
      return makeAPIResponseError(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        { error: { message: String(parseError.message) } },
        {
          code: response.status,
          text: response.statusText,
        },
        response.headers
      );
    }
  }

  if (statusCodeIndicatesError) {
    const error = errorFromResponseBody(body);
    if (error) {
      return makeAPIResponseError(
        error,
        {
          code: response.status,
          text: response.statusText,
        },
        response.headers
      );
    }

    return makeAPIResponseError(
      {
        error: {
          message: `Unknown error: ${response.status} ${response.statusText} ${requestId}`,
        },
      },
      {
        code: response.status,
        text: response.statusText,
      },
      response.headers,
      requestId
    );
  }

  return makeAPIResponseSuccess<R>(
    body,
    {
      code: response.status,
      text: response.statusText,
    },
    response.headers,
    requestId,
    response.type === "opaqueredirect"
  );
}

/** Returns the generic type of a resolved Promise. */
export type PluckPromiseValue<T> = T extends PromiseLike<infer U> ? U : T;

/** ReturnType for functions with PromiseLike return values. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReturnTypeOfPromise<T extends (...args: any) => any> =
  PluckPromiseValue<ReturnType<T>>;

/** A PromiseLike value containing an API response. */
export type APIResponseReturnType<R> = PromiseLike<
  APIResponse<R extends (infer U)[] ? Readonly<U[]> : R>
>;

/**
 * API handler without any arguments.
 *
 * - Supports optional request options.
 * - Allows return type to be overridden, such as with `bodyTransform`
 */
export type APIHandlerBasic<R, RO extends RequestOptions<R>> = <
  ARO extends Partial<RO>
>(
  requestParams?: null,
  requestBody?: null,
  additionalRequestOptions?: ARO
) => APIResponseReturnType<
  ARO["bodyTransformer"] extends BodyTransformer<R>
    ? ReturnTypeOfPromise<ARO["bodyTransformer"]>
    : R
>;

/**
 * API handler with at least path parameters argument.
 *
 * - Supports optional request options.
 * - Allows return type to be overridden, such as with `bodyTransform`
 */
export type APIHandlerWithParameters<R, P, RO extends RequestOptions<R, P>> = <
  ARO extends Partial<RO>
>(
  requestParams: P,
  requestBody?: null,
  additionalRequestOptions?: ARO
) => APIResponseReturnType<
  ARO["bodyTransformer"] extends BodyTransformer<R, P>
    ? ReturnTypeOfPromise<ARO["bodyTransformer"]>
    : R
>;

/**
 * API handler with path parameters and body arguments.
 *
 * - Supports optional request options.
 * - Allows return type to be overridden, such as with `bodyTransform`
 */
export type APIHandlerWithParametersAndBody<
  R,
  B,
  P,
  RO extends RequestOptions<R, P>
> = <ARO extends Partial<RO>>(
  requestParams: P,
  requestBody: B,
  additionalRequestOptions?: ARO
) => APIResponseReturnType<
  ARO["bodyTransformer"] extends BodyTransformer<R, P>
    ? ReturnTypeOfPromise<ARO["bodyTransformer"]>
    : R
>;

/** Composes variadic function signatures.  */
export type APIHandler<
  /** The response body. */
  R extends unknown,
  /** Optional path parmeters. Necessary if the request path is built with entity IDs. */
  P extends RequestParams | undefined,
  /** Optional request. Necessary if the request is sent with a payload such as in HTTP methods PUT and POST. */
  B extends RequestBody | unknown | undefined = undefined
> = B extends undefined // No body?
  ? P extends undefined // ...and no request params?
    ? APIHandlerBasic<R, RequestOptions<R>> // No parameters needed.
    : APIHandlerWithParameters<R, P, RequestOptions<R, P>> // Just request parameters.
  : APIHandlerWithParametersAndBody<R, B, P, RequestOptions<R, P>>;
// request parameters, request body, query parameters, and additional request options.

/**
 * Creates an API handler with rich type information.
 *
 * `createAPIHandler` wraps `makeRequest` with the default values provided.
 * See `APIHandler` interface for more details on type parameters.
 *
 * @param requestOptions Default request options passed to `makeRequest`.
 * See `RequestOptions` interface for more details.
 */
export function createAPIHandler<
  R extends unknown = undefined,
  P extends RequestParams | undefined = undefined,
  B extends RequestBody | unknown | undefined = undefined,
  M extends RequestMethod = RequestMethod
>(baseRequestOptions: RequestOptions<R, P, M>) {
  const tryCatchWrapper = async (
    requestParams: P,
    body: B,
    additionalRequestOptions?: RequestOptions<R, P, M>
  ) => {
    try {
      const requestOptions: RequestOptions<R, P, M> = additionalRequestOptions
        ? { ...baseRequestOptions, ...additionalRequestOptions }
        : baseRequestOptions;

      return await MakeRequest<R, P, B, M>(requestOptions, requestParams, body);
    } catch (error) {
      return makeAPIResponseError({ error: { message: String(error) } });
    }
  };

  return tryCatchWrapper as unknown as APIHandler<R, P, B>;
}

const assignQueryParams = (
  searchParams: URLSearchParams,
  queryParams: RequestQueryIndexed
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(queryParams)) {
    if (typeof value !== "undefined") {
      searchParams.set(key, value.toString());
    }
  }
};

export const caseInsensitiveContains = (
  str: string | undefined,
  substr: string | undefined
): boolean => {
  if (!str || !substr) {
    return false;
  }
  return str.toLowerCase().includes(substr.toLowerCase());
};

export const exponentialDelay = (idx: number): number => {
  const ms = 100;
  if (idx <= 0) return ms;
  return idx * idx * ms;
};

export const getOrigin = (): string => window.location.origin;

export const getHost = (): string => window.location.host;

export const makeURL = (path: string): string =>
  `${window.location.origin}${path}`;
