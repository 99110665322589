import { useHistory, Link, Redirect } from "react-router-dom";
import React, { useContext, useState } from "react";
import { logIn } from "http/endpoints";
import { userContext } from "contexts/User";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { notificationsContext } from "contexts/Notifications";
import FadeIn from "components/FadeIn";
import { FcGoogle } from "react-icons/fc";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    inputField: {
      width: theme.spacing(30),
      marginLeft: theme.spacing(11),
      marginTop: theme.spacing(2),
    },
    root: {
      marginTop: theme.spacing(6),
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(5),
    },
    btn: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(-1),
    },
    forgotPassword: {
      width: "200px",
      marginLeft: "45px",
      marginTop: theme.spacing(3),
      color: theme.palette.primary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    moveUp: {
      marginTop: theme.spacing(-4),
    },
    loginIcon: {
      marginLeft: theme.spacing(1),
    },
    spacer2: {
      height: theme.spacing(2),
      width: "100%",
    },
    spacer3: {
      height: theme.spacing(3),
      width: "100%",
    },
    spacer4: {
      height: theme.spacing(4),
      width: "100%",
    },
    spacer5: {
      height: theme.spacing(5),
      width: "100%",
    },
    spacer6: {
      height: theme.spacing(6),
      width: "100%",
    },
    spacer7: {
      height: theme.spacing(7),
      width: "100%",
    },
    spacer8: {
      height: theme.spacing(8),
      width: "100%",
    },
    spacer9: {
      height: theme.spacing(9),
      width: "100%",
    },
    spacer10: {
      height: theme.spacing(10),
      width: "100%",
    },
  }),
  { index: 1 }
);

const LogIn: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isPending, setIsPending] = useState<boolean>(false);
  const [hasEmailError, setHasEmailError] = useState<boolean>(false);
  const { user, setUser } = useContext(userContext);
  const styles = useStyles();
  const { displayNotification, displayCustomAPIErrorNotification } =
    useContext(notificationsContext);

  const openOauthConsent = () => {
    window.open("/api/v1/sessions/oauth-sign-in");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    setHasEmailError(false);

    logIn(null, { email, password }).then((resp) => {
      switch (resp.status.code) {
        case 200:
          setTimeout(() => {
            history.push("/verify-multi-factor-auth");
            displayNotification(
              "successfully texted verification code",
              "success"
            );
          }, 1000);
          break;
        case 201:
          setTimeout(() => {
            history.push("/verify-email");
            displayNotification(
              "successfully emailed verification code",
              "success"
            );
          }, 1000);
          break;
        case 202:
          setTimeout(() => {
            setUser();
            history.push("/");
            displayNotification("welcome back", "success");
          }, 1000);
          break;
        default:
          if (resp.error) {
            if (resp.error.error.detail) {
              if (resp.error.error.detail.includes("invalid email")) {
                setHasEmailError(true);
                displayCustomAPIErrorNotification(resp.error.error.detail);
                break;
              }
            }
            displayCustomAPIErrorNotification("bad credentials");
          }
      }
      setTimeout(() => {
        setIsPending(false);
      }, 2000);
    });
  };

  if (user !== null) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.root}>
      <Container>
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
        >
          <form onSubmit={handleSubmit} noValidate>
            <Grid
              className={styles.grid}
              container
              alignItems="center"
              justify="center"
            >
              <Typography variant="h3">Log In</Typography>
            </Grid>
            <FadeIn>
              <Grid item xs={12} className={styles.moveUp}>
                <TextField
                  onChange={(e) => setEmail(e.target.value.trim())}
                  className={styles.inputField}
                  label="Email"
                  type="email"
                  value={email}
                  error={hasEmailError}
                  fullWidth
                  required
                />
              </Grid>
              <div className={styles.spacer4} />
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setPassword(e.target.value.trim())}
                  className={styles.inputField}
                  label="Password"
                  type="password"
                  fullWidth
                  required
                >
                  {password}
                </TextField>
              </Grid>
              <Grid
                className={styles.grid}
                direction="column"
                container
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12}>
                  {isPending ? (
                    <Button
                      className={styles.btn}
                      color="default"
                      disabled
                      variant="contained"
                    >
                      Logging in...
                    </Button>
                  ) : (
                    <Button
                      className={styles.btn}
                      type="submit"
                      color="secondary"
                      variant="contained"
                    >
                      <Typography>Enter</Typography>
                      <ExitToAppIcon className={styles.loginIcon} />
                    </Button>
                  )}
                </Grid>
                <Grid
                  className={styles.grid}
                  direction="column"
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Button
                    onClick={openOauthConsent}
                    color="secondary"
                    variant="contained"
                    endIcon={<FcGoogle className={styles.loginIcon} />}
                  >
                    Google Sign In
                  </Button>
                  <Link to="/forgot-password" className={styles.forgotPassword}>
                    <Typography>Forgot your password?</Typography>
                  </Link>
                </Grid>
              </Grid>
            </FadeIn>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default LogIn;
